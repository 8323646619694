.specialities {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  /* text-decoration: underline; */
  padding: 3px;
  /* color: blue; */
}

/* .specialities:active{
    color:white;
}
 */

#none_display {
  display: none;
}

.active-link {
  color: #01068e;
  text-decoration: underline;
}

.active-link:hover {
  color: #01068e;
}

.article-para>ul>li>span {
  background-color: white !important;
  line-height: 1.4;
  text-align: justify;
}

.article-para>p>span {
  background-color: white !important;
  line-height: 1.4;
  text-align: justify;
}

.article-para a{
  text-decoration: underline;
  color: blue;
}

.article-para>p {
  margin: 8px 0px;
  text-align: justify;
  word-break: break-all;
}

.article-para>ul>ul>li,
.article-para>ul>li,
.article-para>ul>ul>ul>li {
  line-height: 1.4;
  text-align: justify;
}

.display_icon_back {
  display: none;
}

.desktopview_header {
  display: block;
}

.mobileview_header {
  display: none;
}

@media screen and (max-width: 769px) {
  .display_icon_back {
    display: block;
  }

  .desktopview_header {
    display: none;
  }

  .mobileview_header {
    display: block;
  }
}

.edm_header_title {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

@media screen and (max-width:480px) {
  .ebm_app_bar {
    padding: 0px !important;
  }

  .drug_header_text {
    font-size: 14px;
    font-weight: 400;
  }
}

@media screen and (max-width:321px) {
  .edm_header_title {
    font-size: 12px;
  }
}