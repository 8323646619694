.MarketPlace_Sidebar_Card {
  height: 400px;
  border: 1px solid red;
  width: 100px;
}

.li {
  font-size: 14px;
}

.spacing_mediscope {
  padding-top: 10px;
  padding-right: 10px;
}

.border_img {
  border: 0.10000000149011612px solid #e7e7e7;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  background-color: white;
}

/* .marketplace__card__content { */
/* padding: 16px; */
/* } */

@media only screen and (min-width: 440px) and (max-width: 768px) {
  .li {
    flex-wrap: wrap;
    /* margin-left: -24px; */
  }
}

@media only screen and (min-width: 375px) and (max-width: 439px) {
  .li {
    flex-wrap: wrap;
    margin-left: -20px;
    font-size: 10px;
    /* padding: 5px; */
  }
}

@media screen and (max-width: 768px) {
  .spacing_mediscope {
    padding-top: 0px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 426px) {
  /* .marketplace__card__content { */
  /* padding: 8px; */
  /* } */
}

.line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cal-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
