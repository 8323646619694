.box {
    width: 100px;
    height: 100px;
    display: flex;
    /* justify-content: center; */
    padding-top: 10px;
    border-radius: 10px;
    background-color: #CDF5F9;
    flex-direction: column;
    align-items: center;
    position: relative;
}