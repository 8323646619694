.ce-block__content {
  position: relative;
  max-width: fit-content !important ;
  margin: 0 20px 0px 68px !important;
  -webkit-transition: background-color 0.15s ease;
  transition: background-color 0.15s ease;

}
.ce-toolbar__content {
  max-width: fit-content !important;
  margin: 0 auto;
  position: relative;
  left:20px;
}


ce-block__content
#react-editor-js-19339d30418{
padding-left:10px !important
}

.codex-editor__redactor {
  padding-bottom: 300px !important;
}
.ce-toolbar__content {
  margin-left: 50px !important;
} 

