#padding_input_post {
  margin-top: 15px;
  padding-left: 25px;
}

#tab_design {
  padding-bottom: 10px;
  /* margin-left: 10px; */
  /* background-color: white !important; */
}

.mentions_text {
  color: #000000;
  font-weight: bold;
  font-size: 15px;
  text-transform: capitalize;
}

.mentions_text:hover {
  text-decoration: underline;
  color: #0137CB;
}



.p-3 {
  padding: 0px 20px;
}

.border_all {
  border: 1px solid lightgrey;
}

.post_btn {
  background-color: #4A33FF !important;
  border-radius: 10px !important;
  padding: 3px 20px !important;
}

.color_white {
  color: white !important
}

.color_head {
  color: #3b10cc
}

.tab_circle {
  background-color: #cef6fa !important;
  border-radius: 18px !important;
  border-bottom: none !important;
  min-height: 20px !important;
  color: #000000 !important;
  width: 125px !important;
  margin-right: 15px !important;
  font-size: 14px !important;
}

.tab_circle1 {
  background-color: #ffffff !important;
  border-radius: 18px !important;
  border-bottom: none !important;
  min-height: 20px !important;
  color: #000000 !important;
  width: 125px !important;
  margin-right: 15px !important;
  font-size: 14px !important;
}

.tab_circle2 {
  background-color: #ffffff !important;
  border-radius: 18px !important;
  border-bottom: none !important;
  min-height: 20px !important;
  color: #000000 !important;
  width: 125px !important;
  margin-right: 15px !important;
  font-size: 14px !important;
}

.tab_circle3 {
  background-color: #cef6fa !important;
  border-radius: 18px !important;
  border-bottom: none !important;
  min-height: 20px !important;
  color: #000000 !important;
  width: 125px !important;
  margin-right: 15px !important;
  font-size: 14px !important;
}

/* span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  background-color: none !important;
} */
.meta_img_text {
  height: 350px;
  overflow: hidden;
}

.meta_url_text {
  font-size: 14px;
  color: #0077c4;
  font-weight: 200;

}

.meta_title_text {
  font-size: 18px;
  color: #4a4a4a;
  font-weight: 550;

}

.meta_show_text {
  color: #0137CB;
  font-size: 16px;
  font-weight: 400;
}

.meta_description_text {
  font-size: 14px;
  color: #20202a;
  font-weight: 300;
}



.trending_text {
  font-size: 18px;
  font-weight: 400;
  color: #20202a;
}

.tag_text {
  font-size: 16px;
  font-weight: 500;
  color: #007dcd;
  text-size-adjust: 100%;
}

.meta_display_img {
  /* width: 500px; */
  width: 100%;
  height: inherit;
  object-fit: cover;
  object-position: center;
}

.meta_preview {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 200px;
}

.feed {
  /* flex:0.4;
    margin:0 20px; */
  margin-top: 10px;
}

.feed__inputContainer {
  background-color: white;
  margin-bottom: 20px;
  border-radius: 10px;
}

.feed__input {
  /* display: flex; */
  padding: 30px;
  color: gray;
  /* padding-left: 15px; */
}

.feed__input>form {
  /* display: flex; */
  width: 100%;
}

.feed__input>textarea {
  border: none;
  /* flex:1; */
  width: 100%;
  /* margin-left: 10px; */
  outline: none;
  border-radius: 20px;
  padding: 10px;
  /* font-weight: 600; */
  font-size: 15px;
  font-family: inherit;
}

.feed__input>form>button {
  display: none;
}

.feed__inputOptions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  border-top: 1px solid transparent;
  padding: 10px;
  background-color: #e4eef5;
}

.feed__modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid transparent;
  padding: 10px;
  background-color: #ffffff;
}

.feed__inputOption {
  display: flex;
  margin: 10px 0px 0px 20px;
}

.feed__btn {
  display: flex;
}

.btn__primary {
  background-color: #007dcd !important;
  outline: none;
  border: none;
  height: 24px;
  width: 75px;
  border-radius: 15px;
  font-size: 14px;
  color: white !important;
  /* margin-left: 30px; */
}

.inputIcon {
  font-size: 8px;
}

.dropDown {
  display: flex;
  padding: 0px 30px;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.dialog_content::-webkit-scrollbar-thumb {
  background-color: gray;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.dialog_content::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar {
  width: 0px;
}

.feed_desktop {
  display: block;
}

.feed_mobile {
  display: none;
}

.addicon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #0077c4;
  box-shadow: 0 0px 10px 0 #666;
  color: white;
  text-align: center;
  position: fixed;
  right: 25px;
  bottom: 100px;
  /* position: sticky;
    right: -275px;
    bottom: -550px; */
  transition: all 0.1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fabdetail {
  display: flex;
}

.button__add_icon {
  display: none;
}

.feed__options__Desktop {
  display: block;
}

.feed__options__mobile {
  display: none;
}

.post_view_Desktop {
  display: block;
}

.post_view_Mobile {
  display: none;
}

.view_post_Desktop {
  display: block;
}

.view_post_Mobile {
  display: none;
}

.text_color1 {
  color: #20202a;
  font-size: 15px;
}

@media screen and (max-width: 769px) {
  .feed_desktop {
    display: none;
  }

  .feed_mobile {
    display: block;
  }

  .button__add_icon {
    display: block;
  }

  .dropDown {
    margin-top: 10px;
  }

  .post_view_Desktop {
    display: none;
  }

  .post_view_Mobile {
    display: block;
  }

  #back_ground {
    background-color: blanchedalmond;
  }

  .view_post_Desktop {
    display: none;
  }

  .view_post_Mobile {
    display: block;
  }

  .view_post_target {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block-start: 70px;
    /* width: auto;
    height: auto; */
  }

  .Mobilepost__header {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 601px) {
  .feed__options__Desktop {
    display: none;
  }

  .feed__options__mobile {
    display: block;
    overflow: auto;
    white-space: nowrap;
  }

  .photo_border {
    display: grid;
    place-items: center;
    padding: 0px 25px;
    margin: 0px 8px;
    border-radius: 20px;
    background-color: rgb(205, 233, 236);
  }

  .photo_display {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 426px) {
  .meta_display_img {
    /* width: 500px; */
    width: 100%;
    height: inherit;
    object-fit: cover;
    object-position: center;
  }

  .meta_img_text {
    height: auto !important;
    overflow: hidden;
  }

  .meta_url_text {
    font-size: 14px;
    color: #0077c4;
    font-weight: 200;
  }

  .meta_title_text {
    font-size: 16px;
    color: #4a4a4a;
    font-weight: 550;
  }

  .meta_show_text {
    color: #0137CB;
    font-size: 14px;
    font-weight: 400;
  }

  .meta_description_text {
    font-size: 14px;
    color: #20202a;
    font-weight: 550;
  }

  .dropDown {
    display: block;
    padding: 0px 10px;
    margin-top: 10px;
  }

  /* .feed__input { */
  /* padding: 10px; */
  /* } */
  .feed__input>textarea {
    padding: 5px;
    height: 65px;
  }

  .feed__modal {
    display: block;
    padding: 0px;
  }

  .feed__btn {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .btn__primary {
    font-size: 16px;
    width: 90px;
  }

  .photo_border {
    padding: 0px 15px;
  }

  #padding_input_post {
    margin-top: 15px;
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 376px) {
  /* .feed__input { */
  /* padding: 10px; */
  /* } */
}

.carousel-container-3,
.react-multi-carousel-list {
  display: block !important;
  padding: 0px 0px 25px 0px;
  overflow: hidden;
  position: relative;
}

.carousel-container-3,
.react-multi-carousel-item {
  margin: 5px !important;
}

.unique-button-group-container {
  position: absolute;
  right: 11vw;
  bottom: 31vh;
}

.carousel-arrow-button {
  background: #F9F5F5 !important;
  color: #000 !important;
  font-weight: bold !important;
  font-size: 30px !important;
  margin-right: 15px !important;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .unique-button-group-container {
    right: 5vw;
    bottom: -56vh;
  }
}

@media screen and (min-width:960px) {
 .singleImage {
  
    /* height:300px;
    object-position: fill; */
    /* object-posi
    tion: center;  */
    /* max-height: 300px; */
  } 
/*      
  .img_content {

    height: 280px;
 
  } */
} 