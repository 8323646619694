.co_authors{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}
.co_authors h5{
    font-size: 14px;
    font-weight: 500;
}
.co_authors p{
    font-size: 14px;
    font-weight: 400;
    padding-left: 20px;
}
/* .organisation{
    padding-left: 10px;
} */
.authors{
    /* border:1px solid #707070; */
    height:200px;
    margin-top:20px;
    padding:10px 0px 0px 10px;
}
.contributors{
    display: flex;
    align-items: center;
    
}
.article_contributors{
    display: flex;
    align-items: center;
}
.article_organisation{
    padding-left: 40px;
}

.publishing__details input{
    border: none;
    padding-left: 88px;
}
.publishing__details input:focus{
    outline: none;
}
.entered__details{
    height: 102px;
    border: 1px solid gray;
    border-radius: 11px;
}
.article__title{
    font-size: 14px;
    font-weight: 700;
    padding:  10px;
}
.article__title input{
    border:none;
}
.article__title input:focus{
    outline: none;
}
.publishing__details{
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-top: 4px;
}
.authorsandcoauthors{
display: flex;
align-items: center;
}
.authorsandcoauthors input{
    margin-top: 7px;
    padding-left: 40px;
    border: none;
}
.authorsandcoauthors input{
    outline: none;
}
.authorsandcoauthors span{
    font-size: 14px;
    font-weight: 600; 
}
.authorsandcoauthors p{
    font-size: 14px;
    font-weight: 400; 
    padding-left: 20px;
}
.butons{
    padding: 5px;
}
.buttons_div{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}