 * {
  margin: 0;
}

.webinar p{
  margin: 1em 0!important;
}

.webinar a.name_hover:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

.webinar a:-webkit-any-link:active {
  color: -webkit-activelink;
}
.webinar a:-webkit-any-link:hover {
  color: #1976d2;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
