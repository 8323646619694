.feed__inputOptions {
  display: flex;
  justify-content: space-evenly;
}
.hash_tag_display {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
}
.inputOption {
  /* display: flex;
    flex-direction: column;
    align-items: center; */
  text-align: center;
  margin: 0px 5px;
  padding: 5px 5px;
  cursor: pointer;
}
.input__title1 {
  font-size: 12px;
  padding-top: 3px;
}
.celebrate {
  padding: 0px 6px;
}
.trending_padding{
  padding: 10px 20px;
}

.hashtag_display{
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
}
.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


@media screen and (max-width: 769px){
  .trending_padding{
    padding: 0px;
  }
  .media_display{
    display: flex;
  }
}

@media screen and (max-width: 601px) {
  .input__title1 {
    position: relative;
    top: -11px;
    font-size: 15px !important;
    text-align: center !important;
    padding-top: 3px;
  }
}

/* @media screen and (max-width: 426px) {
  .inputOption {
    padding: 0px;
  }
} */

.post_options {
  color: #4fa3db;
  font-size: 13px;
}

.post_border {
  border: 1px solid #4fa3db;
  background-color: white;
  border-radius: 10px;
}

.media_class {
  box-shadow: 0px 10px 10px lightgrey;
  border-radius: 10px;
}

.textLowerCase {
  text-transform: lowercase;
}
