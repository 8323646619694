.clini_cases{
    display: flex;
    align-items: center;
}

.clini_cases input{
    margin-top: 18px;
    margin-left: 18px;
    border: none;
}
.clini_cases input:focus{
    outline: none;
}
.category{
    display: flex;
    /* margin-left: 18px;
    margin-top: 8px;
    margin-bottom: 8px; */
    padding: 18px;
    align-items: center;
}
.category h5{
    font-size: 14px;
    font-weight: 600;
    color: #2E3B43;
}
.category ul{
    display: flex;
    list-style-type: none;
}
.category ul li{
    padding: 0px 6px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
h5{
    color: blue;
    font-size: 16px;
    font-weight: 500;
}