.app {
  /* background-color: #ffffff; */
  background-color: #F4F2EE;
  min-height: 100vh;

  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}



/* .mobile {
    display: none;
}
@media screen and (max-width: 767px) { 
    .app {
        display: none;
    }
    .mobile {
        display: block;
        text-align: center;
        margin: 100px 20px;
    }
} */

/* body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
} */

body {
  overflow-x: hidden;
  margin: 0;
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app__body {
  max-width: 1280px;
  margin: auto;
}

/* .app__body::-webkit-scrollbar{
    width: 0px!important;
    display: none!important;
} */
/* body::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
/* } */
body::-webkit-scrollbar {
  height: 3px;
  width: 0px;
}

body::-webkit-scrollbar-thumb {
  background: lightgray;
}

.MuiButtonBase-root {
  padding: 8px !important;
}

.webinar a.MuiButtonBase-root {
  padding: 5px 15px !important;
}

/* .MuiFormControl-root{
      margin: 32px!important;
  } */

.MuiAutocomplete-endAdornment {
  top: 0px;
}

.capitalize {
  text-transform: capitalize !important;
}

.css-1k430x0-MuiButtonBase-root-MuiChip-root {
  background-color: rgba(0, 0, 0, 0.08) !important;
  border-radius: 16px !important;
}