body {
  color: #000000E6;
}

.notification {
  /* padding: 5px; */
  font-size: 14px;
}
.notification li {
  list-style: none;
}

.notifications {
  /* position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  color: black;
  font-weight: 300; */
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.counter1 {
  /* width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%; */
  padding: 5px;
  /* font-size: 14px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 18px;
  top: 0px;
  /* color: #ffffff; */
}

a {
  text-decoration: none;
  color: #000000E6;
}

/* a:hover {
  color: #222222;
} */

/* Dropdown */
.userprofile {
  height: 100%;
  width: 180px;
  margin-right: 15px;
  background-color: #ffffff;
  border-radius: 12px 12px 0px 0px;
}
.dropdown {
  display: flex;
  position: relative;
}

.dd-button {
  display: flex;
  border-radius: 4px;
  width: 145px;
  padding: 15px 30px 10px 5px;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 2px solid #b1edf4;
}

/* .dd-button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
} */

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  width: 180px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0;
  background-color: #ffffff;
  list-style-type: none;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}
.dd-menu img {
  padding-right: 10px;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}
/* 
.dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
} */
.postdropdown {
  border: 1px solid black;
  border-radius: 10px;
  width: 200px;
  display: flex;
  justify-content: right;
  align-items: flex-end;
}

.user-data {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#dropdown__Desktop {
  display: block;
}

#dropdown__smalldevices {
  display: none;
}

@media screen and (max-width: 769px) {
  #dropdown__Desktop {
    display: block;
  }
  #dropdown__smalldevices {
    display: none;
  }
}

.n_bg{
  background-color: #e4eef58f!important;
}