@media screen and (min-width: 1282px) {
  .orz__cover__edit img {
    position: absolute;
    margin-top: 15px !important;
    right: 0px !important;
  }
}

/* #containerorganisation {} */

.organisation_button {
  border-radius: 12px !important;
  height: 30px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  width: 120px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  background-color: #47aee1 !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  font-size: 16px !important;
}

.organisation_edit {
  margin-top: 20px !important;
  margin-right: 30px;
  top: 12px;
  right: 12px !important;
}

.app__one {
  max-width: 1280px;
  margin: auto;
}

.orz_title {
  border: 1px solid #707070;
  color: "#B300F5";
  font-weight: normal;
  font-size: 21px;
}

.orz_margin {
  margin-top: 16px;
  padding: 16px;
}

.orz_img {
  border: 1px solid black;
  width: 105px;
  height: 98px;
  border-radius: 20px;
}

.orz__check {
  display: flex;
  padding: 10px 20px;
  /* margin-top: 20px !important; */
}

.orz_Desktop_Devices {
  display: block;
}

.orz_small_Devices {
  display: none !important;
}

.DesktopDevices_orz {
  display: block;
}

.SmallDevices_orz {
  display: none !important;
}

@media screen and (max-width: 600px) {
  #img_orz {
    height: 100px !important;
    width: 100px !important;
  }
}
@media screen and (max-width: 900px) {
  #img_orz {
    height: 100px !important;
    width: 100px !important ;
  }
}

#img_orz {
  position: absolute;
  height: 144px;
  width: 144px;
  top: -75px;
  margin-left: 30px;
  border-radius: 20px;
}

.profile__header2 {
  position: relative;
  background-color: white !important;
  height: 60px;
  margin-top: -5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orz_cover_pic {
  width: 100%;
}

#link_background {
  display: none;
}

.orz_input_field {
  padding: 0px 20px;
}

.orz_input_field1 {
  padding: 16px 20px;
}

.org_name {
  padding: 0px;
}

.orz_caption_field {
  padding: 16px 0px;
}

.orz_telecom_use {
  padding: 0 10px;
}

.orz_feed {
  margin-top: 24px;
}

.display_feed {
  display: block;
}

#orz_image_edit {
  font-size: 16px;
  color: black;
  position: absolute;
  top: 53px !important;
  bottom: 0px;
  left: 30px;
  width: 144px;
  height: 56px;
  border-radius: 0px 0px 20px 20px;
  background-color: black;
  opacity: 0.7;
}

.role_orz {
  padding: 0px 10px;
}

.orz_title {
  padding: 0px 45px;
}

.admin_feed_desktop {
  display: block;
}

.admin_feed_mobile {
  display: none;
}

.Organisation__view__Mobile {
  display: none;
}

.Organisation__view__Desktop {
  display: block;
}

.Timeline__Desktop {
  display: flex !important;
}

.Timeline__Mobile {
  display: none !important;
}

#create_telecom {
  padding: 5px 20px;
}

/* .profile__header_orz {
  position: relative;
  background-color: rgb(199, 206, 217);
  height: 60px;
  margin-top: -60px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
} */

@media screen and (max-width: 1281px) {
  .orz__cover__edit img {
    position: absolute;
    margin-top: 15px !important;
    right: 0px !important;
  }
}

/* .feed__inputContainer1 {
  display: none;
} */

/* .orz_input_field {
} */
@media screen and (max-width: 1030px) {
  .orz_margin {
    margin: 0px;
    padding: 8px;
  }

  .orz_feed {
    margin-top: 0px !important;
  }

  #orz_image_edit {
    font-size: 16px;
    color: black;
    position: absolute;
    top: 36px !important;
    bottom: 0px;
    left: 30px;
    width: 144px;
    height: 77px !important;
    border-radius: 0px 0px 22px 22px;
    background-color: black;
    opacity: 0.7;
  }
}

@media screen and (max-width: 961px) {
  .orz_Desktop_Devices {
    display: none !important;
  }

  .organisation_button {
    border-radius: 12px !important;
    height: 30px !important;
    color: #ffffff !important;
    font-weight: 500 !important;
    width: 100px !important;
    margin-right: 11px !important;
    text-transform: capitalize !important;
    background-color: #47aee1 !important;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    font-size: 16px !important;
  }

  .orz_small_Devices {
    display: block !important;
  }

  .DesktopDevices_orz {
    display: none !important;
  }

  .SmallDevices_orz {
    display: block !important;
    width: 100%;
  }

  #public_org_img {
    position: absolute;
    /* top: -35px; */
    margin-left: 30px;
    border-radius: 20px;
    height: 110px;
    width: 110px;
    margin-top: -30px !important;
  }

  #img_orz {
    height: 110px;
    width: 110px;

    margin-top: -30px !important;
  }

  .profile__header2 {
    height: 140px;
  }

  #link_background {
    display: block;
    margin-top: 10px;
    background-color: white;
  }

  #link_background ul {
    display: flex;
    list-style: none;
    cursor: pointer;
  }

  #link_background li {
    padding: 10px 20px;
    text-decoration: none;
  }

  /* .myprofile__left {
    display: none;
  } */
  .profile-active2 {
    border: 1px solid gray;
    border-radius: 15px;
    background-color: #f8f0f0;
  }

  .orz_cover_pic {
    height: 150px;
  }

  .orz__header1 {
    display: block !important;
    height: 100px;
    width: 100%;
    margin-top: -4px !important;
    background-color: white;
  }

  .orz__header2 {
    display: block !important;
    height: 180px;
    width: 100%;
    margin-top: -4px !important;
    background-color: white;
  }

  .orz__link {
    height: 50px;
    background-color: white;
  }

  .orz__link ul {
    display: flex;
    list-style: none;
    cursor: pointer;
    padding: 10px 0px 0px 10px;
  }

  .orz__link li {
    padding: 5px;
    text-decoration: none;
    margin: 0px 10px !important;
  }

  .display_feed {
    display: none;
  }

  .orz__height {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
  }

  .orz_side_head {
    margin-top: 15px;
    margin-block-end: 80px;
  }

  #orz_image_edit {
    width: 144px !important;
    /* top: 24px !important; */

    height: 71px !important;
    border-radius: 0px 0px 21px 21px !important;
  }

  #orz__edit__icon {
    font-size: 18px;
    position: absolute;
    top: 15px;
    left: 45px;
  }

  .orz__sidebar__info1 {
    padding-top: 40px;
  }

  .orz_sidetext_head {
    padding: 20px 0px 0px 40px;
    font-size: 18px;
    font-weight: 500;
  }

  .orz_header_last {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  #btn_follow_orz {
    margin-right: 15px;
  }

  #btn_follow_orz Button {
    border: 1px solid #0020d1 !important;
    height: 28px !important;
    width: 100px !important;
    font-size: 12px !important;
    font-weight: 450 !important;
    color: black;
    border-radius: 15px !important;
    text-transform: capitalize !important;
  }

  .more_icon {
    position: relative;
    top: 6px;
    padding-left: 15px;
  }

  .website_edit_icon {
    display: flex;
    justify-content: space-between;
  }

  .orz_below_img_text {
    font-size: 13px;
    padding: 5px 0px 0px 40px;
  }

  .orz__cover__edit {
    margin-right: 20px;
    margin-top: 50px !important;
    /* top: 50px; */
    right: 0px !important;
    /* height: 30px; */
  }

  .admin_feed_desktop {
    display: none;
  }

  .admin_feed_mobile {
    display: block;
    margin-top: 10px;
  }

  .Organisation__view__Mobile {
    display: block;
    margin-block-end: 80px;
  }

  .Organisation__view__Desktop {
    display: none;
  }

  .Timeline__Desktop {
    display: none !important;
  }

  .Timeline__Mobile {
    display: block !important;
  }

  .Main_Header_orz {
    margin-block-end: 90px !important;
  }
}

@media (min-width: 900px) and (max-width: 961px) {
  #orz_image_edit {
    width: 110px !important;
    top: -50px !important;
    height: 60px !important;
    left: 34px !important;
    border-radius: 0px 0px 22x 22px !important;
  }
  .position_edit_icon {
    top: 20px !important;
    left: 50px !important;
  }
}
/* 
@media (min-width: 961px) and (max-width: 1030px) {
  #img_orz {
    height: 120px !important;
    width: 120px !important ;
    top: -50px !important;
  }
} */
@media (min-width: 961px) {
  #img_orz {
    height: 130px !important;
    width: 130px !important ;
    top: -50px !important;

    /* margin-top: 0px !important;
    top: -75px !important;
    margin-left: 16px !important; */
  }
  #orz_image_edit {
    top: 29px !important;
    bottom: 0px;
    left: 34px;
    width: 130px !important;
    height: 55px !important;
  }
  .position_edit_icon {
    top: 20px !important;
    left: 58px !important;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  #orz_image_edit {
    width: 100px !important;
    left: 34px !important;
    height: 50px !important;
    top: -50px !important;
  }

  .position_edit_icon {
    left: 42px !important;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .position_edit_icon {
    left: 42px !important;
  }
  #orz_image_edit {
    width: 100px !important;
    top: -50px !important;
    left: 34px !important;
    height: 50px !important;
    /* top: -10px !important; */
  }
}

@media screen and (max-width: 769px) {
  .margin_768px {
    margin-left: 20px !important;
  }

  /* #orz_image_edit {
    width: 100px !important;
    height: 50px !important;
    top: -10px !important;
  } */
}

@media screen and (max-width: 601px) {
  .orz_input_field {
    padding: 10px 20px;
  }

  .orz_input_field1 {
    padding: 10px 20px;
  }

  .org_name {
    padding: 10px;
  }

  .orz_caption_field {
    padding: 10px;
  }

  .orz_telecom_use {
    padding: 10px 0px;
  }

  .organosation_image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .role_orz {
    padding: 0px;
  }

  .orz__cover__edit {
    margin-top: 25px !important;
    right: 0px !important;
  }

  #orz_image_edit {
    width: 100px !important;
    top: -50px !important;
    left: 34px !important;
    height: 50px !important;
    /* top: -10px !important; */
  }

  .position_edit_icon {
    top: 15px !important;
    left: 44px !important;
  }
}

@media screen and (max-width: 481px) {
  #public_org_img {
    margin-left: 16px;
    border-radius: 20px;
    height: 100px;
    width: 100px;
    top: -25px;
  }

  .organisation_button {
    height: 25px !important;
    width: 80px !important;
    margin-right: 0px !important;
    font-size: 14px !important;
  }

  .orz_input_field {
    padding: 0px 10px;
  }

  .orz_input_field1 {
    padding: 0px 10px;
  }

  .org_name {
    padding: 5px 10px;
  }

  .orz_caption_field {
    padding: 5px 10px;
  }

  #orz_image_edit {
    width: 100px !important;
    top: -20px !important;
    left: 20px !important;
    /* height: 50px !important; */
  }

  .position_edit_icon {
    top: 20px !important;
    left: 44px !important;
  }
  .orz_title {
    font-size: 16px;
    /* padding:  */
  }

  .orz__link li {
    padding: 6px 10px;
    text-decoration: none;
  }

  #img_orz {
    height: 100px !important;
    width: 100px !important ;
    margin-top: 0px !important;
    top: -75px !important;
    margin-left: 16px !important;
  }

  .orz_sidetext_head {
    padding: 10px 0px 0px 20px;
    font-size: 16px;
  }

  .orz_below_img_text {
    padding: 5px 0px 0px 20px;
    color: rgb(54, 69, 79);
    font-weight: lighter;
    font-size: 12px;
  }

  #btn_follow_orz {
    margin-right: 0px;
  }

  .orz__link {
    height: 30px;
    padding: 8px;
  }

  .orz__link ul {
    padding: 0px;
  }

  .margin_768px {
    margin: 0px !important;
  }

  .orz__check {
    padding: 10px;
  }

  #create_telecom {
    padding: 0px 10px;
  }

  .orz_telecom_use {
    padding: 0px;
  }
}

@media screen and (max-width: 376px) {
  .orz__check {
    padding: 10px 0px 0px 0px !important;
  }

  .orz__link ul {
    padding: 0px;
  }

  .orz__cover__edit {
    margin-right: 10px;
    margin-top: 15px !important;
  }

  .orz__header1 {
    height: 90px;
  }

  .orz__link li {
    padding: 6px;
    text-decoration: none;
  }

  .orz__link li {
    margin: 0px !important;
  }
}
