.celebratepoll {
  border: 1px solid lightgrey;
  border-radius: 20px;
  /* height:105px; */
  margin-top: 20px;
  padding: 15px;
  width: 525px;
}
.c-width {
  width: 80%;
}
@media screen and (max-width: 426px) {
  .celebratepoll {
    margin: 0px;
    padding: 10px;
  }
}
@media screen and (max-width: 376px) {
  .c-width {
    width: 60%;
  }
}
