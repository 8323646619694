.submit-btn {
  background-color: #004265 !important;
  color: white !important;
  border-radius: 20px !important
}

.submit-btn:hover {
  background-color: #005065 !important
}

.elib_sub_text {
  font-size: 16px;
  margin-top: 5px;
  text-align: left;
}

@media screen and (max-width: 1250px){
.elib_sub_text {
    font-size: 12px;
    margin-top: 5px;
}
}

