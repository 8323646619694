/* .pdf__view__tab {} */

/* .react-pdf__Page__canvas {
  width: 100% !important;
  height: 400px !important;
  object-fit: contain !important;
  user-select: all !important;
} */

.post {
  background-color: white;
  padding: 15px 0px 5px 0px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #e5e5dc;
}

.post__header {
  display: flex;
  margin-bottom: 10px;
}

.post__info {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.post__info > div > p {
  font-size: 12px;
  color: #00000099;
}

.post-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post__body {
  overflow-wrap: anywhere;
}

.post__info > div > h2 {
  font-size: 15px;
  margin: 0px;
  font-weight: 600;
  color: #000000e6;
}

.post__info > div > h2 :hover {
  color: #027dcd;
}

.post__buttons {
  display: flex;
  /* justify-content: space-evenly; */
  margin-top: 20px;
}

.post__comment {
  align-items: flex-end;
  width: 100%;
}

.grid-image > div > div > div {
  background-size: contain !important;
}

.d-flex-start {
  align-items: flex-start;
}

.shared-styles {
  border: 1px solid lightgrey;
  border-radius: 7px;
  padding: 10px 0px;
  /* margin-top: 20px; */
  margin: 10px;
}

.input_width {
  width: 50%;
}

.text_color {
  color: #20202a;
  font-size: 18px;
}

.width_pdf {
  max-width: 820px !important;
  margin: 0px auto !important;
}

@media screen and (max-width: 769px) {
  .post__buttons {
    margin-top: 10px;
  }
}

@media screen and (max-width: 426px) {
  .post__buttons {
    display: flex;
    justify-content: space-between;
  }
  .rpv-core__inner-page {
    padding: 0px !important;
  }
}

@media screen and (max-width: 380px) {
  .input_width {
    width: 80%;
  }
}

.action_hover:hover > span {
  text-decoration: underline;
}

.post_deleted {
  padding: 15px;
  border: 2px solid red;
  color: red;
  background-color: #ff000029;
}

.overflow_hidden {
  overflow: hidden;
}

.bookmark_img {
  width: 17px;
  height: 19px;
}
