.org__link ul {
    display: flex;
    list-style: none;
    cursor: pointer;
    padding-left: 10px !important;
}

#height_org_header {
    height: 150px;
}


.org__link li {
    padding: 5px;
    text-decoration: none;
    margin: 10px;
}

.email_text {
    font-size: 16px;
    font-weight: 400;
    color: rgb(54, 69, 79);
}

.portal_text {
    font-size: 16px;
    font-weight: 400;
    padding-left: 35px;
}

.portal_update {
    border: none !important;
    background-color: white !important;
    color: rgb(25, 118, 210) !important;
}

.people_text {
    font-size: 18px;
    font-weight: 550;
    color: #007ECE;
}

.people_card {
    height: 90px;
    border-radius: 16px !important;
    border: 0.10000000149011612px solid #e7e7e7;
    box-shadow: 0px 3px 6px #00000029 !important;
    background-repeat: no-repeat;
    background-color: white;
    margin: 10px 15px;
}


.doctors img {
    height: 50px;
    width: 50px;
    border-radius: 8px;
    border: 2px solid #ffff;
}

.doctorname_text {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: bold;
    color: #36454f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* padding: 0px 10px; */
}

.doctors_tagline {
    font-size: 16px;
    font-weight: 400;
    color: #707070;
}

.doctors_role {
    color: #2E3B43;
    font-size: 14px;
    font-weight: 400;
}

.about_org_header {
    padding: 10px 30px;
}

.about_org_text {
    font-size: 18px;
    font-weight: 550;
    color: rgb(35, 122, 196);
}

.job_org_opening {
    max-height: 200px;
}

.about_height {
    max-height: 120px;
}

.post_job {
    border: none !important;
    background-color: white !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #003BFF !important;
    text-transform: capitalize !important;
}

.job_text {
    font-size: 16px;
    font-weight: 400;
    color: #003BFF;
    padding-left: 10px;
}

.job_posting {
    margin: 10px 20px;
}

.request_text {
    font-size: 18px;
    color: #36454f;
    font-weight: 400;
    padding: 8px;
}

.verify_people_header {
    padding: 10px 20px;
}

.verify_people_header1 {
    padding: 20px;
}

.clear_round_icon {
    font-size: 36px !important;
}

.emp_text {
    font-size: 18px;
    font-weight: 550;
    padding: 0px 20px;
}

.desktop_view_org {
    display: block;
    margin-top: 2px;
}

.job_register {
    border: none !important;
    background-color: white !important;
    font-size: 16px !important;
}

.header_org {
    display: flex;
    align-items: center;
    width: 50%;
}

.follow_hgt_wdth {
    width: 200px;
    height: 210px;
    border-radius: 16px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    margin-right: 0px !important;
}

.following_images {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    border: 2px solid #ffff;
    object-fit: cover;
}

.viewprofile_btn {
    border: 1px solid #257fc4 !important;
    border-radius: 12px !important;
    height: 30px !important;
    color: #237dcd !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    width: 100px !important;
    text-transform: capitalize !important;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
}

.cards_flex {
    padding: 15px 0px;
    display: -webkit-inline-box !important;
    overflow-x: scroll;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
}

.card_connent_profile {
    padding: 0px !important;
}

.orgpeople_smallscreen {
    display: none !important;
}

.orgpeople_desktop {
    display: block;
}




@media screen and (max-width:1135px) {
    .follow_hgt_wdth {
        width: 180px;
        height: 210px;
        border-radius: 16px !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        margin-right: 0px !important;
    }

    .card_followers_margin {
        margin: 5px !important;
    }
}

@media screen and (max-width:1024px) {
    .follow_hgt_wdth {
        width: 170px;
        height: 210px;
        border-radius: 16px !important;
        box-shadow: 0px 3px 6px #00000029 !important;
    }

    .doctorname_text {
        font-size: 14px;
    }
}

@media screen and (max-width:961px) {
    #follow_padding {
        padding-left: 40px;
    }

    .desktop_view_org {
        display: none !important;
    }

    .orgpeople_smallscreen {
        display: block !important;
    }

    .orgpeople_desktop {
        display: none !important;
    }

    .padding_editprofile {
        padding-left: 30px;
    }

    .website_url {
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;

    }

    .website_text {
        font-size: 14px;
        font-weight: 400;
    }





}

@media screen and (max-width:600px) {
    #margin_verified {
        margin: 20px 8px 0px 0px;

    }
}


@media screen and (max-width:481px) {
    .header_org {
        display: flex;
        align-items: center;
        width: 70%;
    }

    #follow_padding {
        padding-left: 20px;
    }

    #followers_blockend {
        margin-block-end: 40px;
    }

    .clear_round_icon {
        font-size: 28px !important;
    }

    .doctorname_text {
        font-size: 14px;
    }

    .verify_people_header {
        padding: 0px;
    }

    .doctors_tagline {
        font-size: 12px;
    }

    .emp_text {
        font-size: 16px;
    }

    .padding_editprofile {
        padding-left: 10px;
    }

    #height_org_header {
        height: 160px;
    }

    .margin_org_details {
        margin-top: 25px;
    }

    #margin_verified {
        margin: 30px 8px 0px 0px;

    }
}

@media screen and (max-width:375px) {


    .doctorname_text {
        font-size: 12px;
    }

    .doctors_tagline {
        font-size: 11px;
    }

    #card_content {
        padding: 8px !important;
    }

    #margin_verified {
        margin: 30px 18px 0px 0px;
    }
}