.showImg {
  position: relative;
  width: 50px;
}

div.showImg:hover .editImg {
  display: block !important;
}

div.showImg .editImg {
  display: none !important;
}

.uploadImgBg {
  cursor: pointer;
  color: black;
  width: 71px;
  height: 36px;
  font-size: 16px;
  background-color: black;
  opacity: 0.7;
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  top: 35px;
}

.uploadIcon {
  color: white;
  font-size: 12px !important;
  position: absolute;
  top: 10px;
  left: 30px;
}

button.clr-btn {
  background-color: #00a0a0;
  border-radius: 20px;
  color: white;
  text-transform: capitalize;
  font-weight: 400;
  padding: 5px 20px;
}

.add-btn {
  padding: 15px 30px !important;
  background-color: #5145d0 !important;
  width: 40px;
}
.remove-btn {
  padding: 15px 30px !important;
  background-color: red !important;
  width: 40px;
}

.editorAbout {
  height: 400px !important;
  border: 1px solid red !important;
  /* overflow-y: hidden; */
}

.overViewEditor {
  border: 1px solid yellow;
  height: 400px;
}

.editorToolbar {
}
