.header {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  /* padding-top: 10px; */
  padding-bottom: 10px;
  background-color: #fbfbfb;
  /* background-image: url('../../../images/headerbg.svg'); */
}
.header__left {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding-top: 10px;
}
.header__right {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right:10px  */
}
.header__left > img {
  object-fit: contain;
  height: 45px;
}
.header__search {
  display: flex;
  width: 334px;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  height: 22px;
  color: #007dcd;
  background-color: #e1f3ff;
  margin-left: 20px;
}
.header__search > input {
  background: none;
  outline: none;
  border: none;
}
.header__icon {
  margin-left: 30px;
}
/* .header-bell__png {
  height: 24px;
  width: 24px;
  margin-left: 50px;
} */

@media screen and (max-width: 768px) {
  /* .header__left {
    display: flex;
    justify-content: space-between;
  } */
}

.appvault-btn{
  justify-content: flex-start!important;
  flex: 1!important;
  background-color: #8204B2!important;
  color: white!important;
  padding: 5px 10px!important;
  border-radius: 10px!important
}