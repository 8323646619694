.heading_public__text {
  font-size: 18px;
  font-weight: 550;
  color: rgb(35, 122, 196);
}

.flex_center {
  display: flex;
  align-items: center;
}

.about__write__box {
  /* max-height: 120px; */
  /* max-height: 330px; */
  /* overflow-y: scroll; */
}

.about__write__box h6 {
  font-size: 16px;
  font-weight: 500;
}

.about--heading {
  font-size: 18px;
  font-weight: bold;
}

.about--sub {
  margin-top: 13px;
  font-size: 16px;
  font-weight: 300;
}

.Desktop_about_page {
  display: block;
}

.Mobile_about_page {
  display: none;
}

.about_page {
  width: 100%;
  /* margin-top: 15px; */
}

@media screen and (max-width: 769px) {
  .Desktop_about_page {
    display: none;
  }

  .Mobile_about_page {
    display: block;
  }
}
