.profile_view_sidebar__left {
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
  padding: 10px 0px;
}

#education_img {
  height: 55px !important;
  width: 55px !important;
  border-radius: 7px !important;
  width: 20% !important;
}

.border_img {
  border: 0.10000000149011612px solid #e7e7e7;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  background-color: white;
}

.height_public_pages {
  max-height: 260px;
  overflow-y: scroll !important;
  overflow: hidden;
  padding: 0px 20px;
}

.app__one {
  max-width: 1280px;
  margin: auto;
}

.sidebar_left_bottom_card {
  margin-top: 10px;
}

.sidebar__user_details {
  display: flex;
  flex-direction: column;
  /* margin-top: 55px; */
  margin-left: 175px;
}

.sidebar__user_details p {
  margin-left: 17px;
}

.sidebar__user__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-left: 11px;
}

.profile__view__sidebar__icons {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar__user__info span {
  font-size: 15px;
}

.profile__view__Desktop {
  display: block;
}

.profile__view__Mobile {
  display: none;
}

.profile_view_sidebar_desktop {
  display: block;
}

@media screen and (max-width: 961px) {
  .profile__view__Desktop {
    display: none;
  }

  .profile__view__Mobile {
    display: block;
    margin-block-end: 75px;
  }

  .profile_view_sidebar_desktop {
    display: none;
  }
}

@media screen and (max-width: 481px) {
  .sidebar__user_details {
    margin-left: 120px;
  }
}
@media screen and (max-width: 375px) {
  .sidebar__user_details {
    margin-left: 120px;
  }
}
