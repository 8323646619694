/* .MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root{
  padding: 10px;
} */
.hide_iframe_border {
  border: none !important;
  box-shadow: none !important;
  display: flex !important;
  justify-content: center !important;
  /* margin-left: 45px; */
}

#qualification_width_p {
  width: 100%;
}

#qualification_width_ch1 {
  width: 20%;
}

#qualification_width_ch2 {
  width: 80%;
}

.date_acc_text {
  font-size: 12px;
  font-weight: 300;
}

.edu_text {
  font-weight: normal;
  font-size: 18px;
  padding-bottom: 2px;
}

.edu_sub_text {
  font-weight: normal;
  font-size: 16px;
  padding-bottom: 3px;
  color: #36454f;
  /* padding-top: 4px; */
}

.edu_date_text {
  font-weight: 300;
  font-size: 14px;
  padding-bottom: 3px;
  color: #36454f;
}

.date_text {
  font-size: 14px;
  font-weight: 300;
}

.data_exp_text {
  color: #36454f;
  font-size: 16px;
}

.box_shadow {
  box-shadow: 0px 8px 6px -2px #e7e7e7;
}

.add_new_org {
  font-size: 18px;
}

.border_new_org {
  border: 1px solid grey;
  border-radius: 8px;
  padding: 10px;
  margin: 0px !important;
}

#margin_acc {
  margin-left: 20px;
  margin-bottom: 10px;
  max-height: 540px;
  overflow: hidden;
  overflow-y: scroll !important;
}

.contact_left {
  /* margin-left: 50px; */
}

#mentions1 {
  color: #7665ff !important;
}

.about___text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(118, 118, 118);
}

.border_img2 {
  /* border: 0.10000000149011612px solid #e7e7e7; */
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  background-color: white;
  /* border-radius: 8px !important; */
}

#about__height {
  /* max-height: 120px; */
  /* max-height: 300px;
  overflow-y: scroll; */
}

#about__height::-webkit-scrollbar {
  display: none;
}

.unofficial_text {
  font-size: 16px;
  font-weight: 400;
  color: #36454f;
  font-style: italic;
}

.city_text {
  font-size: 14px;
  /* font-weight: 300; */
  font-style: italic;
  color: #36454f;
}

.des_text {
  font-size: 18px;
  font-weight: 500;
}

.acc_Heading_text {
  font-size: 18px;
  font-weight: 550;
  color: rgb(35, 122, 196);
}

#border_exp_btn {
  border: 1px solid #257fc4;
  padding: 10px 15px;
  border-radius: 24px;
  /* border-color: #237dcd; */
}

.header_acc_title {
  font-size: 21px;
  color: #247bc4;
  font-weight: 600;
  padding-left: 10px;
}

.congo_text {
  font-size: 21px;
  font-weight: 400;
  padding-left: 10px;
}

#dialog_box_sizes {
  padding: 10px !important;
}

.skipp {
  color: #ff0000 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.acc_badge h1 {
  color: #4a33ff;
  font-size: 21px;
  text-align: center;
}

.title_acc_text {
  font-size: 18px;
  font-weight: 550;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.title_acc_text1 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.margin_exp {
  margin: 0px !important;
}

#input_summary_height {
  height: 60px !important;
  min-width: 73vh !important;
}

.btn_author {
  color: #7665ff !important;
  font-size: 18px !important;
  text-transform: capitalize !important;
}

.border_img {
  border: 0.10000000149011612px solid #e7e7e7;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  background-color: white;
  /* border-radius: 8px !important; */
}

.form1_submit {
  height: 30px !important;
  background-color: #4a33ff !important;
  font-weight: 500 !important;
  width: 100px !important;
  text-transform: capitalize !important;
  border: 1px solid #257fc4 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
}

.create_post_btn {
  height: 30px !important;
  background-color: #4a33ff !important;
  font-weight: 500 !important;
  width: 100px !important;
  text-transform: capitalize !important;
  border: 1px solid #257fc4 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
}

.add_attach {
  color: #7665ff;
  font-size: 16px;
  font-weight: 350px !important;
  padding-left: 10px;
}

.open_pdf {
  color: #7665ff !important;
  font-size: 16px !important;
  font-weight: 350px !important;
  padding-left: 10px !important;
  text-transform: capitalize !important;
  width: 120px !important;
  height: 30px !important;
}

.attachment_btn {
  border: 1px solid #257fc4 !important;
  border-radius: 8px !important;
  height: 30px !important;
  width: 180px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  display: flex !important;
  justify-content: space-evenly !important;
  /* align-items: center !important; */
  margin-top: 15px !important;
  /* padding-left: 30px !important; */
}

.link_text {
  font-size: 14px;
  font-weight: 300;
}

.ppub {
  box-shadow: 0px 3px 6px #00000029;
  background: #0000008a;
  padding: 8px;
}

.publications_text h2 {
  font-size: 22px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.margin_myprofile {
  padding-right: 10px !important;
}

.research_article {
  padding: 15px;
}

.research_text {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
}

.dflex_al_jc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.see__all h4 {
  color: #ffffff;
  font-size: 11px;
  font-weight: 500;
}

.url_text p {
  padding-top: 8px;
  font-size: 9px;
  color: #000000;
}

.justify_content {
  justify-content: space-between;
}

.article_text h4 {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  text-align: inherit;
  /* padding: 0px !important; */
}

.article_text h5 {
  font-size: 11px;
  color: #000000;
  font-weight: 400;
  text-align: inherit;
  padding: 4px;
}

.article_text h5 {
  font-size: 9px;
  color: #000000;
  font-weight: 400;
  text-align: inherit;
  /* padding: 4px; */
}

.media_text {
  font-size: 18px;
  font-weight: 400;
  color: #1900b2;
  padding: 10px 0px;
  cursor: pointer;
}

#bookmark_padd {
  padding: 10px 0px;
  cursor: pointer;
}

.media_text1 {
  font-size: 18px;
  font-weight: 400;
  color: #47aee1;
  padding: 10px 0px;
}
.media_text3 {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  padding: 10px 0px;
}

.inter_job p {
  font-size: 18px;
  color: #1900b2;
  font-weight: 500;
}

.gallery_text {
  color: #257fc4;
  font-size: 18px;
}

.publication_text {
  display: flex;
  justify-content: space-between;
}

.publication_text p {
  font-size: 14px;
  color: #ffffff;
}

.source_text p {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
}

.news_media_img {
  max-height: 300px;
  overflow: hidden;
}

.download_journal {
  font-size: 8px !important;
  font-weight: 400 !important;
}

.read_journal {
  font-size: 8px !important;
  font-weight: 400 !important;
}

.image_download {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.article_description {
  font-size: 14px;
  font-weight: 400;
  color: rgb(54, 69, 79);
  padding-top: 8px;
}

.link_publication {
  font-size: 14px;
  color: #6a75fd;
  padding-left: 10px;
}

.publication_dop {
  font-size: 14px;
  font-weight: 500;
}

.publication_date {
  font-size: 12px;
  font-weight: 400;
}

#mobilecontact_list {
  list-style-type: disc;
  padding-left: 20px;
}

.media_metaimage {
  width: 100%;
  max-height: 180px;
  object-fit: cover;
  object-position: center;
}

.media_metaimage1 {
  width: 100%;
  height: 180px;
  object-fit: contain;
  object-position: center;
}

.share_article {
  padding: 2px 20px !important;
  margin-top: 10px !important;
  width: 120px !important;
  /* border:1px solid #106DBE!important; */
  border-radius: 8px !important;
  font-size: 12px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  margin-left: 20px !important;
}

.article_title {
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.article_title_patient {
  font-size: 20px;
  font-weight: 550;
}

.butn_arrow_back {
  border: none !important;
  padding: 0px !important;
}

.meta_display_img1 {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

/* .con_btn {
  padding-bottom: 10px;
} */

/* #recent_activity_h2 {
    font-size: 24;
    font-weight: 400;
  }
  #recent_post_h3 {
    font-size: 14px;
    color: #000000;
    font-weight: 300;
  }
  #recent_post_h4 {
    font-size: 12px;
    color: #000000;
    font-weight: 300;
  } */

@media screen and (max-width: 961px) {
  .btn_message {
    border-radius: 5px !important;
    height: 24px !important;
    color: #ffffff !important;
    font-weight: 500 !important;
    width: 86px !important;
    margin-right: 11px !important;
    background-color: #197fc4 !important;
    text-transform: capitalize !important;
  }

  .react_swipable_view_960 {
    display: block;
  }

  .react_swipable_view_769 {
    display: none;
  }

  .media_metaimage {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    object-position: center;
  }

  .date_text {
    font-size: 14px;
    font-weight: 300;
  }

  #margin_acc {
    margin-left: 0px !important;
  }

  /* .con_btn {
    margin-bottom: 10px !important;
  } */

  .unofficial_text {
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
  }

  .des_text {
    font-size: 16px;
    font-weight: 400;
  }
}

@media screen and (max-width: 769px) {
  .react_swipable_view_960 {
    display: none;
  }

  .react_swipable_view_769 {
    display: block;
  }

  .mobile_swipper {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 480px) {
  .article_title {
    font-size: 16px;
  }

  .article_title_patient {
    font-size: 18px;
    font-weight: 500;
  }

  .media_metaimage {
    max-height: 200px;
    object-fit: cover;
  }

  .edu_text {
    font-weight: normal;
    font-size: 16px;
    padding-bottom: 2px;
  }

  .edu_sub_text {
    font-weight: lighter;
    font-size: 14px;
    padding-bottom: 3px;
    color: #36454f;
  }

  .edu_date_text {
    font-weight: 300;
    font-size: 14px;
    padding-bottom: 3px;
    color: #36454f;
  }

  .data_exp_text {
    color: #36454f;
    font-size: 14px;
  }

  .unofficial_text {
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
  }

  .date_text {
    font-size: 12px;
    font-weight: 300;
  }

  .des_text {
    font-size: 14px;
    font-weight: 400;
  }
}

@media screen and (max-width: 481px) {
  #margin_acc {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }
}
