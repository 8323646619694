.content {
  /* height: 450px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.templates {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}
.items {
  background-color: #d9eaf5;
  padding: 0.5rem;
  border-radius: 4px;
  flex: 1;
}

.imagewrapper {
  height: 150px;
  background-color: white;
  margin: 5px 0;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
}
