.fonthead {
    font-size: 16px;
    font-weight: 700;
}

.normalfont {
    font-size: 14px;
    font-weight: 500;
}

.font {
    font-size: 14px;
    font-weight: 400;
    color: #2E3B43;
}

.eventpoll {
    /* border:1px solid lightgrey; */
    border-radius: 20px;
    /* height:225px; */
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 10px;
    /* width: 525px; */
    /* overflow-y: auto; */


}

.eventpoll::-webkit-scrollbar-thumb {
    background-color: gray;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
}

.eventpoll::-webkit-scrollbar {
    width: 16px;
}

/* ::-webkit-scrollbar  */
/* { width: 15px;} */
.dflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.p__l {
    padding-left: 100px;
}

.eventtype_p_l {
    /* padding-left: 88px; */
    width: 70%;
}

.pre_reg_p_l {
    padding-left: 46px;
}

.eventmode_p_l {
    /* padding-left: 85px; */
    width: 70%;
}

.selecttemplate_p_l {
    padding-left: 46px;
}

.webinar_platform_p_l {
    padding-left: 48px;
}

.meeting_link_p_l {
    padding-left: 78px;
}

.evnt-btn {
    background-color: #007dcd !important;
    color: white !important;
}

.see-more {
    font-size: 13px;
    text-align: right;
    width: 100%;
}

.event_list {
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.74);
    border-bottom: none;
    width: 35%;
    padding: 0px;
    margin: 10px
}

.event_list li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.74);
    padding: 8px;
    cursor: pointer;
}

.event_mention {
    position: relative;
}

.event_mention>div>div {
    height: 40px !important;
}

.mention_label {
    position: absolute !important;
    top: -10px !important;
    left: 11px !important;
    font-size: 13px !important;
    background-color: white !important;
    padding: 0 4px !important;
}

@media screen and (max-width:1024px) {
    /* .evnt-btn {
        font-size: 14px !important;
    } */
}

.pointer_none{
    pointer-events: none!important;
}