.join-text{
    font-size: 36px!important;
}
.con-color{
    color: #0472B9;
}
.con-hr{
    width: 25%;
    border-top: 2px solid #0472B9!important;
    background-color: #0472B9;

}
.m-auto{
    margin: auto!important;
}
.ebm-color{
    color: #013353!important
}
.con__btn{
    background-color: #007DCD!important;
    outline: none;
    border: none;
    border-radius: 15px;
    font-size: 14px;
    color: white!important;
}