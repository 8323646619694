.largedevices {
  display: block;
}

.smalldevices {
  display: none;
}

@media screen and (min-width: 960px) {
  .largedevices {
    display: block !important;
  }

  .smalldevices {
    display: none !important;
  }
}

@media screen and (max-width: 960px) {
  .largedevices {
    display: none;
  }

  .smalldevices {
    display: block;
  }
}
