.header1 {
  background-color: #750084;
  padding: 15px 20px;
  font-size: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.profile__cover {
  height: 200px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  margin: 0px !important;
}

.orz__cover__edit {
  margin-right: 20px;
  margin-top: 50px !important;
}

.orz__cover__edit img {
  position: absolute !important;
  margin-top: 15px !important;
  right: 0px !important;
}

.organisation_edit {
  margin-top: 20px !important;
  margin-right: 30px;
  top: 12px;
  right: 12px !important;
}

.show-image {
  position: relative;
  float: left;
}

#img_orz {
  position: absolute;
  height: 144px;
  width: 144px;
  top: -35px;
  margin-left: 30px;
  border-radius: 20px;
}

.orgname {
  text-transform: capitalize;
  margin-top: 5px;
  font-size: calc(1.275rem + 0.3vw);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.edit_profile {
  background-color: #47aee1 !important;
  border-radius: 25px !important;
  padding: 5px 10px;
}

.status_icon {
  color: #2580eb !important;
  font-size: 15px !important;
}

.verify_kyc {
  color: red !important;
  border-color: red !important;
  border-radius: 15px !important;
  padding: 1px 5px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
}

.left_card svg {
  color: #01cde5;
}

.org_layout > p {
  margin-top: 5px !important;
  margin-bottom: 7px !important;
}

#orz_image_edit {
  font-size: 16px;
  color: black;
  position: absolute;
  top: 14px;
  left: 34px;
  width: 144px;
  height: 60px;
  border-radius: 0px 0px 20px 20px;
  background-color: black;
  opacity: 0.7;
}

.close-btn {
  padding: 1px 15px !important;
  color: red !important;
  text-transform: capitalize !important;
  border-color: red !important;
  margin-right: 10px !important;
}

.save-btn {
  padding: 1px 15px !important;
  background-color: #5145d0 !important;
  text-transform: capitalize !important;
  border: 1px solid #5145d0 !important;
}

.save-btn:disabled {
  background-color: lightgrey !important;
  border: 1px solid lightgrey !important;
}

.input_padding input {
  padding: 10px 13px !important;
}

.input_shadow .MuiInputBase-root {
  box-shadow: 2px 3px 10px lightgrey;
  border-radius: 10px;
  background-color: white;
}

.input_shadow fieldset {
  border-color: transparent;
}

.input_shadows {
  .MuiInputBase-root {
    box-shadow: 2px 3px 10px lightgrey;
    border-radius: 10px;
  }

  fieldset {
    border-color: transparent;
  }
}

.select_padding input {
  padding: 1px 4px !important;
}

#wh_table th {
  text-align: center;
}

#wh_table td:nth-child(1),
td:nth-child(2),
th:nth-child(1) {
  text-align: left !important;
  text-transform: capitalize;
}

#wh_table td:nth-child(1),
td:nth-child(2),
th:nth-child(1) p {
  margin: 0 !important;
}

#wh_table .wh_head th {
  font-weight: 600;
}

#wh_table .wh_body .MuiCheckbox-root .MuiSvgIcon-root {
  font-size: 1.2rem;
}

#wh_table .wh_body svg {
  cursor: pointer;
}

#wh_table .wh_body td {
  color: black !important;
  border-bottom: 0px;
  padding: 10px;
  /* vertical-align: text-top; */
}

#wh_table .wh_body .sfield_left label {
  left: 12px;
}

#wh_table .wh_body .sf_left label {
  left: 9px;
}

#wh_table .wh_body .efield_left label {
  left: 5px;
}

#wh_table .wh_body .ef_left label {
  left: 6px;
}

#wh_table .wh_body .wh_time .MuiInputBase-root {
  width: 100px;
  height: 25px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

#wh_table .wh_body .wh_time .MuiInputBase-input {
  padding: 7px !important;
  font-size: 13px;
}

#wh_table .wh_body .wh_time label {
  font-size: 13px;
  top: 1px;
}

#wh_table .wh_body .wh_time fieldset {
  font-size: 13px;
}

/* overview */

.call {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 5px;
  gap: 5px;
}

.direction {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 30px;
  /* margin:12px 0 */
}

.workinghours {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
}

.timing {
  background: #d9eaf5;
  width: max-content;
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
}

.camera {
  text-transform: capitalize;
  padding: 3px 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #3540bf;
}

.work-hours {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 5;
  /* gap: 30px; */
  align-items: center;
}

.work-hours > p {
  width: 200px;
  color: #020200;
  font-weight: 600;
  margin-bottom: 5px;
}

.open-hospital {
  color: green !important;
}

.show-all-button_appointment {
  color: #01cde5 !important;
  cursor: pointer;
}
