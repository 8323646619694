.box_shadow {
  box-shadow: 0px 8px 6px -2px #e7e7e7;
}

.title_acc_text {
  font-size: 18px;
  font-weight: 550;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.title_acc_text1 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.pdf_post > .react-pdf__Page > .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: fill !important;
  display: flex !important;
  justify-content: center !important;
  margin-top: 0px !important;
  max-height: 560px !important;
}

.bg_transperency {
  display: flex !important;
  justify-content: space-between !important;
}
