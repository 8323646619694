.App_Details{
  display:inline-flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 15px;
}
.Subscription_App{
    display: flex;
}
.Subscription_App_Ratings{
    margin-top: 8px;
}
.Subscription_App_Content{
    margin-left: 25px;
}
.Subscription_App_Details h3{
    font-size: 18px;
    font-weight: 450;
    opacity: 1;
}
.Subscription_App_Details p{
    font-size: 13px;
    font-weight: 400;
}
.Subscription_App_Reviews_content{
    display: flex;
    justify-content: space-between;
}
.buttons{
    justify-content: space-around;
}
.buttons p{
    margin-left: 40px;
}
.description_content{
    width: 506px;
    margin-top: 12px;
    margin-bottom: 12px;
}
.tags h6{
    margin-bottom: 16px;
}
.subscription_content{
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
   
}
.gallery_icon_image{
    margin-top: 24px;
}
.trail_box h6{
font-size: 16px;
font-weight: 400;
}
.trail_details p{
    font-size: 16px;
    margin-left: 60px;
    }
.trail_details{
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}
.pricing_details{
    display: flex;
    margin-bottom: 13px;
}
.pricing_details p{
    font-size: 16px;
    font-weight: 400;
    margin-left: 45px;
}
.instalations_details h6{
font-size: 13px;
font-weight: 400;
}
.instalation_source_name{
    margin-bottom: 12px;
}
.privacy_box h6{
    font-size: 16px;
    font-weight: 400;
    margin-top: 14px; 
}
.Main_Card_Header{
    display: flex;
    align-items: center;
}
.Main_Card_Header h5{
    font-size: 17px;
    font-weight: 400;
}
.images{
    margin-left: 60px;
}
.like_or_dislike_icon{
    display:flex;
    float: right;
    justify-content: space-around;
}
.like_or_dislike{
    display: flex;
    justify-content: space-around;
}
.like_icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.like_icon img{
    height: 24px;
}
.like_icon a{
    margin-top: -5px;
    margin-left: 4px;
}

/* ::-webkit-scrollbar {
    width: 0px; } */

