.interactive__apps {
  display: flex;
  align-items: center;

  width: 92%;
  padding-left: 2px;
}

.border_img {
  border: 0.10000000149011612px solid #e7e7e7;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  background-color: white;
}

.interactive__app__title {
  color: #2d0472;
  padding-bottom: 10px;
}

.allElibrary_laptop {
  display: block;
}

.border_img_radius {
  border: 0.10000000149011612px solid #e7e7e7;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.allElibrary_responsive {
  display: none;
}

@media screen and (max-width: 768px) {
  .allElibrary_laptop {
    display: none;
  }

  .allElibrary_responsive {
    display: block;
  }
}