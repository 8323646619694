.active {
  padding: 5px;
  color: white;
  text-align: "";
  /* margin-bottom: 5px; */
  background-color: #47aee1;
  border-radius: 15px;
  cursor: pointer;
}
.inActive {
  padding: 5px;
  color: black;
  text-align: "";
  /* margin-bottom: 5px; */
  border-radius: 15px;
  border: 1px solid lightgray;
  cursor: pointer;
}
