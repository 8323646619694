.headerOption{
   
    cursor:pointer;
    margin-left: 15px!important;
    margin-right: 15px!important;
    /* padding-top: 5px; */
    
    padding: 0px!important;
    background-color: transparent!important;
}

.app-active{
    padding-bottom: 0px!important;
    border-top: 4px solid #007DCD!important;
    font-weight: bold!important;
}

@media only screen and (max-width: 899px){
    .path{
        display: block;
        align-items: center;
    }

    .headerOption{
        margin: 0px 12px!important;
        margin-bottom: 10px!important;
    }

    .active-item{
        border-bottom: 3px solid #007DCD!important;
        font-weight: bold;
        /* padding-bottom: 10px; */
        transition: 1s;
    }
}
.headerOption__title{
    font-size:12px;
    text-align: center;
    /* font-weight:600; */
}
.path{
    text-decoration: none!important;
    color: inherit!important;
}
@media only screen and (min-width: 900px){
    .active-item{
        border-top: 4px solid #007DCD!important;
        font-weight: bold!important;
        padding-bottom: 10px!important;
    }
}
.headerOption__icon{
    width: 100%;
    display: flex;
    height: 40px;
}