#view:hover .description_ele {
  display: block;
}

.description_ele {
  display: none;
  background-color: white;
  position: absolute;
  bottom: 10px;
  z-index: 999;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 20px;
  /* width: "calc(100vw - 304px)"; */
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

#view {
  height: 100%;
  overflow: hidden;
}
