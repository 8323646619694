/* padding */
/*Padding All*/

.p1 {
  padding: 10px;
}
.p0 {
  padding: 0px;
}

/* Padding Top/Bottom */
.pv0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.pv0-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pv0-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pv1 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pv1-5 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pv2 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pv2-5 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.pv3 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pv3-5 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.pv4 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.pv4-5 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.pv5 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.pv5-5 {
  padding-top: 55px;
  padding-bottom: 55px;
}
.pv6 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.pv6-5 {
  padding-top: 65px;
  padding-bottom: 65px;
}
.pv7 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.pv7-5 {
  padding-top: 75px;
  padding-bottom: 75px;
}
.pv8 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.pv8-5 {
  padding-top: 85px;
  padding-bottom: 85px;
}
.pv9 {
  padding-top: 90px;
  padding-bottom: 90px;
}

/* Padding Left/Right */
.ph0 {
  padding-left: 0px;
  padding-right: 0px;
}
.ph0-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.ph1 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.ph1-5 {
  padding-left: 15px;
  padding-right: 15px;
}
.ph2 {
  padding-left: 20px;
  padding-right: 20px;
}
.ph2-5 {
  padding-left: 25px;
  padding-right: 25px;
}
.ph3 {
  padding-left: 30px;
  padding-right: 30px;
}
.ph3-5 {
  padding-left: 35px;
  padding-right: 35px;
}
.ph4 {
  padding-left: 40px;
  padding-right: 40px;
}
.ph4-5 {
  padding-left: 45px;
  padding-right: 45px;
}
.ph5 {
  padding-left: 50px;
  padding-right: 50px;
}
.ph5-5 {
  padding-left: 55px;
  padding-right: 55px;
}
.ph6 {
  padding-left: 60px;
  padding-right: 60px;
}

/* Padding Left */
.pl0 {
  padding-left: 0px;
}
.pl0-5 {
  padding-left: 5px;
}
.pl1 {
  padding-left: 10px;
}
.pl1-5 {
  padding-left: 15px !important;
}
.pl2 {
  padding-left: 20px;
}
.pl2-5 {
  padding-left: 25px;
}
.pl3 {
  padding-left: 30px;
}
.pl3-5 {
  padding-left: 35px;
}
.pl4 {
  padding-left: 40px;
}
.pl4-5 {
  padding-left: 45px;
}
.pl5 {
  padding-left: 50px;
}
.pl5-5 {
  padding-left: 55px;
}
.pl6 {
  padding-left: 60px;
}

/* Padding Right */
.pr0 {
  padding-right: 0px;
}
.pr0-5 {
  padding-right: 5px;
}
.pr1 {
  padding-right: 10px;
}
.pr1-5 {
  padding-right: 15px !important;
}
.pr2 {
  padding-right: 20px;
}
.pr2-5 {
  padding-right: 25px;
}
.pr3 {
  padding-right: 30px;
}
.pr3-5 {
  padding-right: 35px;
}
.pr4 {
  padding-right: 40px;
}
.pr4-5 {
  padding-right: 45px;
}
.pr5 {
  padding-right: 50px;
}
.pr5-5 {
  padding-right: 55px;
}
.pr6 {
  padding-right: 60px;
}

/* Padding Top */
.pt0 {
  padding-top: 0px;
}
.pt0-5 {
  padding-top: 5px;
}
.pt1 {
  padding-top: 10px;
}
.pt1-5 {
  padding-top: 15px !important;
}
.pt2 {
  padding-top: 20px;
}
.pt2-5 {
  padding-top: 25px;
}
.pt3 {
  padding-top: 30px;
}
.pt3-5 {
  padding-top: 35px;
}
.pt4 {
  padding-top: 40px;
}
.pt4-5 {
  padding-top: 45px;
}
.pt5 {
  padding-top: 50px;
}
.pt5-5 {
  padding-top: 55px;
}
.pt6 {
  padding-top: 60px;
}
.pt6-5 {
  padding-top: 65px;
}
.pt7 {
  padding-top: 70px;
}
.pt7-5 {
  padding-top: 75px;
}
.pt8 {
  padding-top: 80px;
}
.pt8-5 {
  padding-top: 85px;
}
.pt9 {
  padding-top: 90px;
}

/* Padding Bottom */
.pb0 {
  padding-bottom: 0px;
}
.pb0-5 {
  padding-bottom: 5px;
}
.pb1 {
  padding-bottom: 10px;
}
.pb1-5 {
  padding-bottom: 15px;
}
.pb2 {
  padding-bottom: 20px;
}
.pb2-5 {
  padding-bottom: 25px;
}
.pb3 {
  padding-bottom: 30px;
}
.pb3-5 {
  padding-bottom: 35px;
}
.pb4 {
  padding-bottom: 40px;
}
.pb4-5 {
  padding-bottom: 45px;
}
.pb5 {
  padding-bottom: 50px;
}
.pb5-5 {
  padding-bottom: 55px;
}
.pb6 {
  padding-bottom: 60px !important;
}
.pb6-5 {
  padding-bottom: 65px;
}
.pb7 {
  padding-bottom: 70px;
}
.pb7-5 {
  padding-bottom: 75px;
}
.pb8 {
  padding-bottom: 80px;
}
.pb8-5 {
  padding-bottom: 85px;
}
.pb9 {
  padding-bottom: 90px;
}

/* /PADDING */

/*---------------------*/

/* MARGIN */

/* Margin Top/Bottom */
.mv0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.mv0-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.mv1 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.mv1-5 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.mv2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mv2-5 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.mv3 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mv3-5 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.mv4 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mv4-5 {
  margin-top: 45px;
  margin-bottom: 45px;
}
.mv5 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.mv5-5 {
  margin-top: 55px;
  margin-bottom: 55px;
}
.mv6 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.mv6-5 {
  margin-top: 65px;
  margin-bottom: 65px;
}
.mv7 {
  margin-top: 70px;
  margin-bottom: 70px;
}
.mv7-5 {
  margin-top: 75px;
  margin-bottom: 75px;
}
.mv8 {
  margin-top: 80px;
  margin-bottom: 80px;
}
.mv8-5 {
  margin-top: 85px;
  margin-bottom: 85px;
}
.mv9 {
  margin-top: 90px;
  margin-bottom: 90px;
}

/* Margin Left/Right */
.mh0 {
  margin-left: 0px;
  margin-right: 0px;
}
.mh0-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mh1 {
  margin-left: 10px;
  margin-right: 10px;
}
.mh1-5 {
  margin-left: 15px;
  margin-right: 15px;
}
.mh2 {
  margin-left: 20px;
  margin-right: 20px;
}
.mh2-5 {
  margin-left: 25px;
  margin-right: 25px;
}
.mh3 {
  margin-left: 30px;
  margin-right: 30px;
}
.mh3-5 {
  margin-left: 35px;
  margin-right: 35px;
}
.mh4 {
  margin-left: 40px;
  margin-right: 40px;
}
.mh4-5 {
  margin-left: 45px;
  margin-right: 45px;
}
.mh5 {
  margin-left: 50px;
  margin-right: 50px;
}
.mh5-5 {
  margin-left: 55px;
  margin-right: 55px;
}
.mh6 {
  margin-left: 60px;
  margin-right: 60px;
}

/* Margin Left */
.ml0 {
  margin-left: 0px;
}
.ml0-5 {
  margin-left: 5px;
}
.ml1 {
  margin-left: 10px;
}
.ml1-5 {
  margin-left: 15px;
}
.ml2 {
  margin-left: 20px;
}
.ml2-5 {
  margin-left: 25px;
}
.ml3 {
  margin-left: 30px;
}
.ml3-5 {
  margin-left: 35px;
}
.ml4 {
  margin-left: 40px;
}
.ml4-5 {
  margin-left: 45px;
}
.ml5 {
  margin-left: 50px;
}
.ml5-5 {
  margin-left: 55px;
}
.ml6 {
  margin-left: 60px;
}
.ml10 {
  margin-left: 100px;
}

/* Margin Right */
.mr0 {
  margin-right: 0px;
}
.mr0-5 {
  margin-right: 5px;
}
.mr1 {
  margin-right: 10px;
}
.mr1-5 {
  margin-right: 15px;
}
.mr2 {
  margin-right: 20px;
}
.mr2-5 {
  margin-right: 25px;
}
.mr3 {
  margin-right: 30px;
}
.mr3-5 {
  margin-right: 35px;
}
.mr4 {
  margin-right: 40px;
}
.mr4-5 {
  margin-right: 45px;
}
.mr5 {
  margin-right: 50px;
}
.mr5-5 {
  margin-right: 55px;
}
.mr6 {
  margin-right: 60px;
}

/* Margin Top */
.mt0 {
  margin-top: 0px;
}
.mt0-3 {
  margin-top: 3px;
}
.mt0-5 {
  margin-top: 5px;
}
.mt1 {
  margin-top: 10px;
}
.mt1-5 {
  margin-top: 15px;
}
.mt2 {
  margin-top: 20px;
}
.mt2-5 {
  margin-top: 25px;
}
.mt3 {
  margin-top: 30px;
}
.mt3-5 {
  margin-top: 35px;
}
.mt4 {
  margin-top: 40px;
}
.mt4-5 {
  margin-top: 45px;
}
.mt5 {
  margin-top: 50px;
}
.mt5-5 {
  margin-top: 55px;
}
.mt6 {
  margin-top: 60px;
}
.mt6-5 {
  margin-top: 65px;
}
.mt7 {
  margin-top: 70px;
}
.mt7-5 {
  margin-top: 75px;
}
.mt8 {
  margin-top: 80px;
}
.mt8-5 {
  margin-top: 85px;
}
.mt9 {
  margin-top: 90px;
}

/* Margin Bottom */
.mb0 {
  margin-bottom: 0px;
}
.mb0-3 {
  margin-bottom: 3px;
}
.mb0-5 {
  margin-bottom: 5px;
}
.mb1 {
  margin-bottom: 10px !important;
}
.mb1-5 {
  margin-bottom: 15px;
}
.mb2 {
  margin-bottom: 20px;
}
.mb2-5 {
  margin-bottom: 25px;
}
.mb3 {
  margin-bottom: 30px;
}
.mb3-5 {
  margin-bottom: 35px;
}
.mb4 {
  margin-bottom: 40px;
}
.mb4-5 {
  margin-bottom: 45px;
}
.mb5 {
  margin-bottom: 50px;
}
.mb5-5 {
  margin-bottom: 55px;
}
.mb6 {
  margin-bottom: 60px;
}
.mb6-5 {
  margin-bottom: 65px;
}
.mb7 {
  margin-bottom: 70px;
}
.mb7-5 {
  margin-bottom: 75px;
}
.mb8 {
  margin-bottom: 80px;
}
.mb8-5 {
  margin-bottom: 85px;
}
.mb9 {
  margin-bottom: 90px;
}

/**Margin-Auto**/
.magAuto {
  margin: 0 auto;
}
.mrAuto {
  margin: 0 0 0 auto;
}
.mh-auto {
  margin: auto 0;
}
.m-auto {
  margin: auto;
}

/* /MARGIN */

.sidebar__pb {
  padding-bottom: 10px;
}

.sidebar__pt {
  padding-top: 10px;
}

.ftsz-18 {
  font-size: 18px;
}

.mt-15 {
  margin-top: 15px;
}

.pt-05 {
  padding-top: 5px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pl-3 {
  padding-left: 30px;
}

.pl-1 {
  padding-left: 10px;
}

.pl-2 {
  padding-left: 20px;
}

.pr-2 {
  padding-right: 20px;
}

.pl_5 {
  padding-left: 5px;
}

.sidebar__padding {
  padding: 10px 20px;
}

.im {
  margin: 0px 5px;
}

/* margin */
.sidebar__mb {
  margin-bottom: 10px;
}

.sidebar__ml {
  margin-left: 10px;
}

.m-0 {
  margin: 0px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-1 {
  margin-left: 15px;
}

.ml-5 {
  margin-left: 5px;
}

.sidebar__mt {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top: 10px;
}

.cursor {
  cursor: pointer;
}

/* font */
/**Font-Size**/
.fntXXSm {
  font-size: 9px;
}
.fntXSm {
  font-size: 11px;
}
.fntSm {
  font-size: 13px;
}
.fntSm14 {
  font-size: 14px;
}
.fntMd {
  font-size: 16px;
}
.fntLg {
  font-size: 18px;
}
.fntXLg {
  font-size: 20px;
}

.font-head {
  font-size: 24px;
}

.font-caption {
  font-size: 14px;
}

.font-title {
  font-size: 12px;
}

.font-sm {
  font-size: 10px;
}

/**Font-weight**/
.fntLighter {
  font-weight: 300;
}
.fntLight {
  font-weight: 400;
}
.fntSemiBold {
  font-weight: 500;
}
.fntBold {
  font-weight: 600;
}
.fntBold9 {
  font-weight: 700;
}
.fw__semibold {
  font-weight: 600;
}

.fw__bold {
  font-weight: bold;
}

.fw__normal {
  font-weight: normal;
}

.sidebar__tmb {
  margin-bottom: 20px;
}

.sidebar__lh {
  line-height: 17px;
}

.sidebar__tle {
  line-height: 15px;
  font-weight: 600;
  margin-left: 10px;
}

.line_height30 {
  line-height: 30px;
}
/* icons */
.icon__ml {
  margin-left: 5px;
}

.flex__col {
  display: flex;
  flex-direction: column;
}

.jc__center {
  justify-content: center;
}

/* display */
.d-flex {
  display: flex;
}

.flex__end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

/* border */
.border__bottom {
  border-bottom: 1px solid lightgray;
}

/* color */
.primary {
  background-color: #0077c4;
}

.white {
  color: #ffffff;
}

.theader_color {
  color: #000000e6;
}

.tbody_color {
  color: #00000099;
}

.bg__white {
  background-color: white;
}

.bg__teal {
  background-color: #01cde5;
}

.bg__blue {
  background-color: #d8e8f1;
}

/* container */
.container {
  display: flex;
  margin-top: 24px;
}

/* border-radius */
/* width */
.width__100 {
  width: 100%;
}

.width100 {
  width: 100%;
}

.bg_dark_blue {
  background-color: #257fc4;
}

.space_btw_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.space_around_center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.space_evenly_center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flx {
  display: flex;
}
.flx-col {
  flex-direction: column;
}
.justify-flexEnd {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.text-align-center {
  text-align: center !important;
}
.align-start {
  align-items: flex-start !important;
}
.align-baseline {
  align-items: baseline;
}
.align-flexEnd {
  align-items: flex-end;
}
.align-self {
  align-self: center;
}
.align-self-flexEnd {
  align-self: flex-end;
}
.left {
  float: left;
}
.right {
  float: right;
}
.wrap {
  flex-wrap: wrap;
}

.flx-center {
  display: flex;
  align-items: center;
}

.capitalize {
  text-transform: capitalize;
}

/* Text alignments */
.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.ta__center {
  align-items: center;
}

.ta__left {
  align-items: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* height */
.height {
  height: 100px;
}

.h-250 {
  height: 250px;
}

/* btn */
.btn-bgwhite {
  border: 1px solid #106dbe !important;
  color: #106dbe !important;
  border-radius: 15px !important;
  padding: 4px 32px !important;
  background-color: white;
  font-size: 12px;
  text-transform: capitalize !important;
}

@media screen and (max-width: 769px) {
  .bg__blue {
    background-color: white;
  }
}

@media screen and (max-width: 426px) {
  .h-250 {
    height: 200px;
  }
}

.tohideScrollbar::-webkit-scrollbar {
  display: none !important;
}


