.padding_post {
  margin: 20px 250px;
  border: 1px solid #e5e5dc;
  background-color: #fff;
  border-radius: 10px;
  /* padding: 10px; */
}

.icon_arrowback {
  width: 100%;
  height: 100%;
  background-color: white;
  /* margin: 20px; */
}

.margin_arrow_icon {
  margin: 20px !important;
}

.left_arrow_image {
  position: absolute;
  top: 715px;
  left: 40px;
  color: white;
  font-size: 35px !important;
}

.right_arrow_image {
  position: absolute;
  top: 715px;
  right: 40px;
  color: white;
  font-size: 35px !important;
}

.image_sizes {
  max-height: 100%;
  max-width: 100%;
}

#display_view_post {
  display: flex;
}

.display_view {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin_clear {
  margin-left: 250px;
}

@media screen and (max-width: 1026px) {
  .left_arrow_image {
    top: 570px;
  }

  .right_arrow_image {
    top: 570px;
  }
}

@media screen and (max-width: 820px) {
  .left_arrow_image {
    top: 500px;
  }

  .right_arrow_image {
    top: 500px;
  }
}

@media screen and (max-width: 769px) {
  .posting_view {
    /* max-height: 420px; */
    overflow: scroll;
    /* margin-block-start: 270px; */
  }

  #comment_margin_end {
    margin-block-end: 70px;
  }

  .padding_post {
    margin: 0px !important;
    padding: 5px 10px;
  }

  .back_view_post {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
  }

  .margin_arrow_icon {
    margin: 0px !important;
  }

  .left_arrow_image {
    position: absolute;
    top: 50%;
    left: 30px;
  }

  .right_arrow_image {
    position: absolute;
    top: 50%;
    right: 30px;
  }
}

@media screen and (max-width: 482px) {

  /* .padding_post {
    padding: 0px !important;
  } */
  .padding_post {
    margin: 0px !important;
    background-color: #fff;
    border-radius: 10px;
    padding: 0px !important;
    border: none !important;
  }

  .image_sizes {
    /* margin: auto; */
    max-width: 100%;
    /* height: 100%; */
    max-height: 100%;
  }

  /* .left_arrow_image {
    top: 320px;
    left: 10px;
  }
  .right_arrow_image {
    top: 320px;
    right: 10px;
  } */
}