@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap");

@font-face {
  font-family: "helvetica-neue-light";
  src: url("../../fonts/Helvetica\ Neue\ UltraLight.ttf") format("truetype");

  font-family: "helvetica neue";
  src: url("../../fonts/Helvetica\ Neue\ Medium\ Extended.otf") format("opentype");

  font-family: "helvetica-neue";
  src: url("../../fonts/Helvetica\ Neue\ LTW0697BlkCnObl.otf") format("opentype");
}

/* body{
  font-family: 'Raleway', sans-serif !important;
} */
/* *{
  box-sizing: border-box;
} */
/* span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator{
  background-color: white !important;
  height: 3px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: white !important;
} */
/* .css-1oqqzyl-MuiContainer-root{
  width: 90%;
} */
.section1-parent-container {
  width: 90%;
  margin: 0 auto;

}

.more_card_text {
  font-size: 16px;
  font-weight: 400;
  color: #009bfa;
}

.search-component #demo-positioned-button {
  width: 100%;
}

.tab-app {
  box-shadow: 0px 1px 6px #00000029 !important;
  background-color: #fbfbfb !important;
}

#header_width {
  max-width: 1280px;
  margin: auto;
}

#header_width1 {
  max-width: 1280px;
  /* margin: auto; */
}

/* .search-component {
  margin-left: 20px;
} */

.sign-bg {
  background-color: white;
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap; */
}

.input_phone {
  font-size: 16px !important;
  background-color: white !important;
  border-radius: 8px !important;
  width: 100% !important;
  outline: none !important;
  padding: 18.5px 14px 18.5px 58px !important;
  height: 40px !important;
}

.custom_reg {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif !important;
}

.header-logo {
  height: 30px;
  width: 35px;
  align-items: center;
}

.header-logo-text {
  font-weight: 600 !important;
  font-size: 25px;
}

.logo-sub {
  font-style: italic;
  font-size: 10px;
  margin: 10px 0 0 5px;
  color: #004d7e;
}

.nav-header {
  flex-grow: 1;
  font-weight: 550;
  padding-left: 4%;
}

.nav-header>span {
  padding-right: 5%;
  font-weight: normal;
  font-size: 1.1rem;
}

.sign-color {
  color: #3e7ea5 !important;
}

.header-sign {
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}

.header-signin {
  background-color: #47aee1 !important;
  color: white !important;
  border-radius: 30px !important;
  width: 150px !important;
  height: 40px !important;
  margin-left: 2rem !important;
  font-family: "Raleway", sans-serif !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
}

/* #demo-positioned-button{ */
/* width: 90%; */
/* margin-right: 1rem;
} */

.global {
  font-size: 3.5rem !important;
  font-weight: bold;
}

.sign-left {
  margin-top: 17%;
}

.reg-left {
  height: inherit;
}

.bottom-text {
  font-size: 34px;
  font-weight: medium;
  margin: 1.5rem 0;
}

.b-color {
  color: #007dcd;
}

.ecosystem {
  width: 80%;
  margin: auto;
  color: #025c93;
  font-size: 2rem;
  letter-spacing: 1.2px;
  text-align: center;
}

.bgImage {
  /* background: transparent url("../../images/Group-homepage.svg") 0% 5% no-repeat
    padding-box; */
  /* background-image: url("../../images/Group-homepage.svg"); */
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: cover;
  background-origin: padding-box;
  height: 90vh !important;
}

.signup-left-wrapper {
  background-image: url("../../images/backgoundhome.svg");
  background-repeat: no-repeat;
  /* background-position: 8rem 0rem; */
  background-size: cover;
  /* height: 90vh; */
  margin: 1rem 0 1rem 1rem;
  width: 60%;
}

.signup-left {
  text-align: left;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  height: auto;
}

.input_field-wrapper {
  background-image: url("../../images/section1-2.png");
  background-repeat: no-repeat;
  background-size: 25% 22%;
  background-position: top right;
  width: 37%;
  margin: 0 auto;
  height: 80%;
}

.input_field {
  width: 70%;
  height: 100%;
  padding: 1.5rem;
  margin: 0 auto 1.5rem auto !important;
  text-align: left;
  box-shadow: rgb(204 204 204) 0px 2px 5px;
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
}

.main-text-container {
  padding: 0 !important;
}

.register-H1 {
  font-size: 2.2rem !important;
}

.first-child-grid {
  width: 60%;
}

.create_margin {
  width: 30% !important;
}

.bg-header {
  background: transparent url("../../images/header.svg") 0% 0% no-repeat padding-box;
}

#z_index {
  z-index: 1;
}

.map {
  width: 100%;
  margin: 20px 0px;
}

.reg {
  color: #3e7ea5;
  font-size: 30px !important;
}

.platform {
  color: #027dcd;
  font-size: 25px !important;
}

.r-check {
  padding: 0 6px 0 16px !important;
}

.r-check.Mui-checked,
.r-check {
  color: #0d42ba !important;
}

.btn-signup {
  border: 1px solid #3e7ea5 !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  margin: auto !important;
  margin-top: 1.5em !important;
  border-radius: 0 !important;
  box-shadow: 0px 2px 6px lightgrey inset, 0px 5px 6px lightgrey;
}

.disable-btn-signup {
  padding-left: 3em !important;
  padding-right: 3em !important;
  margin: auto !important;
  margin-top: 1.5em !important;
}

.cursor-notallowed {
  cursor: not-allowed !important;
}

.content-clr {
  color: #013353 !important;
}

.t2_text {
  margin: auto !important;
  width: 65% !important;
  font-size: 20px !important;
  color: #013353 !important;
  /* padding-top: 10px; */
}

.image-item {
  padding: 10px 20px;
  margin: auto;
  align-items: center !important;
  height: 225px;
  width: 233px;
  text-align: center;
}

.image-item>img {
  border-radius: 28px;
}

.logo-height {
  height: 44px;
  width: 44px;
}

.f-logo {
  display: flex;
  align-items: center;
}

/* .footer-logo-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  margin: 1rem auto 0 auto  !important;
} */
.contact-info {
  position: relative;
}

.footer-logo {
  visibility: hidden;
}

.footer-logo2 {
  position: absolute;
  top: -5rem;
  padding-left: 5px;
}

.f-color {
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.f-logo>span {
  flex-grow: 1;
  font-weight: 600;
  font-size: 2rem;
  margin-top: 5px;
}

.copy-right {
  background-color: #87898a;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem !important;
}

.img-position {
  position: absolute;
  top: 40%;
  left: 47%;
}

.Title_card {
  font-size: 18px;
  font-weight: 400;
  white-space: break-spaces;
}

.t1_text {
  margin: auto !important;
  width: 60%;
  color: #025c93;
}

.disclaimer_text {
  margin-top: 1.2em;
}

.accept_text {
  align-items: flex-start;
  margin-top: 8px;
}

.footer_docsteth {
  padding: 24px 0;
}

.footer-alignment-container {
  display: flex;
  align-items: flex-start;
}

.footer-logo-bottom-text {
  font-size: 0.8rem;
  color: white;
  font-style: italic;
  font-family: "helvetica-neue-light", sans-serif;
}

/* .footer-part-sections{
  margin-left: 0.5rem;
} */
.footer-part-sections li {
  list-style-type: none;
  font-size: 1.3rem !important;
  font-family: "Raleway", sans-serif !important;
}

.contact-info span {
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif !important;
}

.SignupLargeDevices {
  display: block;
}

.SignupsmallDevices {
  display: none;
}

.Autocomplete_input {
  margin-bottom: 24px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.privacy_footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 15px;
}

.privacy_footer h2 {
  font-size: 18px;
  color: white;
  font-weight: 500;
}

.download_app {
  text-transform: capitalize !important;
  margin: 2.5rem 10px 3rem 0 !important;
  height: 50px !important;
  background-color: #47aee1 !important;
  color: white !important;
  border-radius: 30px !important;
  width: 200px !important;
  font-family: "Raleway", sans-serif !important;
  font-size: 1.2rem !important;
}

.download_app img {
  margin-right: 0.5rem;
}

.down_app_text {
  padding: 20px 15px;
  width: 400px;
}

.down_app_text h5 {
  text-align: center;
  padding: 5px;
  color: #3e7ea5;
}

.down_app_text h3 {
  text-align: center;
  padding: 5px;
  color: #3e7ea5;
  font-size: 18px;
  font-weight: 600;
}

.now_avalaible {
  text-align: center !important;
  padding: 5px;
  color: #3e7ea5 !important;
  font-size: 18px;
  font-weight: 400;
}

.down_app_text p {
  text-align: center;
  padding: 5px;
  color: #3e7ea5;
}

/* second section styling starts */
.second-section {
  background-image: url("../../images/services_background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-origin: padding-box;
  /* height: 100vh; */
  width: 100%;
}

.second-section h3 {
  font-size: 44px;
  color: #262222;
  font-weight: 600;
  margin-bottom: 1rem !important;
  font-family: "Raleway", sans-serif !important;
}

.second-section h5 {
  font-size: 36px !important;
  color: #025c93 !important;
  font-weight: 400;
  font-family: "Raleway", sans-serif !important;
  line-height: inherit;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 90%;
  height: auto;
  margin: 2rem auto 4.5rem auto;
}

.card {
  width: 17%;
  min-height: 300px;
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 4px 4px 8px #3dc296;
}

.carousel-component1 {
  display: none;
}

.card h5 {
  color: #262222;
  font-size: 29px !important;
  margin: 1rem auto !important;
}

.image1 {
  width: 70%;
  height: 80%;
}

.card-image {
  position: relative;
}

.image2 {
  width: 40%;
  position: absolute;
  top: 20%;
  left: 32%;
}

.card-desc {
  font-size: 18px;
  width: 100%;
  height: 20%;
  margin: 1rem 0;
  text-align: inherit;
  line-height: 20px;
}

/* 2nd section  ends */

/* 3rd section  starts */
.explore-topics {
  box-sizing: border-box;
  display: flex;
  /* height: 90vh !important; */
  width: 100%;
  /* padding: 4rem; */
  padding: 4rem 0rem;
  justify-content: space-evenly;
}

.explore-container {
  width: 57%;
  /* width: 60%; */
  /* padding: 0 2rem; */
}

.explore-container h2 {
  font-size: 3rem;
}

.explore-container p {
  width: 75%;
  margin: 2.5rem 0px;
  font-size: 1.8rem;
}

.explore-topics-search-wrapper {
  margin: 1rem 0 !important;
  width: 80% !important;
}

#explore_home {
  margin: 70px 0px;
}

.explore-items-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}

.explore-items {
  background-color: #dedede;
  min-width: 4rem;
  height: auto;
  padding: 10px;
  margin: 5px;
  border-radius: 1.5rem;
}

.explore-items:first-of-type {
  background-color: white;
  border: 2px solid #dedede;
}

.iphone {
  width: 20%;
  /* padding: 0 2rem; */
}

/* 3rd section  ends */

/* 4th section starts */
.e-practice-card-wrapper {
  background-image: url("../../images/section4-background-all3-combined.png");
  /* background-image: url('../../images/fourth-section-background.png'), url('../../images/fourth-section-background2.png'); */
  background-repeat: no-repeat;
  background-size: 100%, 100%;
  background-position: top;
  width: 100%;
  /* height: 1648px; */
  /* height: 94rem; */
}

.e-practice-header-wrapper {
  margin: 1rem auto;
  width: 50%;
  text-align: center;
}

.e-practice-header-wrapper h2 {
  color: white;
  font-size: 3rem;
  margin-top: 3rem;
}

.e-practice-header-wrapper p {
  font-size: 21px;
  margin: 1rem 0;
  line-height: 1.5;
  text-align: inherit;
}

.e-practice-header-tab button {
  margin: 0 1.5rem;
  font-size: 1.6rem;
  text-transform: capitalize;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
}

/* .e-practice-header-tab button:selected{
  color: white;
} */
/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: white !important;
} */

/* span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  background-color: white;
} */
.e-practice-card-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 2rem auto;
  justify-content: space-evenly;
}

.epractice-parent {
  position: relative;
  box-shadow: 0 2px 20px #ccc;
  width: 22rem;
  height: 15rem;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 3rem 1.5rem;
  text-align: center;
}

.epractice-child {
  position: absolute;
  font-family: "Raleway", sans-serif;
  width: 20rem;
  height: 16rem;
  bottom: 3rem;
  background-color: white;
  border-radius: 4rem;
  box-shadow: 0 2px 5px rgb(204 204 204);
}

.epractice-child>img {
  width: 2.2rem;
  padding: 1rem 0 0.5rem 0;
}

.epractice-child>h5 {
  font-size: 1.4rem;
  color: black;
}

.epractice-child>p {
  font-size: 1.1rem;
  padding: 0.5rem;
}

.try-now {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: absolute;
  bottom: 0.4rem;
  right: 2.5rem;
}

.try-now>p {
  font-size: 1.1rem;
  margin-right: 0.5rem;
}

.try-now>img {
  height: 1.5rem;
}

.learn-more {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0.3rem;
  left: 0.5rem;
}

.learn-more>p {
  font-size: 1.1rem;
  margin-right: 0.5rem;
  font-family: "Raleway", sans-serif;
}

.learn-more>img {
  height: 1rem;
}

.e-practice-bottom-button {
  background-color: #47aee1 !important;
  width: 10rem;
  height: 4rem;
  font-size: 1.25rem !important;
  border-radius: 2rem !important;
  text-transform: capitalize !important;
  font-family: "Raleway", sans-serif !important;
}

.e-practice-carousel-wrapper {
  display: none;
}

.one_profile {
  font-size: 40px;
  font-weight: bold;
  margin: 1rem auto;
}

/* 4th section Ends */

/* 5th section starts */
.section5-expertise {
  width: 100% !important;
  height: 80vh !important;
  /* height: calc(100vh - 70px) !important; */
  background-image: url(../../icons/oneprofile.svg);
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  margin-bottom: 3rem;
}

.Interoperable_sentence {
  font-size: 22px;
  font-weight: 500;
  margin: 0px auto 3rem auto;
}

.expertise-section {
  width: 80%;
  margin: 1rem auto;
}

.section5-inputs {
  display: flex;
}

.expertise-sectionwise-headers {
  font-size: 3.5rem;
}

.expertise-sectionwise-desc {
  font-size: 2rem;
  margin: 1.75rem 0;
}

.section5-inputs div {
  margin: 1rem 1.75rem 2rem 0;
}

.section5-inputs div>p {
  font-size: 1.5rem;
}

.like-comment-share img {
  height: 2rem;
  margin: 0 0 1.75rem 1.75rem;
}

.share {
  text-align: right;
}

.report {
  display: flex;
  margin-bottom: 3rem;
}

.report>img {
  height: 2rem;
}

.report p {
  color: #237dcd;
  font-size: 2rem;
  margin-left: 1.75rem;
}

/* 5th section ends */

/* 6th section starts */
.section6-medscope {
  position: relative;
  background-image: url("../../images/section6-top-grey.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  /* height: 120vh; */
}

.common-style-for-innersections {
  position: absolute;
  width: inherit;
  height: inherit;
  background-repeat: no-repeat;
}

.innersection1-medscope {
  background-image: url("../../images/single-square.png");
  background-size: 40% 60%;
  background-position: bottom left;
}

.innersection2-medscope {
  background-image: url("../../images/single-circle.png");
  background-size: 35% 55%;
  background-position: bottom right;
}

.medscope-header {
  margin: 2rem 0;
  text-align: center;
  color: white;
}

.medscope-header h2 {
  font-size: 3rem;
}

.medscope-header p {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.medscope-header-tab button {
  color: white;
  margin: 0 1.5rem;
  font-size: 1.6rem;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
}

.webinar-card-container {
  font-family: "Raleway", sans-serif;
  width: 90%;
  height: 80vh;
  margin: 0 auto;
  background-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 0.2),
      rgba(128, 128, 128, 0.2));
  overflow: scroll;
  position: relative;
  z-index: 2;
}

.articles {
  display: flex;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.article-pic {
  width: 11%;
  background-color: white;
  margin-right: 1rem;
}

.article-desc {
  width: 89%;
}

.article-date-time {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* 6th section ends */

.social-icons svg {
  color: white;
  margin: 1rem 2rem 1rem 0;
}

.webinar-bottom-button {
  text-transform: capitalize !important;
  background-color: #47e1ae !important;
  margin-top: 1.5rem !important;
  /* padding: 0.5rem 1.2rem !important;
  border-radius: 1rem !important; */
  width: 10rem;
  height: 4rem;
  border-radius: 2rem !important;
  font-size: 1.25rem !important;
  font-family: "Raleway", sans-serif !important;
}

@media (max-width: 1561px) {
  .first-child-grid {
    width: 60%;
    height: 75%;
  }

  .explore-topics {
    height: 100% !important;
  }

  .explore-container h2 {
    font-size: 2.7rem;
  }

  .explore-container p {
    font-size: 1.7rem;
  }

  .explore-topics-search-wrapper {
    margin: 1rem 0 !important;
    width: 80% !important;
  }

  .explore-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
  }

  .explore-items {
    margin: 7px;
  }

  .iphone {
    width: 22%;
    height: 100%;
  }

  /* 5th section starts */
  .section5-expertise {
    width: 100%;
    /* height: 95vh; */
    background-size: contain;
  }

  .expertise-sectionwise-headers {
    font-size: 2.5rem;
  }

  .expertise-sectionwise-desc {
    font-size: 1.5rem;
    margin: 1.25rem 0;
  }

  .section5-inputs div {
    margin: 1rem 1.25rem 5rem 0;
  }

  .section5-inputs div>p {
    font-size: 1.2rem;
  }

  .like-comment-share img {
    height: 1.5rem;
    margin: 0 0 5rem 1.25rem;
  }

  .report>img {
    height: 1.5rem;
  }

  .report p {
    font-size: 1.5rem;
    margin-left: 1.25rem;
  }

  /* 5th section ends */
}

@media (max-width: 1441px) {
  .explore-topics {
    height: 100% !important;
  }

  /* .footer-logo-wrapper {
    width: 52%;
  } */
  /* .bgImage {
    height: 75vh !important;
  } */
  /* .input_field {
    margin: 1.5rem !important;
  } */

  /* .second-section {
    height: 85vh;
  } */
  .iphone {
    width: 22%;
  }

  /* 5th section starts */
  .section5-expertise {
    width: 100%;
    /* height: 90vh; */

    background-size: contain;
  }

  .expertise-sectionwise-headers {
    font-size: 2.5rem;
  }

  .expertise-sectionwise-desc {
    font-size: 1.5rem;
    margin: 1.25rem 0;
  }

  .section5-inputs div {
    margin: 1rem 1.25rem 5rem 0;
  }

  .section5-inputs div>p {
    font-size: 1.2rem;
  }

  .like-comment-share img {
    height: 1.5rem;
    margin: 0 0 5rem 1.25rem;
  }

  .report>img {
    height: 1.5rem;
  }

  .report p {
    font-size: 1.5rem;
    margin-left: 1.25rem;
  }

  /* 5th section ends */
}

@media screen and (max-width: 1250px) {
  .epractice-parent {
    width: 18rem;
    height: 15rem;
    margin: 3rem 1.5rem;
  }
}

@media (max-width: 1025px) {
  .down_app_text {
    padding: 15px;
    width: 320px;
  }

  /* .footer-logo-wrapper {
    width: 67%;
  } */
  .download_app {
    margin: 0 !important;
  }

  .bgImage {
    /* background-size: 100% 71%; */
    background-size: cover;
    background-position: top;
    height: 100vh !important;
  }

  .signup-left-wrapper {
    /* background-image: url('../../images/section1-1.png');
    background-repeat: no-repeat; */
    background-position: left;
    /* background-size: cover; */
    /* height: 90vh; */
    /* margin: 1rem ;
    width: 60%; */
  }

  .signup-left div:first-child {
    width: 50%;
  }

  /* .signup-left {
    text-align: left;
    width: 80%;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    height: auto;
  } */
  .input_field-wrapper {
    /* background-image: url('../../images/section1-2.png');
    background-repeat: no-repeat; */
    background-size: 30% 20%;
    /* background-position: top right;
    width: 37%;
    height: 80%;
    border: 1px solid blue; */
  }

  .register-H1 {
    font-size: 1.8rem !important;
  }

  .register-P {
    font-size: 1.1rem !important;
    margin-top: 0.5rem !important;
  }

  .input_field {
    /* width: 70%;
    height: 100%; */
    padding: 1rem;
    margin: 0.4rem auto !important;
    /* text-align: left;
    box-shadow: rgb(204 204 204) 0px 2px 5px;
    background-color: #fff; */
  }

  .create_margin {
    padding: 20px 0 !important;
    margin: -5rem 0 0 0 !important;
  }

  .disclaimer-text {
    font-size: 0.5rem !important;
  }

  .global,
  .bottom-text {
    font-size: 2rem !important;
  }

  .ecosystem {
    font-size: 1.5rem !important;
    padding: 1.5rem 0;
  }

  .first-child-grid {
    padding: 2rem !important;
  }

  /* second section  starts */
  /* .second-section {
    height: 100vh;
  } */
  .second-section h3 {
    font-size: 35px;
    margin-bottom: 1rem !important;
  }

  .second-section h5 {
    font-size: 33px !important;
  }

  .card h5 {
    font-size: 22px !important;
    margin: 1rem 0;
  }

  .card-desc {
    font-size: 15px;
    height: auto;
    margin: 1rem 0;
  }

  /* second section  ends */

  /* 3rd section starts */

  .explore-topics {
    height: 100% !important;
  }

  .explore-container h2 {
    font-size: 1.8rem;
  }

  .explore-container p {
    font-size: 1.3rem;
  }

  .explore-items {
    margin: 5px;
  }

  .iphone {
    width: 30%;
  }

  /* 3rd section ends */

  /* 4th section starts */
  /* .e-practice-card-wrapper {
    height: 80rem;
  } */

  .e-practice-header-wrapper h2 {
    font-size: 2rem;
  }

  .e-practice-header-wrapper p {
    font-size: 1.3rem;
    line-height: 1.3;
  }

  .e-practice-header-tab button {
    margin: 0 1rem;
    font-size: 1rem;
  }

  .e-practice-header-tab button:focus {
    color: white !important;
  }

  .e-practice-card-container {
    margin: 1rem 0;

    justify-content: space-between;
  }

  .epractice-parent {
    position: relative;
    width: 16rem;
    height: 13rem;
    margin: 2.5rem 0.8rem;
  }

  .epractice-child {
    position: absolute;

    width: 15rem;
    height: 14rem;
    bottom: 2.5rem;
    border-radius: 3rem;
  }

  .epractice-child>img {
    width: 2rem;
    padding: 0.8rem 0 0.4rem 0;
  }

  .epractice-child>h5 {
    font-size: 1.2rem;
  }

  .epractice-child>p {
    font-size: 0.9rem;
    padding: 0.4rem;
  }

  .try-now {
    bottom: 0.4rem;
    right: 2rem;
  }

  .try-now>p {
    font-size: 0.9rem;
    margin-right: 0.5rem;
  }

  .try-now>img {
    height: 1.2rem;
  }

  .learn-more>p {
    font-size: 0.9rem;
    margin-right: 0.4rem;
  }

  .learn-more>img {
    height: 0.8rem;
  }

  /* 4th section Ends */

  /* 5th section starts */
  .section5-expertise {
    height: 85vh !important;
    margin-bottom: 2rem;
  }

  .expertise-sectionwise-headers {
    font-size: 2.2rem;
  }

  .expertise-sectionwise-desc {
    font-size: 1.2rem;
  }

  .section5-inputs div>p {
    font-size: 1rem;
  }

  .like-comment-share img {
    height: 1.2rem;
  }

  .report>img {
    height: 1.2rem;
  }

  .report p {
    font-size: 1.2rem;
    margin-left: 1.25rem;
  }

  /* 5th section ends */

  /* 6th section starts */

  /* .section6-medscope {
    height: 105vh;
  } */

  .medscope-header {
    margin: 1rem 0;
  }

  .medscope-header h2 {
    font-size: 2.5rem;
  }

  .medscope-header p {
    font-size: 1.3rem;
    margin-top: 0.8rem;
  }

  .medscope-header-tab button {
    /* color: white; */
    margin: 0 0.8rem;
    font-size: 1.2rem;
  }

  .webinar-card-container {
    width: 95%;
    height: 60vh;
  }

  .article-pic {
    width: 14%;
  }

  .article-desc {
    width: 86%;
    font-size: 0.8rem;
  }

  .article-date-time {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .Interoperable_sentence {
    margin: 0px auto 2rem auto;
    font-size: 20px;
  }

  /* 6th section ends */
}

@media (max-width: 900px) {
  .footer-part-sections {
    margin: 1rem 0 !important;
  }

  .ecosystem {
    display: none;
  }

  .e-practice-card-container {
    justify-content: space-evenly;
  }

  /* .footer-logo-wrapper {
    justify-content: flex-start;
    width: 45%;
    margin: 1.5rem 0 0 1.5rem !important;
  } */
  .footer-logo {
    visibility: visible;
    padding-top: 2rem;
    padding-left: 3rem;
  }

  .footer-logo2 {
    /* position: absolute;
    top: -5rem; */
    display: none;
  }

  .privacy_footer {
    width: 100%;
    height: 60px;
    /* margin: 0 auto; */
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }

  .privacy_footer a {
    margin: 0 2rem;
  }

  .social-icons {
    display: flex;
    justify-content: space-evenly;
  }

  .social-icons svg {
    font-size: 2rem;
    margin: 1rem 2rem;
  }
}

@media screen and (max-width: 821px) {
  .bottom-text {
    margin-bottom: 0px !important;
  }

  .global {
    font-size: 2.8rem !important;
  }

  .create_margin {
    padding: 0 !important;
    margin-top: 1rem !important;
    box-shadow: none;
  }

  /* .footer-logo-wrapper {
    justify-content: flex-start;
    margin: 1rem 0 0 2rem !important;
  } */
}

@media screen and (max-width: 769px) {
  #explore_home {
    margin: 40px 0px;
  }

  .section5-expertise {
    height: 75vh !important;
    margin-bottom: 1rem;
    /* margin-top: -30px; */
  }

  .Interoperable_sentence {
    margin: 0px auto 1rem auto;
    font-size: 15px;
  }

  .one_profile {
    font-size: 35px;
  }

  .logo-height {
    height: 44px;
    width: 44px;
  }

  .global {
    font-size: 2.4rem !important;
  }

  .section1-parent-container {
    flex-direction: column;
  }

  .signup-left-wrapper {
    /* background-image: url('../../images/section1-1.png');
    background-repeat: no-repeat; */
    background-position: 4rem 0rem;
    /* background-size: cover; */
    /* height: 90vh; */
    /* margin: 1rem ; */
    width: 95%;
  }

  /* .signup-left div:first-child{
    width: 50%;
  } */
  .signup-left {
    width: 90%;
  }

  .input_field-wrapper {
    /* background-image: url('../../images/section1-2.png');
    background-repeat: no-repeat; */
    background-size: 18% 25%;
    /* background-position: top right; */
    width: 95%;
    height: 80%;
    /* border: 1px solid blue; */
  }

  /* .register-H1{
    font-size: 1.8rem !important;
  }
  .register-P{
    font-size: 1.1rem !important;
    margin-top: 0.5rem !important;
  } */
  .input_field {
    /* width: 70%;
    height: 100%; */
    padding: 1rem;
    margin: 0 auto !important;
    /* text-align: left; */
    box-shadow: none;
    background-color: transparent;
  }

  /* ------------------------------ */
  .sign-left {
    width: 45%;
    margin-bottom: 2rem;
  }

  .footer-part-sections {
    margin: 1rem 0 !important;
  }

  .image-item {
    height: 200px;
    width: 217px;
    padding: 5px 10px;
  }

  .Title_card {
    font-size: 10px;
  }

  .bottom-text {
    margin-top: 1rem;
    font-size: 18px;
  }

  .t1_text {
    margin: auto !important;
    width: 60%;
    color: #025c93;
    font-size: 40px !important;
  }

  .t2_text {
    margin: auto !important;
    width: 60% !important;
    font-size: 20px !important;
  }

  .footer_docsteth {
    padding: 12px;
    padding-bottom: 12px;
  }

  .footer_header {
    margin: 0px !important;
  }

  .bgImage {
    margin: 0;
    /* background-size: 100% 71%;
    background-position: top; */
    height: 100vh !important;
    flex-direction: column;
    background-position: inherit;
  }

  /* .signup-left-wrapper {
    margin: 5rem auto;
    width: 80%;
  } */
  /* .input_field {
    box-sizing: border-box;
    width: 80%;
    height: 80%;
    padding: 1rem;
    margin: 0 auto !important;
    box-shadow: none;
    text-align: left;
    background-color: #fff;
  } */
  .register-H1 {
    font-size: 1.8rem !important;
  }

  .register-P {
    font-size: 1.2rem !important;
  }

  .grid-container {
    flex-direction: column !important;
  }

  .first-child-grid {
    margin: 40rem 0 0rem 0 !important;
    color: #008cd1 !important;
    width: 80%;
    height: 50vh;
  }

  .download_app {
    margin: 2rem auto !important;
  }

  .disclaimer_text {
    margin-top: 1rem;
    font-size: 1rem !important;
    width: 80%;
  }

  .tnc {
    display: inline-block;
    width: 100%;
  }

  .ecosystem {
    display: none !important;
  }

  .search-component,
  .site-map {
    display: none !important;
  }

  .create_margin {
    box-shadow: none !important;
    padding: 2rem 0 !important;
    width: 100% !important;
  }

  .create_margin p {
    font-size: 1.8rem !important;
  }

  .Autocomplete_input {
    background-color: white;
  }

  .phone__number {
    background-color: white;
  }

  .privacy_footer h2 {
    font-size: 14px;
    color: white;
  }

  /* 2nd section  starts */
  .card-container {
    display: none;
    width: 50%;
  }

  .carousel-component1 {
    display: block;
    margin-top: 2rem !important;
  }

  /* .second-section {
    height: 108vh;
  } */
  .second-section h3 {
    font-size: 1.65rem !important;
    margin-bottom: 0.8rem !important;
  }

  .second-section h5 {
    font-size: 1.4rem !important;
  }

  /* .card-container{
    width: 90%;
    justify-content: space-between;
  } */
  .card {
    min-height: 250px;
    width: 18%;
  }

  /* .card h5{
    font-size: 20px !important;
    margin: 0.8rem 0;
  }
  .card-desc{
    font-size: 13px;
    height: auto;
    margin: 0.8rem 0;
  } */
  .image1 {
    width: 60%;
    height: 70%;
  }

  .image2 {
    width: 35%;
    top: 25%;
    left: 32%;
  }

  .card h5 {
    font-size: 19px !important;
    margin: 0.7rem 0;
  }

  .card-desc {
    font-size: 1rem;
    height: auto;
    width: 80%;
    margin: 0.7rem auto;
  }

  .carousel-card {
    width: 80%;
    margin: 0 auto;
  }

  /* 2nd section  ends */

  /* 3rd section starts */

  .explore-topics {
    height: 100% !important;
    padding: 1.8rem;
  }

  .explore-container h2 {
    font-size: 1.5rem;
  }

  .explore-container p {
    font-size: 1.3rem;
  }

  .explore-items-wrapper {
    width: 100%;
  }

  .explore-items {
    min-width: 3rem;
    height: auto;
    padding: 8px;
    margin: 3px;
  }

  .iphone {
    width: 30%;
  }

  /* 3rd section ends */

  /* 4th section starts */
  .e-practice-card-container-wrapper {
    display: none;
  }

  .e-practice-carousel-wrapper {
    display: block;
  }

  .e-practice-card-wrapper {
    background-image: url("../../images/fourth-section-background.png"),
      url("../../images/fourth-section-background2.png") !important;

    /* height: 48rem; */
  }

  .e-practice-header-wrapper {
    width: 90%;
  }

  .e-practice-header-wrapper h2 {
    margin-top: 2rem;
  }

  .e-practice-card-container {
    margin: 1rem 0 0 0;
  }

  .epractice-parent {
    width: 94%;
    height: 15rem;
    margin-top: 4rem;
    background-color: rgba(37, 85, 61, 0.1);
    box-shadow: none;
  }

  .epractice-child {
    width: 95%;
    height: 16rem;
  }

  .epractice-child>img {
    width: 2.5rem;
    padding: 1.2rem 0 0.8rem 0;
  }

  .epractice-child>h5 {
    font-size: 1.8rem;
  }

  .epractice-child>p {
    font-size: 0.9rem;
    padding: 0.9rem;
  }

  .try-now {
    right: 2rem;
  }

  .try-now>p {
    font-size: 1rem;
    margin-right: 0.3rem;
  }

  .try-now>img {
    height: 1.1rem;
  }

  .learn-more>p {
    font-size: 0.9rem;
    margin-right: 0.5rem;
  }

  .learn-more>img {
    height: 0.85rem;
  }

  /* 4th section ends */

  /* 5th section starts */
  /* .section5-expertise {
    height: 100vh;
  } */

  .expertise-sectionwise-headers {
    font-size: 2.1rem;
  }

  .expertise-sectionwise-desc {
    font-size: 1.1rem;
  }

  .section5-inputs div {
    margin: 1rem 1rem 5rem 0;
  }

  .section5-inputs div>p {
    font-size: 0.9rem;
  }

  .like-comment-share img {
    height: 1.2rem;
  }

  .report>img {
    height: 1.1rem;
  }

  .report p {
    font-size: 1.1rem;
    margin-left: 1.25rem;
  }

  /* 5th section ends */

  /* 6th section starts */

  .section6-medscope {
    background-size: 120% 30%;
    background-position: top;
    /* height: 100vh; */
  }

  .medscope-header {
    margin: 0.8rem 0 0 0;
  }

  .medscope-header h2 {
    font-size: 2rem;
  }

  .medscope-header p {
    font-size: 1.1rem;
    margin-top: 0.7rem;
  }

  .medscope-header-tab button {
    /* color: white; */
    margin: 0 0.2rem;
    font-size: 1rem;
  }

  .webinar-card-container {
    width: 100%;
    height: 65vh;
  }

  .article-pic {
    width: 15%;
  }

  .article-desc {
    width: 85%;
    font-size: 0.7rem;
  }

  .webinar-bottom-button {
    /* text-transform: capitalize !important;
    background-color: #47E1AE !important;
    margin-top: 1.5rem !important; */
    /* padding: 0.5rem 1.2rem !important;
    border-radius: 1rem !important; */
    width: 8rem;
    height: 3rem;
    border-radius: 1.2rem !important;
    font-size: 1.1rem !important;
  }

  /* 6th section ends */
  .footer-logo {
    /* visibility: visible; */
    /* padding-top: 2rem; */
    padding-left: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .global {
    font-size: 1.8rem !important;
  }

  /* .input_field {
    width: 90%;
  } */

  .signup-left-wrapper {
    /* background-image: url('../../images/section1-1.png');
    background-repeat: no-repeat; */
    background-position: left;
    /* background-size: cover; */
    /* height: 90vh; */
    /* margin: 1rem ; */
    /* width: 95%; */
  }

  .bottom-text {
    font-size: 1.5rem !important;
  }

  /* .signup-left div:first-child{
    width: 50%;
  } */
  /* .signup-left {
    margin-bottom: 0;
    background-size: cover;
} */
  .input_field-wrapper {
    /* background-image: url('../../images/section1-2.png');
    background-repeat: no-repeat; */
    background-size: 18% 22%;
    /* background-position: top right; */
    /* width: 95%;
    height: 80%; */
    /* border: 1px solid blue; */
  }

  /* .register-H1{
    font-size: 1.8rem !important;
  }
  .register-P{
    font-size: 1.1rem !important;
    margin-top: 0.5rem !important;
  } */
  /* .input_field { */

  /* width: 70%;
    height: 100%; */
  /* padding: 1rem;
    margin: 0 auto !important; */
  /* text-align: left; */
  /* box-shadow: none;
    background-color: transparent; */
  /* } */

  /* 2nd section  starts */

  .second-section h3 {
    font-size: 23px !important;
    margin-bottom: 0.7rem !important;
    width: 90%;
  }

  .second-section h5 {
    font-size: 21px !important;
    width: 90% !important;
  }

  /* .card-container{
    display: none;
    width: 50%;
    
  }
  .carousel-component1{
    display: block;
    margin-top: 2rem !important;
  } */
  .card {
    min-height: 100px;
    width: 100%;
  }

  /* .image1{
    width: 60%;
    height: 70%;
  }
  .image2{
    width: 35%;
    top: 25%;
    left: 32%;
  }
  .card h5{
    font-size: 19px !important;
    margin: 0.7rem 0;
  }
  .card-desc{
    font-size: 12px;
    height: auto;
    width: 80%;
    margin: 0.7rem auto;
  }
  
  .carousel-card{
    width: 80%;
    margin: 0 auto;
  } */
  /* 2nd section  ends */

  /* 3rd section starts */

  .explore-topics {
    height: 100% !important;
    padding: 2rem;
    flex-direction: column;
    justify-content: space-between;
  }

  .explore-container {
    width: 100%;
    padding: 0;
  }

  .explore-container p {
    width: 100%;
    font-size: 1rem;
    margin: 0.5rem 0px;
  }

  .explore-topics-search-wrapper {
    width: 100% !important;
  }

  .explore-items-wrapper {
    width: 100%;
  }

  .explore-items {
    min-width: 3rem;
    height: auto;
    padding: 8px;
    margin: 3px;
  }

  .iphone {
    width: 45%;
    margin: 0 auto;
  }

  /* 3rd section ends */

  /* 4th section starts */
  .e-practice-card-container-wrapper {
    display: none;
  }

  .e-practice-carousel-wrapper {
    display: block;
  }

  /* .e-practice-card-wrapper {
    height: 50rem;
  } */
  .e-practice-header-wrapper {
    width: 90%;
  }

  .e-practice-card-container {
    margin: 1rem 0 0 0;
  }

  .epractice-parent {
    width: 94%;
    height: 15rem;
    margin-top: 4rem;
  }

  .epractice-child {
    width: 95%;
    height: 16rem;
  }

  .epractice-child>img {
    width: 2.5rem;
    padding: 1.2rem 0 0.8rem 0;
  }

  .epractice-child>h5 {
    font-size: 1.8rem;
  }

  .epractice-child>p {
    font-size: 0.9rem;
    padding: 0.9rem;
  }

  .try-now {
    right: 2rem;
  }

  .try-now>p {
    font-size: 1rem;
    margin-right: 0.3rem;
  }

  .try-now>img {
    height: 1.1rem;
  }

  .learn-more>p {
    font-size: 0.9rem;
    margin-right: 0.5rem;
  }

  .learn-more>img {
    height: 0.85rem;
  }

  /* 4th section ends */

  /* 5th section starts */

  .expertise-section {
    width: 90%;
    margin: 2rem auto;
  }

  .expertise-sectionwise-headers {
    font-size: 2rem;
  }

  .expertise-sectionwise-desc {
    font-size: 1rem;
  }

  .section5-inputs div {
    margin: 1rem 0.7rem 5rem 0;
  }

  .section5-inputs div>p {
    font-size: 0.7rem;
  }

  .report p {
    font-size: 1rem;
    margin-left: 1rem;
  }

  /* 5th section ends */

  /* 6th section starts */

  .section6-medscope {
    background-size: 150% 30%;
    background-position: top;
  }

  .medscope-header {
    margin: 1rem 0 0 0;
  }

  .medscope-header p {
    font-size: 1rem;
  }

  .medscope-header-tab button {
    margin: 0 0.5rem;
    padding: 0 !important;
  }

  .article-pic {
    display: none;
  }

  .article-desc {
    width: 100%;
  }

  .article-date-time svg {
    font-size: 0.8rem;
  }

  /* 6th section ends */
  /* .footer-logo-wrapper{
    margin: 1rem 0 0 1.5rem !important
  } */
  .footer-part-sections li {
    font-size: 1.1rem !important;
  }

  .contact-info span {
    font-size: 1rem !important;
  }

  .copy-right {
    font-size: 1rem !important;
  }

  .footer-logo {
    /* visibility: visible;
    padding-top: 2rem; */
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 481px) {
  .social-icons {
    display: flex;
  }

  .Interoperable_sentence {
    margin: 0px 15px 1rem 15px;
    font-size: 18px;
  }

  .down_app_text h3,
  h4 {
    font-size: 14px;
  }

  .down_app_text h5 {
    font-size: 14px;
  }

  .download_app {
    font-size: 0.9rem !important;
    width: 160px !important;
    height: 3rem !important;
  }

  .reg {
    font-size: 24px !important;
  }

  .platform {
    font-size: 15px !important;
  }

  .bottom-text {
    margin: 2rem 0;
  }

  .btn-signup {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
  }

  .disclaimer_text {
    margin-top: 0.5em;
  }

  .t1_text {
    width: 80%;
    font-size: 25px !important;
  }

  .t2_text {
    width: 70%;
    font-size: 16px !important;
  }

  .logo-height {
    height: 33px;
    width: 33px;
  }

  .f-logo>span {
    font-size: 25px;
  }

  .bgImage {
    background-size: cover;
    background-position: 20% 10%;
    height: 100vh;
  }

  .signup-left-wrapper {
    /* background-image: url('../../images/section1-1.png');
    background-repeat: no-repeat; */
    background-position: left;
    background-size: 120% 100%;
    /* height: 90vh; */
    /* margin: 1rem ; */
    /* width: 95%; */
  }

  .bottom-text {
    font-size: 1.3rem !important;
    font-weight: 500;
  }

  .signup-left div:first-child {
    /* width: 50%; */
    font-size: 2rem !important;
  }

  /* .signup-left {
    text-align: left;
    width: 80%;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    height: auto;
  } */
  .input_field-wrapper {
    /* background-image: url('../../images/section1-2.png');
    background-repeat: no-repeat; */
    background-size: 16% 16%;
    /* background-position: top right; */
    /* width: 95%;
    height: 80%; */
    /* border: 1px solid blue; */
  }

  /* .register-H1{
    font-size: 1.8rem !important;
  }
  .register-P{
    font-size: 1.1rem !important;
    margin-top: 0.5rem !important;
  } */
  /* .input_field { */

  /* width: 70%;
    height: 100%; */
  /* padding: 1rem;
    margin: 0 auto !important; */
  /* text-align: left; */
  /* box-shadow: none;
    background-color: transparent; */
  /* } */
  /* ------------------------------------ */
  .nav-header>span {
    display: none;
  }

  .SignupLargeDevices {
    display: none;
  }

  .SignupsmallDevices {
    display: block;
  }

  .footer_docsteth {
    padding: 5px !important;
    padding-bottom: 12px;
  }

  .Autocomplete_input {
    margin-bottom: 5px;
  }

  .privacy_footer h2 {
    font-size: 14px;
    color: white;
  }

  .down_app_text {
    padding: 10px;
    width: 280px;
  }

  .dob_padding {
    padding-top: 10px;
    width: "100%";
  }

  /* 2nd section  starts */

  /* .second-section{
  width: 100%;
  } */
  .second-section h3 {
    font-size: 21px !important;
    margin-bottom: 0.5rem !important;
  }

  .second-section h5 {
    font-size: 19px !important;
  }

  /* 2nd section  ends */

  .expertise-sectionwise-headers {
    font-size: 1.7rem;
  }

  .medscope-header-tab button {
    margin: 0 0.3rem;
  }

  /* .section5-expertise{
    height: 105vh;
  } */
  .footer-logo {
    /* visibility: visible;
    padding-top: 2rem; */
    padding-left: 1.2rem;
  }
}

@media (max-width: 480px) {
  .grid-container {
    flex-direction: column !important;
  }

  #disease_header {
    display: flex;
    flex-direction: row;
  }

  .width_header {
    width: 50%;
    flex: 50%;
  }

  .bgImage {
    height: 85vh !important;
  }

  .signup-left-wrapper {
    background-size: 120% 90%;
  }

  .input_field-wrapper {
    background-size: 15% 15%;
  }

  .main-text-container {
    margin-top: 1rem;
  }

  .register-H1 {
    font-size: 1.3rem !important;
    margin-bottom: 0.5rem;
  }

  .register-P {
    font-size: 1.1rem !important;
  }

  .sign-left {
    width: 60%;
    font-size: 1rem;
  }

  .bottom-text {
    font-size: 1.1rem !important;
  }

  /* .input_field {
    width: 80%;
    margin: 0 auto !important;
    padding: 0;
    box-shadow: none;
  } */
  .card {
    width: 80%;
  }

  .first-child-grid {
    color: #008cd1 !important;
    width: 90%;
    height: 50vh;
  }

  .create_margin p {
    font-size: 1.3rem !important;
  }

  .explore-topics {
    height: 100% !important;
    width: 100% !important;
    padding: 1.5rem;
  }

  .iphone {
    width: 60%;
  }

  .second-section {
    width: 100% !important;
    /* height: 98vh; */
  }

  /* 4th section starts */

  .e-practice-card-wrapper {
    width: 100% !important;
    /* height: 40rem; */
  }

  .e-practice-header-wrapper {
    width: 90%;
  }

  .e-practice-header-wrapper h2 {
    font-size: 1.5rem;
  }

  .e-practice-header-wrapper p {
    font-size: 1rem;
    margin: 0.6rem 0;
    line-height: 1.2;
  }

  .e-practice-header-tab button {
    margin: 0 0.8rem;
    font-size: 0.8rem;
  }

  .e-practice-card-container {
    margin: 1rem 0 0 0;
  }

  .epractice-parent {
    width: 94%;
    height: 15rem;
    margin: 4rem auto 0 auto !important;
  }

  /* 4th section ends */
  .expertise-sectionwise-headers {
    font-size: 1.4rem;
  }

  .section5-expertise {
    width: 100% !important;
    margin-bottom: 1rem;
  }

  /* 6th section starts */
  .section6-medscope {
    width: 100% !important;
  }

  .medscope-header h2 {
    font-size: 1.5rem;
  }

  .medscope-header p {
    font-size: 0.9rem;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 50px !important;
  }

  .medscope-header-tab button {
    margin: 0 0.25rem;
    font-size: 0.8rem;
    /* width: 70px !important; */
  }

  .one_profile {
    font-size: 20px;
  }

  .articles {
    padding: 0.5rem;
  }

  .article-date-time svg {
    font-size: 0.8rem;
  }

  .webinar-bottom-button {
    /* text-transform: capitalize !important;
    background-color: #47E1AE !important;
    margin-top: 1.5rem !important; */
    /* padding: 0.5rem 1.2rem !important;
    border-radius: 1rem !important; */
    width: 7rem;
    height: 2.5rem;
    border-radius: 1rem !important;
    font-size: 1rem !important;
  }

  /* 6th section ends */
  .footer_header {
    width: 100% !important;
  }

  .section5-expertise {
    height: 55vh !important;
    /* margin-bottom: -30px; */
  }

  .explore-container h2 {
    font-size: 1.2rem;
  }

  #explore_home {
    margin: 20px 0px;
  }
}

@media screen and (max-width: 376px) {
  .download_text {
    font-size: 12px !important;
    padding-left: 5px;
  }

  .bgImage {
    height: 75vh !important;
  }

  .signup-left-wrapper {
    background-size: 120% 85%;
  }

  .input_field-wrapper {
    background-size: 14% 12%;
  }

  .register-H1 {
    font-size: 1.1rem !important;
  }

  .register-P {
    font-size: 0.9rem !important;
  }

  .global {
    font-size: 1.3rem !important;
    /* padding-left: 10px; */
  }

  .logo-sub {
    font-size: 9px !important;
  }

  .first-child-grid {
    margin-top: 30rem !important;
  }

  .first-child-grid .bottom-text {
    font-size: 1.1rem !important;
  }

  .create_margin {
    margin-top: 1.3rem !important;
    margin-bottom: -8rem !important;
  }

  .bottom-text {
    margin-top: 1rem;
  }

  .logo-height {
    height: 33px;
    width: 33px;
  }

  .privacy_footer h2 {
    font-size: 13px;
  }

  /* 4th section starts */

  .e-practice-header-wrapper h2 {
    font-size: 1.2rem;
  }

  .e-practice-header-wrapper p {
    font-size: 0.9rem;
    margin: 0.6rem 0;
    line-height: 1.3;
  }

  .e-practice-header-tab button {
    margin: 0 0.5rem;
    font-size: 0.7rem;
  }

  .epractice-parent {
    width: 94%;
    height: 15rem;
    margin: 4rem auto 0 auto !important;
  }

  .epractice-child>img {
    width: 2rem;
    padding: 1.2rem 0 0.8rem 0;
  }

  .epractice-child>h5 {
    font-size: 1.5rem;
  }

  .epractice-child>p {
    font-size: 0.8rem;
    padding: 0.9rem;
  }

  .try-now>p {
    font-size: 0.8rem;
    margin-right: 0.3rem;
  }

  .try-now>img {
    height: 1rem;
  }

  .learn-more>p {
    font-size: 0.8rem;
    margin-right: 0.4rem;
  }

  .learn-more>img {
    height: 0.75rem;
  }

  .e-practice-bottom-button {
    width: 7rem;
    height: 2.5rem;
    border-radius: 1rem !important;
  }

  .social-icons svg {
    font-size: 2rem;
    margin: 1rem;
  }
}

@media screen and (max-width: 325px) {

  /* .input_field {
    width: 250px;
  } */
  .signup-left-wrapper {
    background-size: 120% 80%;
  }

  .input_field-wrapper {
    background-size: 13% 8%;
  }

  .bottom-text {
    font-size: 0.9rem !important;
  }

  .para {
    font-size: 0.8rem;
  }

  .privacy_footer a {
    margin: 0 1rem;
  }

  .privacy_footer h2 {
    font-size: 13px;
  }

  .down_app_text h3,
  h4 {
    font-size: 10px;
  }

  .down_app_text h5 {
    font-size: 10px;
  }

  .down_app_text {
    padding: 0px;
    width: 200px;
  }

  #download_app {
    width: 60px;
  }

  .e-practice-header-tab button {
    margin: 0 0.3rem;
    font-size: 0.6rem;
    padding: 0 !important;
  }

  /* 5th section starts */

  /* .section5-expertise {
    height: 75vh;
  } */
  .expertise-section {
    margin: 1rem auto;
  }

  .expertise-sectionwise-headers {
    font-size: 1.5rem;
  }

  .expertise-sectionwise-desc {
    font-size: 0.8rem;
  }

  .section5-inputs div {
    margin: 0.3rem 0.3rem 2rem 0;
  }

  .section5-inputs div>p {
    font-size: 0.5rem;
  }

  .like-comment-share img {
    height: 1rem;
    margin: 0 0 2rem 1.25rem;
  }

  .report p {
    font-size: 0.8rem;
    margin-left: 0.7rem;
  }

  /* 5th section ends */
  /* .bgImage {
      height: 560px !important;
    } */
}

/* .form-bg {
    background-color: #f0f0e4 !important;
    background-color: #00446f;
    width: 100vw;
    height: 93v;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
  }
  
  .form1-bg {
    background-image: url("../../images/form1bg.png");
  }
  
  .form-background {
    margin-left: auto;
  } */

.form-bg {
  /* background-color: #f0f0e4 !important; */
  /* background-color: #00446f; */
  width: 100vw;
  height: 93vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.form1-bg {
  background-image: url("../../images/form1bg.png");
}

.form2-bg {
  background-image: url("../../images/form2bg.png");
  /* background-size: cover; */
}

.form3-bg {
  background-image: url("../../images/form3bg.png");
}

.form-background {
  background-color: #ffff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  margin-top: 10px;
  width: 80%;
  height: 91%;
  box-shadow: 0px 2px 3px 2px rgb(196, 190, 190) !important;
  border-radius: 0px 5px 10px 5px;
}

@media screen and (min-width: 700px) {
  .form-background {
    /* padding-bottom: 0px; */
    margin-bottom: auto;
    width: 80%;
    margin-top: 0;
    margin-left: auto;
    padding: 15px;
    height: 93%;
    margin-right: 30px;
    box-shadow: 0px 2px 3px 2px rgb(196, 190, 190) !important;
    border-radius: 0px 5px 10px 5px;
  }

  /* .form-bg {
    height: ;
  } */
}

@media (min-width: 1024px) {
  .form-background {
    /* padding-top: 100px; */
    height: 94%;
    width: 55%;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: 0px 2px 3px 2px rgb(196, 190, 190) !important;
    border-radius: 0px 5px 10px 5px;
  }
}

.text-container {
  display: none;
}

@media screen and (min-width: 700px) {
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    color: #0068a7;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.main-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottom-text-container {
  align-self: flex-start;
  margin-top: 100px;
  padding-top: 100px;
  padding-left: 25px;
  font-size: 22px;
}

@media screen and (min-width: 700px) {
  .head {
    font-size: 22px;
    color: #0068a7;
  }

  .para {
    font-size: 13px;
    margin-top: 20px;
    color: #3e7ea5;
  }

  .bottom-text-container {
    font-size: 13px;
    color: #002134;
    padding-left: 14px;
  }
}

@media (min-width: 1024px) {
  .head {
    font-size: 38px;
  }

  .para {
    font-size: 19px;
    margin-top: 20px;
  }

  .bottom-text-container {
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .head {
    font-size: 50px;
  }

  .para {
    font-size: 24px;
    margin-top: 30px;
  }

  .bottom-text-container {
    font-size: 26px;
    padding-top: 300px;
    padding-left: 60px;
  }

  .main-text-container {
    padding-right: 39px;
  }
}

.bottom-text-container1 {
  align-self: flex-end;
  font-size: 22px;
  padding-top: 400px;
  padding-right: 30px;
  color: #ffff;
}

.head2 {
  color: #ffff;
  font-size: 32px;
}

.para2 {
  color: #ffff;
  font-size: 22px;
}

@media screen and (min-width: 700px) {
  .head2 {
    font-size: 14px;
  }

  .para2 {
    font-size: 12px;
  }

  .bottom-text-container1 {
    font-size: 12px;
    padding-top: 250px;
  }

  .bt2 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 1024px) {
  .head2 {
    font-size: 25px;
  }

  .para2 {
    font-size: 18px;
  }

  .bottom-text-container1 {
    font-size: 16px;
  }
}

@media screen and (min-width: 1440px) {
  .head2 {
    font-size: 32px;
  }

  .para2 {
    font-size: 24px;
  }

  .bottom-text-container1 {
    font-size: 20px;
    padding-top: 400px;
    margin-right: 50px;
  }
}

@media screen and (min-width: 700px) {
  .head3 {
    font-size: 16px;
  }

  .para3 {
    font-size: 10px;
  }

  .bt3 {
    font-size: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .head3 {
    font-size: 28px;
  }

  .para3 {
    font-size: 18px;
  }

  .bt3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .head3 {
    font-size: 34px;
  }

  .para3 {
    font-size: 24px;
  }

  .bt3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .third-form {
    justify-content: center;
  }
}