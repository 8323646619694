.policy_header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-bottom: 25px; */
  padding: 30px 110px;
}
.policy_header ul {
  display: flex;
  list-style: none;
  cursor: pointer;
  padding: 0px;
}
.policy_header li {
  margin: 10px 35px 10px 0px;
  padding-bottom: 10px;
  text-decoration: none;
  font-size: 26px;
}
.text_link {
  color: #3e7ea5 !important;
}

/* .policy_header h2 {
} */
.border_line {
  border-bottom: 2px solid #3e7ea5;
  font-weight: 600 !important;
}
/* .border_line {
  border-bottom: 2px solid #3e7ea5;
  font-weight: 600;
} */
#policy_body_padding {
  /* padding-top: 30px; */
  margin: 0px 110px;
  color: #212121;
  /* color: #3e7ea5; */
}
.policy_subheader h2 {
  font-size: 24px;
  color: #3e7ea5;
}
#policy_margin {
  margin-top: 30px;
}
#policy_margin p {
  font-size: 18px;
}
.first_heading h1 {
  font-size: 21px;
  font-weight: 600;
  /* color: #3e7ea5; */
}
.first_heading p {
  font-size: 18px;
}
.setpolicy_text {
  margin-top: 15px;
  font-size: 18px;
}
.subpart p {
  font-size: 18px;
}
.intr_sub_header h2 {
  font-size: 19px;
}
.font_text {
  font-size: 18px;
}
.display__laptop {
  display: block;
}
.border_login {
  margin-right: 10px;
  border: 1px solid #007dcd;
  padding: 5px 30px;
  font-weight: 500;
  color: #007dcd;
}
.login_text {
  font-size: 18px;
}
.docsteth_text {
  font-size: 34px;
  color: #007dcd;
  font-weight: 500;
}
#logo_height {
  position: relative;
  top: 5px;
  height: 45px;
  width: 45px;
}
.logo-sub {
  font-size: 10px;
  margin-top: 8px !important;
  color: #004d7e;
}
#head_terms {
  margin: 0px;
}
#head_terms p {
  font-size: 18px;
}
.terms_cond h1 {
  font-size: 18px;
  font-weight: 500;
}
.eff_date {
  font-size: 19px;
  font-weight: lighter;
}
.terms_end {
  padding-bottom: 15px;
}
.term_main_head {
  font-size: 21px;
  text-align: center;
}
.elibrary_homepage_body1 {
  box-shadow: 0px 3px 6px #00000029;
  background-color: #ffff;
  padding: 0px !important;
}

@media screen and (max-width: 1024px) {
  .policy_header {
    font-size: 21px;
  }
  .policy_header {
    padding: 20px 100px;
  }
}
@media screen and (max-width: 830px) {
  .policy_header {
    font-size: 20px;
  }
}

@media screen and (max-width: 769px) {
  .policy_header {
    margin-bottom: 10px;
    padding: 20px 10px !important;
  }
  #policy_body_padding {
    margin: 0px 10px;
  }
  .policy_subheader h2 {
    font-size: 20px;
  }
  .first_heading h1 {
    font-size: 19px;
    font-weight: 600;
    /* color: #3e7ea5; */
  }
  .policy_header li {
    font-size: 20px;
  }
}
@media screen and (max-width: 481px) {
  .policy_header {
    margin-bottom: 0px;
    padding: 10px !important;
  }
  .policy_header li {
    margin: 5px;
    font-size: 14px;
  }
  #policy_body_padding {
    padding-top: 0px;
  }
  .policy_subheader h2 {
    font-size: 20px;
  }
  .font_text {
    font-size: 14px;
  }
  .subpart p {
    font-size: 14px;
  }
  .intr_sub_header h2 {
    font-size: 16px;
  }
  .display__laptop {
    display: none;
  }
  .login_text {
    font-size: 14px;
  }
  .border_login {
    padding: 5px 10px;
  }
  .docsteth_text {
    font-size: 24px;
  }
  #logo_height {
    height: 25px;
    width: 25px;
  }
  #policy_margin {
    margin-top: 20px;
  }
  #head_terms p {
    font-size: 16px;
  }
  .term_main_head {
    font-size: 18px;
    text-align: center;
  }
  .terms_cond h1 {
    font-size: 16px;
  }
  .eff_date {
    font-size: 16px;
    font-weight: lighter;
  }
  .first_heading h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 426px) {
  .policy_header li {
    font-size: 12px;
  }
  #policy_margin p {
    font-size: 16px;
  }
  .first_heading p {
    font-size: 14px;
  }
  .setpolicy_text {
    font-size: 16px;
  }
}
@media screen and (max-width: 376px) {
  #logo_height {
    height: 20px;
    width: 20px;
  }
  .docsteth_text {
    font-size: 20px;
  }
  .logo-sub {
    font-size: 6px;
  }
}
