.hashpost_margin {
  margin: 30px;
}

.sidebar {
  position: sticky;
  height: fit-content;
  margin: 0px 16px;
  flex: 0.2;
}

.sidebar_width {
  width: 100% !important;
}

.upcomming_event_padding {
  padding: 10px 20px;
}

.sidebar__right {
  text-align: left;
  margin-top: 24px;
  background-color: white;
}

.star_bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* .responsivesidebar {
  display: block;
} */
.sidebar__recentItem {
  display: flex;
  padding: 10px 0px;
}

.sidebar__groupItem {
  display: flex;
  padding: 10px 0px;
}

.sidebar__orgItem {
  display: flex;
  padding: 10px 0px;
}

.sidebar__heading {
  font-size: 16px;
  color: #000000;
}

.sidebar__title {
  font-size: 12px;
  color: #000000;
}

.sidebar__titleWebinar {
  font-size: 12px;
  width: 80px;
  height: 25px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #007dcd;
  color: white;
  border: none;
}

.sidebar__titleWebinar:hover {
  background-color: blue;
  box-shadow: 0px 0px 2px black;
}

.sidebar__avatar {
  margin-bottom: 10px;
}

.sidebar__left {
  display: flex;
  flex-direction: column;
  align-items: left;
  border-bottom: 1px solid lightgray;
  background-color: white;
  padding: 10px 0px;
}

.sidebar__icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidebar__icon {
  margin: 0px 10px;
  color: gray;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar__stats {
  padding: 10px 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.sidebar__stat {
  display: flex;
  justify-content: space-between;
}

.sidebar__substat {
  display: inline-flex;
}

.sidebar__statNumber {
  font-weight: bold;
  font-size: 12px;
  color: #3b10cc !important;
}

.sidebar__group {
  display: flex;
  padding-left: 20px;
}

.sidebar__memberstat {
  display: flex;
}

.sidebar__btn {
  border-top: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.home_border_side {
  border: 0.10000000149011612px solid #e7e7e7;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  background-color: white;
}

.sidebar__Audience {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

/* font */
.fontItalic {
  font-style: italic;
}

/* text-align */
.textAlign {
  text-align: left !important;
}

.textCenter {
  text-align: center;
}

.side-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.side-content {
  font-size: 12px;
  padding-left: 25px;
}

@media screen and (max-width: 1025px) {
  .upcomming_event_padding {
    padding: 10px;
  }

  .sidebar_width {
    width: 80% !important;
  }
}

@media only screen and (max-width: 961px) {
  .d-sidebar {
    display: none !important;
  }

  .hashpost_margin {
    margin: 15px;
    margin-block-end: 85px;
  }
}

.join-webinar {
  border: none;
  color: white;
  background-color: #007cdc;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 15px;
}

.pb-1 {
  padding-bottom: 4px;
}

@media screen and (max-width: 769px) {
  .star_bar {
    display: block !important;
  }
}

@media screen and (max-width: 480px) {
  .hashpost_margin {
    margin: 5px;
    margin-block-end: 85px;
  }
}

.t_black2 {
  color: #00000099;
}

/* @media screen and (max-width: 768px) {
  .responsivesidebar {
    display: none;
  }
} */
