.sub-header {
  font-size: 15.5px !important;
  border-radius: 12px;
  text-align: left;
  font-weight: bold !important;
}

.request,
.req-bg2 {
  /* background: transparent linear-gradient(180deg, #2004bc 0%, #808080 100%) 0%
    0% no-repeat padding-box; */
  background-image: url("../../icons/backgroundpeople.png");
  /* background-position: initial; */
  background-repeat: no-repeat;
  background-size: contain !important;
}

.show__all {
  text-transform: capitalize !important;
  font-size: 16px;
}

#share_social {
  margin-top: 0px !important;
  margin-right: 16px;
  font-weight: 550 !important;
  padding: 5px 12px !important;
  font-size: 14px;
  line-height: 16px;
}

.whatsapp_icon {
  font-size: 35px;
  margin-right: 16px;
}

.req-org-bg {
  width: 100%;
  border-radius: 20px 20px 0 0;
  /* height: 100%; */
  height: inherit;
  display: flex;
  background-size: 100% 50% !important;
  margin: auto;
  filter: drop-shadow(5px 1px 5px #00000029);
}

.organisation {
  background: transparent linear-gradient(180deg, #7cbc04 0%, #808080 100%) 0% 0% no-repeat padding-box;
}

.my-img {
  width: 65px;
  height: 65px;
  /* background-color: white; */
  /* border-radius: 50%; */
  border-radius: 15px;
  margin: auto;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;
}

.list-style {
  list-style: none !important;
  font-size: 15px;
  padding-left: 0 !important;
}

.font-bold {
  font-weight: bold !important;
}

.card-pad {
  padding-top: 10px;
  /* font-size: 13px; */
}

.sugg-list {
  padding: 8px 24px 0px 24px !important;
  justify-content: space-between !important;
}

.list-text {
  font-size: 14;
  font-weight: normal;
}

.text-primary {
  color: #000000E6 !important;
}

.follow-link {
  text-align: right;
  color: #3b10cc !important;
  text-decoration-color: #3b10cc !important;
}

.accept {
  text-transform: capitalize !important;
  color: #0054c7 !important;
  border-radius: 10px !important;
  border-color: #0054c7 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.acc {
  padding: 0px 30px !important;
}

.pad {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.domain-icon {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.req-card {
  min-height: 140px;
  margin-bottom: 16px;
}

.custom-card {
  border-radius: 15px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.req-list {
  display: block !important;
  padding-left: 32px;
  padding-right: 32px;
  max-height: 250px;
  overflow-x: auto;
}

.req-list::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

.req-list::-webkit-scrollbar-thumb {
  background: lightgray;
}

/* .req-list::-webkit-scrollbar-corner {
    background: white;
} */
.sugg-icon {
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 0.61;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  text-align: center;
  padding: 1px;
  cursor: pointer;
}

.arrow-icon {
  font-size: 16px !important;
  margin-left: -7px;
}

.icon-clr {
  color: #0072fa;
}

.sugg-bg {
  background-size: 100% 65% !important;
  height: 85% !important;
}

.close-icon {
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff29;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  cursor: pointer;
}

.close-icon>svg {
  color: #fbfbfb;
  font-size: 16px;
}

.cap {
  font-size: 14px;
  color: #00000099 !important;
}

.font {
  font-size: 15px;
  /* font: normal normal 300 15px Helvetica Neue */
}

.card-font {
  text-align: center;
}

.org-bg,
.org-bg2 {
  background: transparent linear-gradient(180deg, #04bc52 0%, #808080 100%) 0% 0% no-repeat padding-box;
  border-radius: 12px 12px 0px 0px;
  background-image: url("../../icons/background.png");
  background-repeat: no-repeat;
  /* background-size: contain !important; */

  opacity: 1;
}

.text-right {
  text-align: right;
  text-align: -webkit-right;
}

.cardresponsive {
  height: 260px;
  width: 220px;
}

.card-width {
  height: 260px;
  width: 200px;
}

.request__css {
  padding: 16px;
}

.peers_header {
  flex-grow: 1;
  margin-top: 8px;
  padding: 16px 8px 0px 8px !important;
}

.request_Desktop {
  display: block;
}

.request_Mobile {
  display: none;
}

.req_button_display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .request_display_desktop {
  display: block;
} */
.request_display_mobile {
  display: none;
}

.List_Desktop {
  display: block;
}

.List_Mobile {
  display: none;
}

.peer_desktop {
  display: block;
}

.inside_icon {
  width: 20px !important;
  height: 20px !important;
}

.custom_padding {
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.load_more_btn{
  border-radius: 12px !important;
  height: 30px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  width: 120px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  background-color: #47aee1 !important;
  font-size: 16px !important;
}

/* #suggested_card {
  height: 260px;
} */

@media screen and (min-width: 1280px) {
  .peers_header {
    padding: 16px 0px 0px 0px !important;
  }
}

@media screen and (max-width: 960px) {
  #topic_bottom {
    margin-block-end: 70px;
  }
}

@media screen and (min-width: 450px) and (max-width: 520px) {
  .card-width {
    width: 180px;
  }

  .peers_header {
    padding: 0px 8px 0px 8px !important;
  }
}

@media screen and (max-width: 960px) {
  .Custom_card_height {
    margin-bottom: 40px !important;
  }

  .request_Desktop {
    display: none;
  }

  .request_Mobile {
    display: block;
  }

  /* .request_display_desktop {
    display: none;
  } */
  .request_display_mobile {
    display: block;
  }



  .peers_header {
    margin-top: 0px;
    padding: 8px 8px 0px 8px !important;
  }

  .peer_desktop {
    display: none;
  }

  .cards_margin {
    margin-block-end: 85px !important;
  }

  /* .font-bold {
    font-weight: 400 !important;
  } */
}

@media screen and (min-width: 600px) {
  .res-card {
    width: 99%;
  }
}

/* @media screen and (max-width: 768px) {
  .cardresponsive {
    height: 250px;
    width: 200px;
  }
} */

@media screen and (max-width: 960px) {
  .cardresponsive {
    height: 250px;
    /* width: 190px; */
    width: inherit !important;
  }

  .List_Desktop {
    display: none;
  }

  .List_Mobile {
    display: block;
  }

  /* .clear__button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  } */
}

@media screen and (max-width: 481px) {
  #share_social {
    line-height: 12px;
    font-size: 12px;
  }

  .whatsapp_icon {
    font-size: 25px;
  }

  .custom_padding {
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 426px) {
  .cardresponsive {
    height: 250px;
    width: 165px;
  }

  .suggested__topic {
    height: 140px;
    width: 110px;
  }

  /* .font {
    font-size: 10px;
  }
  /* .accept {
    width: 50px;
  } */
  .followtext {
    font-size: 10px;
  }

  .Custom_card_height {
    margin-bottom: 50px !important;
  }

  #width_mobile {
    width: 100%;
  }

  .cap {
    font-size: 12px;
    color: black !important;
  }

  #card_margin {
    margin: 0px 60px;
  }
}

@media screen and (max-width: 376px) {
  #line_height {
    line-height: 20px;
  }

  #share_social {
    line-height: 12px;
    font-size: 10px;
  }

  .inside_icon {
    width: 15px !important;
    height: 15px !important;
  }
}

.name_hover:hover {
  text-decoration: underline;
  color: #1976d2
}