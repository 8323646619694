.input-container {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.link_border th,
.link_border td {
  border-color: transparent;
}

.link_border th {
  font-weight: bold;
  font-size: 16px;
}

.link_border td {
  font-size: 16px;
}

.create_orz_text {
  font-size: 21px;
  color: #003bd9;
  padding-left: 15px;
  font-weight: 400;
}

.just_space_btwn {
  display: flex;
  justify-content: space-between;
}

.work__space {
  display: flex;
  justify-content: center;
}

#profile_header {
  display: flex;
}

.title_exp {
  font-size: 21px;
  color: #237ac4;
}

.border_img1 {
  border: 0.10000000149011612px solid #e7e7e7;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  background-color: white;
  /* border-radius: 8px !important; */
}

.icon {
  padding: 10px;
  color: black;
  min-width: 50px;
  text-align: center;
}

.feed__display {
  display: block;
}

.input-field {
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  border-color: lightgray;
  border-style: solid;
}

i.material-icons {
  position: relative;
  right: 30px;
  top: 8px;
}

.myprofile {
  display: flex;
}

.myprofile__left {
  flex: 0.3;
  margin: 0px 14px 0px 14px;
}

.myprofile__right {
  flex: 0.4;
}

.feed {
  flex: 0.7;
}

.myprofile__left .last {
  padding-left: 20px;
}

.myprofile .sidebar__stat {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.e-align {
  align-items: center;
}

.about__head {
  width: 98%;
}

.about__mt {
  height: 354px;
}

.about__2 {
  padding-top: 20px;
}

.people__height {
  /* height: 250px; */
  width: 310px;
  /* overflow-y: scroll; */
}

.people1__height {
  max-height: 220px;
  width: 310px;
}

.people__height1 {
  height: 80px;
}

.people_header {
  height: 30px;
}

.sidebar__mb {
  padding-bottom: 15px;
}

.website_header {
  text-transform: capitalize;
  color: black;
}

.side_head {
  font-size: 15px;
}

.addressfont p {
  font-family: Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 500;
  /* padding: 3px; */
}

.icon-none {
  display: none;
}

.people_name {
  font-size: 13px;
  text-transform: capitalize;
  color: black;
  font-weight: 450;
}

.icon-show:hover > .icon-none {
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-btn {
  padding: 15px 30px !important;
  background-color: #0077c4 !important;
}

.validate {
  color: white !important;
  background-color: #24ea00 !important;
  border-radius: 15px !important;
  padding: 5px 30px !important;
  margin: 20px 0px 0px 25px !important;
}

.validate1 {
  color: white !important;
  background-color: #24ea00 !important;
  border-radius: 15px !important;
  padding: 5px 30px !important;
  margin: -15px 0px 0px 5px !important;
}

.p-heading {
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #707070;
  padding: 10px 16px;
}

.location {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 16px;
}

.inputfield {
  display: flex;
  align-items: flex-start;
  /* justify-content: flex-start; */
}

.setinput {
  padding-left: 40px;
  margin-top: -8px;
}

.inputstyle {
  width: 180%;
}

.flex-center {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.sidebar1__padding {
  padding: 15px 20px;
}

.justify-flex-end {
  justify-content: flex-end !important;
}

.check-pad {
  padding: 0px 6px !important;
}

.create-pad {
  padding-right: 35px !important;
  padding-left: 35px !important;
  width: auto !important;
}

.contact-num > .MuiOutlinedInput-root {
  border-radius: 12px;
  height: 44px;
}

.contact-num {
  margin-top: 20px !important;
  border-radius: 12px;
  height: 44px;
  box-shadow: 0px 3px 4px #756d6d29;
  color: grey;
}

.contact-num > .MuiOutlinedInput-root > fieldset.PrivateNotchedOutline-root-1 {
  top: -1 !important;
}

/* organization */

.neworganisation-accordion {
  margin-bottom: 20px;
}

.neworganisation-accordion::before {
  height: 0% !important;
}

.borderbottom1 {
  border-bottom: 1px solid #707070;
}

.newcontact-fieldheader {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.newcontact-formheader {
  text-align: left;
}

.tooltip-icon svg.MuiSvgIcon-root {
  font-size: 1rem;
}

.uploadbtn2 {
  display: inherit;
  text-align: center;
  margin-top: 10px;
}

.uploadbtn2 label {
  font-size: 10px;
  padding: 5px 15px;
  color: #18009f;
  background-color: #f4c0f4;
  border-radius: 5px;
  margin-left: -65px !important;
}

.side_head {
  margin-top: 15px;
}

/* organizationheader */

.contact-num > .MuiOutlinedInput-root > fieldset.PrivateNotchedOutline-root-1 {
  top: -1 !important;
}

.sidebar_pages {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  /* width: 165px; */
  /* align-items: center; */
}

.sidebar_pages:focus {
  background-color: aqua;
}

.sidebar_pagesName {
  margin-left: 20px;
  /* margin-top: 5px; */
  font-size: 13px;
}

/* .sidebar_pagesName:hover {
  font-size: 15px;
} */
.orgImg {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-left: 5px;
  box-shadow: 0px 3px 4px grey;
}

.sidebar_column {
  display: flex;
  flex-direction: column;
}

.height_pages {
  overflow: hidden;
  max-height: 210px;
  overflow-y: scroll;
}

.sidebar_countPAge {
  margin-top: 12px;
  padding-right: 10px;
}

.sidebar_statPage {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid grey; */
}

.parent_header {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 10px;
  height: 50px;
  background-color: #fbfbfb;
  border-bottom: 1px solid blue;
}

.header_container {
  display: flex;
  padding-left: 30px;
}

.header_subcontainer {
  display: flex;
  text-align: left;
}

/* .header__data {
  font-size: 16px;
  color: "#2e3b43";
  font-weight: 600;
  padding-left: 10px;
} */
.connect_btn {
  position: absolute;
  left: 350px;
  border: 1px solid #004096;
  border-radius: 5px;
  top: 18px;
}

.connect_btn Button {
  padding: 3px 25px !important;
  text-align: center;
  color: black;
}

.header_site {
  position: absolute;
  right: 230px;
  top: 20px;
}

.header_faq {
  position: absolute;
  right: 150px;
  top: 20px;
}

.header_signin {
  position: absolute;
  right: 30px;
  top: 18px;
  border: 1px solid #004096;
  border-radius: 8px;
}

.header_signin Button {
  text-transform: lowercase;
  padding: 1px 12px !important;
}

.small_screens {
  display: none;
}

.bigscreens {
  display: block;
}

.create_page {
  padding: 0px 45px;
}

.main_header {
  padding-top: 10px;
}

.myprofile_header {
  flex-grow: 1;
  padding-bottom: 80px;
  /* padding-left: 16px;
  padding-right: 16px; */
}

.update_user_head {
  margin: auto;
  text-align: center;
  padding: 0px 10px;
}

.contact_update {
  padding: 0px 10px;
}

.user_update_main {
  padding: 0px 45px;
}

.update_icon {
  margin: auto;
  text-align: center;
  padding: 0px 10px;
}

.update_icon1 {
  margin: auto;
  text-align: center;
  padding: 0px 10px;
}

.update_icon2 {
  margin: auto;
  text-align: center;
  padding: 0px 10px;
}

.about_padding {
  margin-top: 15px;
}

#user_title {
  font-size: 16px;
  font-weight: 500;
}

#share_button {
  display: flex !important;
  justify-content: space-between !important;
}

.details__were {
  /* display: flex;
  justify-content: flex-start; */
  padding-top: 10px;
  margin-left: 180px;
  padding-left: 30px;
  /* margin-top: 60px; */
  /* padding-left: 30px; */
}

.update_org_padding {
  padding: 0px 45px !important;
}

.update_work_pd {
  padding: 0px 10px;
}

.update_telecom {
  padding: 0px 20px;
}

.identifier_pd {
  padding: 10px 20px;
}

@media screen and (max-width: 1024px) {
  .people__height {
    /* height: 240px; */
    width: 280px;
  }

  .people1__height {
    height: 240px;
    width: 280px;
  }

  .connect_btn {
    left: 300px;
  }

  .about__head {
    padding: 20px 10px 20px 0px;
  }

  .about__mt {
    height: 360px;
  }

  .people__height1 {
    height: 70px;
  }

  .uploadbtn2 label {
    margin-left: -35px !important;
  }

  .about_padding {
    margin-top: 0px;
  }
}

/* @media screen and (max-width: 769px) {

  #profile_header {
    display: none; */
/* @media screen and (min-width: 769px) and (max-width: 961px){
  .card_padding{
    padding-top: 10px!important;
  }
} */
@media screen and (max-width: 961px) {
  .card_padding {
    /* padding-top: 0px!important; */
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  #user_title {
    font-size: 18px;
    font-weight: 500;
  }

  #profile_header {
    display: none;
  }

  .captntag {
    font-size: 12px;
  }

  .connect_btn {
    left: 220px;
  }

  .header_container {
    padding-left: 10px;
  }

  .connect_btn Button {
    padding: 5px 10px !important;
  }

  .people__height {
    height: 300px;
    width: 210px;
  }

  .people1__height {
    height: 240px;
    width: 210px;
  }

  .about__mt {
    height: 360px;
  }

  .people__height1 {
    height: 70px;
  }

  .bigscreens {
    display: none;
  }

  .small_screens {
    display: block;
    /* position: absolute;
    top: 206px !important; */
  }

  .feed__display {
    display: none;
  }

  .details__were {
    display: block;
    /* justify-content: flex-start; */
  }

  .uploadbtn2 {
    padding-left: 29px;
  }

  .main_header {
    padding-left: 25px !important;
  }

  .myprofile_header {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px !important;
  }

  .contact_update {
    padding: 0px;
  }

  .input-field {
    width: 70%;
  }

  .height_pages {
    margin-block-end: 80px;
  }

  .about_padding {
    margin-top: 15px !important;
  }

  .details__were {
    margin-top: 0px !important;
    margin-left: 120px;
    padding-left: 25px !important;
    /* display: flex;
    justify-content: flex-start; */
  }

  /* .feed_destop {
    display: none;
  } */
}

@media screen and (max-width: 700px) {
  .main_header {
    padding-left: 45px !important;
  }

  .create_page {
    padding: 0px !important;
  }

  .input-field {
    width: 55%;
  }
}

@media screen and (max-width: 601px) {
  .main_header {
    padding-left: 0px !important;
  }
  .details__were {
    margin-left: 120px;
  }
}

@media screen and (max-width: 481px) {
  .main_header {
    padding: 0px !important;
  }

  #user_title {
    font-size: 15px;
    font-weight: 500;
  }

  .user_update_main {
    padding: 15px !important;
  }

  .update_icon {
    display: contents;
    padding: 0px !important;
  }

  .update_icon1 {
    display: contents;
    padding: 0px !important;
  }

  .update_icon2 {
    display: contents;
    padding: 0px !important;
  }

  .setinput {
    padding-left: 10px;
  }

  #setpublic {
    display: none;
  }

  .update_org_padding {
    padding: 0px 15px !important;
  }

  .update_work_pd {
    padding: 0px;
  }

  .update_telecom {
    padding: 0px 10px;
  }

  .validate_alignment {
    text-align: center;
  }

  .identifier_pd {
    padding: 5px 10px !important;
  }

  .facility_pb {
    padding: 0px 10px 10px 10px !important;
  }

  #address_line {
    padding: 2px 10px !important;
  }

  .validate {
    padding: 5px 30px !important;
    margin: 0px !important;
  }

  .details__were {
    padding-left: 15px !important;
    margin-left: 110px;
  }

  /* #user_title {
    font-size: 14px;
    font-weight: 400;
  } */
}

@media screen and (max-width: 400px) {
  .update_icon2 {
    display: none;
    padding: 0px !important;
  }

  .user_update_main {
    padding: 5px !important;
  }

  /* #user_title {
    font-size: 12px;
    font-weight: 400;
  } */
}

.pointer_none {
  pointer-events: none !important;
}

@media screen and (max-width: 375px) {
  .details__were {
    margin-left: 100px;
  }
}
