.publish__row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:450px;
    padding: 20px 20px;
}
.publish__inputOptions{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    border-top:1px solid transparent;
    padding:10px 15px;
    background-color: #e4eef5;
}
.publish__icon{
    display: flex;
    align-items: center;
}
.green__btn{
    background-color: green;
}