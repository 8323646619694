.custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d3d3d3;
  /* Inactive dot color */
}

.slick-dots li.slick-active .custom-dot {
  background-color: #3498db;
  /* Active dot color */
}

.slick-prev:before,
.slick-next:before {
  color: #3498db;
}
