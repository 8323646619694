@media screen and (min-width: 1282px) {
  .profileEdit img {
    position: absolute;
    margin-top: 15px !important;
    right: 0px !important;
  }
}

#personal_details {
  height: 160px;
  background-color: rgb(255, 255, 255);
  margin-top: -4px;
}

.profile_picture {
  height: 144px !important;
  width: 144px !important;
  position: absolute !important;
  top: -100px !important;
  margin-left: 30px !important;
  border-radius: 20px !important;
}

.display_more_btn {
  display: block;
}

.display_more_icon {
  display: none;
  /* margin-top: 5px; */
}

.button_display_mobile {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.heading_full_name {
  font-size: 21px;
  font-weight: 550;
}

.heading_caption {
  font-size: 13px;
  font-weight: 400;
  color: rgb(54, 69, 79);
}

.heading_address {
  font-size: 18px;
  font-weight: 400;
  color: rgb(54, 69, 79);
}

#padding_btn_view {
  padding: 0px 22px;
}

#padding__topview {
  padding: 0px 0px 0px 30px;
}

.more_text {
  border: 1px solid #257fc4 !important;
  /* border-radius: 12px !important; */

  color: #237dcd !important;
  height: 30px !important;
  /* color: #000000 !important; */
  text-transform: capitalize !important;
  /* border: 1px solid black !important; */
  border-radius: 20px !important;
  /* margin-top: -5px !important; */
}

.more_icon1 {
  border: 1px solid #257fc4 !important;
  color: #237dcd !important;
  border-radius: 20px !important;
  height: 25px !important;
  width: 30px !important;
  margin-top: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;

  /* display: flex; */
}

.padding_profile_tabs {
  padding: 1rem 2rem !important;
}

.border_img1 {
  border: 0.10000000149011612px solid #e7e7e7;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  background-color: white;
  /* border-radius: 8px !important; */
}

/* @media screen and (max-width:1440px){
  .profileEdit img {
    position: absolute !important;
    margin-top: 15px !important;
    right: 0px !important;
  }
}


.profile__wall {
  background-image: url("../../Atom/img/human-brain-medical-digital-illustration.png");
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  /* border-radius: 25px 25px 0px 0px; */
/* } */

.header___profile {
  position: relative;
}

.profile__header {
  /* position: relative; */
  /* background-color: rgb(199, 206, 217); */
  /* height: 60px; */
  /* margin-top: -60px; */
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.profile__link1 {
  /* margin-left: 150px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.links1 {
  height: 50px;
  background-color: white;
  width: 100%;
}

.links1 ul {
  margin-top: 10px;
  display: flex;
  list-style: none;
  cursor: pointer;
  align-items: center;
}

.links1 li {
  /* margin: 30px 20px; */
  padding: 5px 10px;
  text-decoration: none;
}

.links ul {
  display: flex;
  list-style: none;
  cursor: pointer;
}

.profile_buttons {
  position: absolute;
  left: 10px;
  display: flex;
  top: 10px;
}

.links li {
  padding: 5px;
  margin: 10px 15px;
  text-decoration: none;
}

.profile__top {
  display: flex;
  justify-content: space-between;
}

/* .profileEdit img {
  position: absolute;
  margin-top: 70px !important;
  right: 70px !important;
} */

.profileIcon {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.iconOption {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_view_Desktop {
  display: block;
}

.header_view_Mobiles {
  display: none;
}

.btn_connect {
  border-radius: 20px !important;
  height: 30px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  width: 120px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  background-color: #47aee1 !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  font-size: 16px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.accept_btn {
  border-radius: 12px !important;
  height: 30px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  width: 120px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  background-color: #47aee1 !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  font-size: 16px !important;
}

.decline_btn {
  border-radius: 12px !important;
  height: 30px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  width: 120px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  background-color: #47aee1 !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  font-size: 16px !important;
}

.share__btn {
  border: 1px solid #257fc4 !important;
  border-radius: 20px !important;
  height: 28px !important;
  color: #237dcd !important;
  font-weight: 500 !important;
  width: 120px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center;
  font-size: 14px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* padding: 0px 10px; */
}

@media screen and (max-width: 1281px) {
  .profileEdit img {
    position: absolute;
    margin-top: 15px !important;
    right: 0px !important;
  }

  .profile_picture {
    top: -60px !important;
  }
}

@media screen and (max-width: 961px) {
  .profile_picture {
    top: -70px !important;
  }
  .tab_text_color {
    color: #000000;
  }

  .display_more_btn {
    display: none;
  }

  .display_more_icon {
    display: block;
  }

  .profile__link1 {
    margin-left: 155px;
  }

  .header_view_Desktop {
    display: none;
  }

  .header_view_Mobiles {
    display: block;
  }

  .profile__top__view {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-top: -75px;
  }

  .profile__view__link {
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #profileview__header {
    display: block;
    height: 145px;
    width: 100%;
    margin-top: 73px;
  }

  .profile__view__Image {
    height: 100px !important;
    width: 100px !important;
    position: absolute !important;
    top: 160px;
    margin-left: 30px !important;
    border-radius: 20px !important;
  }

  .profile_buttons {
    margin-top: 290px;
  }

  .header_btn {
    border-radius: 12px;
    height: 33px;
    color: #000000;
    font-weight: 400;
    width: 80px;
    margin-right: 11px;
  }

  .tag_text {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    width: 70px;
    height: 15px;
  }

  .btn_connect {
    border-radius: 20px !important;
    height: 30px !important;
    font-weight: 500 !important;
    width: 125px !important;
    margin-right: 11px !important;
  }

  .accept_btn {
    border-radius: 12px !important;
    height: 33px !important;
    font-weight: 500 !important;
    width: 125px !important;
    margin-right: 11px !important;
  }

  .decline_btn {
    border-radius: 12px !important;
    height: 33px !important;
    font-weight: 500 !important;
    width: 125px !important;
    margin-right: 11px !important;
  }

  #text_name {
    padding-left: 20px;
  }

  .Mobile_profile__cover {
    height: 150px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .profile_buttons {
    right: 20px;
    margin-top: 0px;
  }
}

@media screen and (max-width: 481px) {
  .profile_buttons {
    right: 8px;
    display: flex !important;
    /* justify-content: flex-end !important; */
  }

  .display_more_icon {
    display: block;
    margin-top: 0px !important;
  }

  #personal_details {
    height: 150px;
  }

  #accept {
    border-radius: 12px;
    height: 25px;
    color: #000000;
    font-weight: 600;
    width: 80px;
    margin-right: 11px;
  }

  .button_display_mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .profile_picture {
    height: 100px !important;
    width: 100px !important;
    position: absolute !important;
    top: -50px !important;
    margin-left: 10px !important;
  }

  #padding_btn_view {
    padding: 0px;
  }

  #padding__topview {
    padding: 0px 0px 0px 10px;
  }

  .heading_full_name {
    font-size: 16px;
    font-weight: 500;
  }

  .heading_caption {
    font-size: 14px;
    font-weight: 400;
    color: rgb(54, 69, 79);
  }

  .heading_address {
    font-size: 14px;
    font-weight: 500;
    color: rgb(54, 69, 79);
  }

  #decline {
    border-radius: 12px;
    height: 25px;
    color: #000000;
    font-weight: 600;
    width: 80px;
    margin-right: 11px;
  }

  #profileview__header {
    margin-top: 80px;
  }

  .profile__view__Image {
    height: 100px !important;
    width: 100px !important;
    position: absolute !important;
    top: 145px;
  }

  .profile__top__view {
    margin-top: -90px;
  }

  .btn_connect {
    height: 25px !important;
    width: 90px !important;
    font-size: 12px !important;
  }

  .accept_btn {
    height: 25px !important;
    width: 90px !important;
    font-size: 12px !important;
  }

  .decline_btn {
    height: 25px !important;
    width: 90px !important;
    font-size: 12px !important;
  }

  .share__btn {
    font-size: 12px !important;
    height: 25px !important;
    width: 70px !important;
  }
}

@media screen and (max-width: 376px) {
  .profile__view__Image {
    height: 80px !important;
    width: 80px !important;
    top: 148px;
    margin-left: 20px !important;
  }

  #text_name {
    padding-left: 0px;
  }
}

@media screen and (max-width: 321px) {
  .profile__top__view {
    margin-top: -36px !important;
  }

  #profileview__header {
    margin-top: 30px;
  }

  .links1 ul {
    padding: 0px 0px 0px 10px !important;
  }
}
