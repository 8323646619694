.article-cover {
  height: 200px;
  background-color: #d8caca;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.a-image {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
}

.cover-link {
  /* display: flex;
  align-items: center; */
  text-align: center;
  /* margin-top: 2.5rem; */
}

.cover-link > a {
  font-weight: bold;
  font-size: 18px;
}

.article-title {
  font-size: 20px !important;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
  padding-top: 10px;

  margin-bottom: 10px;
}

.form-input {
  border: none !important;
  font-size: 16px;
}

.form-input input:focus,
.form-input:focus-visible {
  border: none !important;
  outline: none;
}

li > .form-input {
  width: 60%;
}

.w-100 {
  width: 100%;
}

.editorClassName {
  border: 1px solid #f1f1f1;
  padding: 10px;
  max-height: 400px !important;
}

.editorClassName a {
  text-decoration: underline;
  color: blue;
}

.close-article {
  color: #ff2e00 !important;
  margin-right: 15px !important;
}

.publish-article {
  color: #2107b1 !important;
  font-weight: bold !important;
  padding: 2px 25px !important;
  border-color: #2107b1 !important;
  border-radius: 8px !important;
}

.article-style {
  text-align: right;
  /* margin-top: 15px; */
}

.article-common {
  text-transform: capitalize !important;
  font-size: 18px !important;
}

.draft-close {
  font-size: 24px;
  font-weight: bold;
}

.continue {
  color: #1b28c6 !important;
  font-size: 21px !important;
  margin-top: 15px !important;
  text-transform: capitalize !important;
}

.draft {
  background-color: #00b400 !important;
  border-color: #00b400 !important;
  color: white !important;
  border-radius: 12px !important;
  padding: 3px 10px !important;
}

.create-article {
  background-color: #dfedf7;
  color: black;
  padding: 6px 15px;
  box-shadow: 0px 2px 4px lightgrey;
}

.preview-cover {
  max-height: 300px;
  min-height: 100px;
}

.p-bg {
  background-color: #8f8686;
}

.t-bg {
  background-color: #b3adad;
}

.p-title {
  height: 92px;
  text-transform: capitalize;
  display: flex;
  font-size: 18px;
}

.add-cover {
  text-decoration: underline;
}

.p-title > p {
  margin: auto;
}

.font-weight {
  font-weight: bold;
}

.preview-class {
  /* text-start pl-2 fs-5 pt-1 */
  text-align: start;
  padding-top: 10px;
  padding-left: 15px;
  font-size: 19px;
}

.cover-pic {
  text-align: center;
  margin-top: 15px;
}

.post-btn {
  padding: 3px 25px !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}

.cursor {
  cursor: pointer;
}

.save-draft {
  text-transform: capitalize !important;
  font-size: 17px !important;
  color: #8f8686 !important;
}

.a-tags {
  color: #9ca2a6 !important;
}

.a-tags > p,
.a-tags > ul > li {
  margin-top: 10px;
  list-style-type: none !important;
}

.a-tags > ul {
  padding-left: 0px;
}

.a-tags > ul > li {
  color: black;
}

.a-tags > ul > li > span {
  color: #9ca2a6 !important;
  padding-left: 15px;
}

.a-btn {
  border: 1px solid #1804b1 !important;
  color: #1804b1 !important;
  border-radius: 15px !important;
  padding: 4px 32px !important;
  background-color: white;
  font-size: 12px;
  text-transform: capitalize !important;
}

.icon-btn {
  padding: 0px 8px !important;
}

.mentions {
  /* margin: 1em 0; */
  font-family: inherit !important;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
}

.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
  /* background-color: red;
    color: red; */
}

.mentions--singleLine .mentions__input {
  padding: 5px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  /* font-family: monospace; */
  font-size: 14px;
  border: none;
  max-height: 200px;
  overflow: auto;
}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  /* min-height: 63px; */
}

.mentions--multiLine .mentions__input {
  border: none;
  outline: 0;
  padding: 9px;
  overflow: inherit !important;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions {
  max-height: 200px;
  overflow: scroll;
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

/* .mentions__mention {
    background-color: #cee4e5;
  } */
/* .mentions__mention {
    position: relative;
  z-index: 1;
  color: black;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
  } */
/* .mentions__highlighter */
.article_mentions {
  flex-grow: 1;
}

.article_mentions > div > textarea {
  padding-top: 0px !important;
}

.article_mentions > div > div {
  padding: 0px !important;
}

.article_mentions > div {
  padding: 6px !important;
}

.mentionBackgrnd {
  box-shadow: #18b418 !important;
  background-color: rgb(242, 233, 233);
  /* padding: 1px; */
  border-radius: 5px;

  border: rgb(252, 252, 254) !important;
  /* border: white !important; */
}

.article_share::-webkit-scrollbar {
  width: 0px !important;
}

.event_mention > div > textarea {
  /* margin-top: 9px!important; */
  border: 1px solid lightgrey !important;
  border-radius: 10px;
  padding: 10px;
}

.bg_color_editarticle {
  background-color: lightgrey;
  /* opacity: 0.7; */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

#coverIdOfEditor::-webkit-scrollbar {
display: none;
}
/* #coverIdOfEditor::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
} */
/* 
#coverIdOfEditor::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
} */

/* 
#coverIdOfEditor::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* #coverIdOfEditor::-webkit-scrollbar-corner {
  background: transparent;
} */