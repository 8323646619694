.add {
  display: block;
}

.notdisplaySelect {
  display: none;
}

.displaySelect {
  display: "block";
}

.menuHoverEffect:hover {
  background-color: #c7c7c7;
}

.showImg {
  position: relative;
  width: 50px;
}

div.showImg:hover .editImg {
  display: block !important;
}

div.showImg .editImg {
  display: none !important;
}

.uploadImg {
  cursor: pointer;
  color: black;
  width: 71px;
  height: 36px;
  font-size: 16px;
  background-color: black;
  opacity: 0.7;
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  top: 35px;
}

.uploadIcon {
  color: white;
  font-size: 12px !important;
  position: absolute;
  top: 10px;
  left: 30px;
}

.video_btn {
  width: 100%;
  display: flex;
  padding: 5px 5px;
  /* margin: 0px 5px; */
  align-items: center;
  justify-content: center;
}

.media_class {
  box-shadow: 0px 10px 10px lightgray;
  border-radius: 10px;
}

.input__title {
  font-size: 12px;
  padding-top: 3px;
}

/* .icon__ml {
  margin-left: 5px;
} */

.post_btn {
  background-color: #4a33ff !important;
  border-radius: 10px !important;
  padding: 3px 20px !important;
}

.color_white {
  color: white !important;
}

.add-option {
  padding: 2px 20px !important;
  margin-top: 10px !important;
  /* border:1px solid #106DBE!important; */
  border-radius: 15px !important;
  font-size: 12px;
  text-transform: capitalize !important;
}

.textCenter {
  text-align: center;
}
