.Appvault_icon {
  display: flex;
  flex-direction: row;
}

.border_img_medscope {
  border: 0.10000000149011612px solid #e7e7e7;
  box-shadow: 0px 3px 6px #00000029;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 8px;
}

.AppVault_body {
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  height: 77vh;
  overflow-y: scroll;
  box-shadow: 0px 3px 6px #00000029;
}

.AppVault_header {
  box-shadow: 0px 3px 6px #00000029;
}

.appvault,
.AppVault_header {
  border-radius: 12px !important;
}

.med_text_header {
  margin: 25px !important;
  padding: 0px 50px;
  font-size: 21px !important;
  text-align: center !important;
  font-weight: 550 !important;
  font-family: "Helvetica Neue";
}

.med_sub_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app_vault_text {
  color: #001f88;
  font-size: 16px;
  padding-left: 10px;
}
.highlight_text {
  font-size: 16px;
  align-items: center;
  display: flex;
  gap: 10px;
}

#main_med {
  display: flex;
  align-items: center;
  padding-left: 40px;
}

.enterprise_text {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  padding-left: 15px;
}

.medscope_toolkit {
  margin-top: 30px;
}

.med_main_card {
  display: flex;
  /* justify-content: space-evenly; */
  padding: 0px 15px 0px 50px;
  margin-top: 15px;
}

.med_card_display {
  height: 130px;
  width: 230px;
  padding: 8px;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  border: 0.10000000149011612px solid #e7e7e7;
  border-radius: 8px !important;
  margin: 10px;
}

.med_sub_text {
  /* margin-top: 10px; */
  text-align: left;
}

.med_card_actions {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0px !important;
  margin-top: 5px;
}

.card_med_icon {
  height: 50px;
  width: 50px;
  padding: 8px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  border: 0.10000000149011612px solid #e7e7e7;
  border-radius: 8px !important;
}

.application_icon {
  width: 25px;
  height: 25px;
  position: relative;
  left: 13px;
  top: 3px;
}

.application_icon_text {
  text-align: center;
  font-size: 9px;
  margin-top: 8px;
}

.job_openings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #d4f4f9; */
  height: 50px;
  box-shadow: 0px 3px 6px #00000029 !important;
  /* border-radius: 8px 8px 0px 0px; */
  padding: 0px 20px;
}

.list_card_carrer {
  display: flex;
  justify-content: space-evenly;
  margin: 30px -25px 0px -25px;
}

#card_size {
  width: 100%;
  height: auto;
  box-shadow: 0px 3px 6px #00000029;
  /* box-shadow: 0px 3px 6px aqua; */
  /* border-radius: 8px; */
  background-color: #ffffff;
}

.footer_medscope {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #001f88;
  height: 120px;
}

.Medical_device_card {
  width: auto;
  height: 90px;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 8px !important;
}

.Formulary_card {
  width: auto;
  height: 90px;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 8px !important;
}

.Research_card,
.Medical_device_card,
.Formulary_card {
  width: 300px;
  height: 90px;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 8px !important;
}

.research_sub_text {
  font-size: 18px;
  color: #001f88;
  font-weight: 500;
}

.research_p_text {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.footer_arrow_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5798b2;
  height: 100%;
  width: 40px;
}

.medscope_icon {
  width: 25px;
  height: 25px;
}

.header_flex {
  display: flex;
  align-items: center;
}

.medcard_head_text {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  font-weight: 550 !important;
  text-align: center;
  /* padding-left: 20px; */
}

#btn_trynow {
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #000eb5;
}

#enterprise_content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
}

#job_opening_text {
  font-size: 18px;
  color: #0077c4;
  padding-left: 10px;
  /* font-weight: bold; */
}

.job_right_text {
  font-size: 14px !important;
  color: #000eb5;
}

.mui_remove_padding {
  padding-bottom: 18px !important;
}

.job_title {
  font-size: 16px;
  /* padding-bottom: 5px; */
  font-weight: 500;
}

.icon_wdth_high {
  width: 25px;
  height: 25px;
}

.card_med_icon1 {
  height: 50px;
  width: 50px;
  padding: 4px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  border: 0.10000000149011612px solid #e7e7e7;
  border-radius: 8px !important;
  margin-top: 10px;
}

.application_icon1 {
  width: 25px;
  height: 25px;
  position: relative;
  left: 13px;
  top: 3px;
  /* margin-top: 5px; */
}

.card_med_icon2 {
  height: 50px;
  width: 50px;
  padding: 7px !important;
  box-shadow: 0px !important;
  background-repeat: no-repeat;
  border: 0 !important;
  border-radius: 8px !important;
}

.organisation_icon {
  height: 55px;
  width: 55px;
  margin: inherit;
  border-radius: 7px;
}

.job_sub_title {
  font-size: 14px;
  /* padding-bottom: 5px; */
}

.job_city {
  font-size: 12px;
}

#app_spacing {
  display: flex;
  margin-top: 20px;
}

.medscope_icon1 {
  width: 25px;
  height: 25px;
}

.share_icon {
  width: 15px;
  height: 15px;
}

.header_icon1 {
  height: 20px;
  width: 20px;
}
.medscope_card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px;
  gap: 20px;
}
.medscope_card_size {
  height: 120px;
  width: 120px;
}
.medimg {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.highlights {
  /* border: 2px solid #92ceeb; */
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 4px;
  height: 200px;
  margin: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.allevents {
  margin: 20px;
  display: flex;
  border: 1px solid red;
  gap: 20px;
}
.events {
  /* border: 1px solid black; */
  /* width: 70%; */
  display: flex;
  flex-wrap: wrap;
}
.cards {
  width: 100%;
  align-items: center;
}
.gallery {
  border: 1px solid black;
  width: 30%;
}
.galleryItem {
  height: 120px;
  border: 2px solid #e6e6e6;
}
.minicardWrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  height: 20%;
  margin-bottom: 3%;
}
.minicard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  text-align: center;
  width: 33%;
}
/* @media screen and (max-width: 1200px) {
  .med_card_display {
    height: 100px;
    width: 161px;
    padding: 4px !important;
  }
} */
.med_sub_text {
  font-size: 10px !important;
  /* margin-top: 5px;   */
  text-align: center;
}

#medscopesearchmob {
  display: none;
}
.cardmain {
  margin: 25px;
}
.mobmedicalgallery {
  display: none;
}
.minicardWrappermob {
  display: none;
}
.line-ellipsishome {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mui_remove_padding {
  height: 200px;
  overflow: scroll;
}
.sidebar__pt1 {
  padding-top: 5px;
}

.medscope_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

#medscope_homepage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .medscope_homepage_body {
  box-shadow: 0px 3px 6px #00000029;
  border: 0.10000000149011612px solid #e7e7e7;
  background-color: #ffff;
  padding: 0px !important;
} */
.medscope_rightcontainer_icon1 {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1250px) {
  .med_text_header {
    padding: 0px 10px !important;
    font-size: 18px !important;
  }

  .app_vault_text {
    font-size: 14px;
  }
  .highlight_text {
    font-size: 14px;
  }

  .med_card_display {
    height: 100px;
    width: 161px;
    padding: 4px !important;
  }

  .med_sub_text {
    font-size: 10px !important;
    text-align: center;
    /* margin-top: 5px; */
  }

  .medcard_head_text {
    font-size: 10px !important;
  }

  .med_card_actions {
    margin-top: -2px !important;
  }

  #btn_trynow {
    font-size: 10px;
    padding: 0px !important;
  }

  #card_size {
    width: 100%;
    height: auto;
  }

  .list_card_carrer {
    margin: 30px 0px 0px 0px !important;
  }

  .job_openings {
    /* padding: 0px 5px !important; */
    padding: 2px 12px !important;
  }

  #job_opening_text {
    font-size: 14px;
  }

  .job_right_text {
    font-size: 10px !important;
  }

  .mui_remove_padding {
    padding: 8px !important;
    height: 200px;
    overflow: scroll;
  }

  .research_sub_text {
    font-size: 12px;
  }

  .research_p_text {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .Research_card,
  .Medical_device_card,
  .Formulary_card {
    width: 220px;
    height: 70px;
  }

  .job_title {
    font-size: 12px;
  }

  .icon_wdth_high {
    width: 18px;
    height: 18px;
  }

  .card_med_icon1 {
    height: 40px;
    width: 40px;
    padding: 4px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    background-repeat: no-repeat;
    border: 0.10000000149011612px solid #e7e7e7;
    border-radius: 8px !important;
  }

  .application_icon1 {
    width: 15px;
    height: 15px;
    position: relative;
    left: 13px;
    top: 3px;
    margin: 0px;
  }

  .application_icon_text {
    text-align: center;
    font-size: 9px;
    margin-top: 5px;
  }

  .organisation_icon {
    height: 45px !important;
    width: 45px !important;
  }

  .job_sub_title {
    font-size: 10px;
  }

  .job_city {
    font-size: 9px;
  }

  .med_main_card {
    padding: 0px !important;
  }

  #app_spacing {
    margin-top: 5px !important;
  }

  .job_openings {
    height: 40px;
  }

  .footer_arrow_icon {
    width: 30px;
  }

  .share_icon {
    width: 12px;
    height: 12px;
  }
  #medscopesearchmob {
    display: none;
  }
  .mobmedicalgallery {
    display: none;
  }
  .sidebar__pt1 {
    padding-top: 5px;
  }
}

@media screen and (max-width: 769px) {
  /* #display_tablet {
    display: none;
  } */
  #medscopesearchmob {
    display: none;
  }
  .mobmedicalgallery {
    display: block;
    margin: 25px;
  }
  .mobgallery {
    display: flex;
    flex-direction: row;
    gap: 10px;
    list-style-type: none;
    overflow-x: auto;
    padding: 0;
    margin: 10px 0;
  }
  .mobgalleryitem {
    width: 80px;
    height: 80px;
    border: 2px solid #e6e6e6;
  }
  .minicardWrapper {
    height: 25%;
  }
  .minicardWrappermob {
    display: none;
  }
}

@media screen and (max-width: 481px) {
  .AppVault_body {
    margin-block-end: 45px;
  }
  #medscopeappbar {
    display: none;
  }
  #medscopesearchmob {
    display: block;
  }
  .highlights {
    height: 120px;
    margin: 10px 20px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
  }
  .medscope_card {
    margin: 0;
  }
  .cardmain {
    margin: 10px;
  }
  /* .gallery{
    overflow-x: scroll;
  } */
  /* .galleryItem{
    width: 100px;
  
  } */
  .minicardWrapper {
    display: none;
  }
  .medscope_card,
  .highlights,
  .cardmain {
    margin-left: 0;
    margin-right: 0;
  }
  .medscope_card {
    margin-top: 10px;
  }
  .medscope_card_size {
    height: 80px;
    width: 90px;
  }
  .mobmedicalgallery {
    display: block;
    margin: 0px;
    margin-bottom: 50px;
  }
  .mobgallery {
    display: flex;
    flex-direction: row;
    gap: 10px;
    list-style-type: none;
    overflow-x: auto;
    padding: 0;
    margin: 0;
  }
  .mobgalleryitem {
    width: 80px;
    height: 80px;
    border: 2px solid #e6e6e6;
  }
  .mui_remove_padding {
    height: 150px;
    padding-top: 0px !important;
  }
  .job_openings {
    /* box-shadow: none !important; */
    height: 35px;
  }
  /* .cardmainmob {
    padding-top: 10px !important; 
    background-color: #4671c5;
    background: aqua;
  } */
  .minicardWrappermob {
    display: flex;
    gap: 10px;
    margin: 10px 0 40px 0;
  }
  .minicardpara {
    font-size: 10px;
    margin-top: 10px;
  }
  .formula_para {
    height: 100px;
  }
  .line-ellipsishome {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .medscope_icon {
    width: 20px;
    height: 20px;
  }
  #card_size {
    width: 99%;
    border: 1px solid #e6e6e6;
  }
  .formula_updates {
    border: 1px solid #e6e6e6;
  }
  .sidebar__pt1 {
    padding-top: 5px;
  }
  .medscope_homepage_body {
    border: none;
    box-shadow: none;
    /* background: aquamarine; */
  }
}

.dobold {
  font-weight: 600;
}

.app_gallery {
  padding: 2px !important;
  border-radius: 12px !important;
  background-color: #d9d1dd !important;
  color: #5446ff !important;
}

#outlined-select-app {
  padding: 10px;
}
