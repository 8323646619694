.view-header-name{
    font-size: 16px;
    color: #2E3B43;
    font-weight: 600;
    padding-left: 10px
}
.header-tagline{
    font-size: 12px;
    color: #2E3B43;
    padding-left: 10px
}

.header-avatar{
    height: 32px!important;
    width: 32px!important;
    /* margin-top: 5px!important */
}
.connect-btn{
    border: 1px solid #004096!important;
border-radius: 7px;
opacity: 1;
color: #000000!important;
padding-left: 45px!important;
padding-right: 45px!important;
}

.sign-in-btn{
    border: 1px solid #007DCD;
    color: #3E7EA5;
    text-transform: capitalize!important;
    border-radius: 10px!important;
    font-size: 18px!important;
    padding-top: 2px!important;
    padding-bottom: 2px!important;
}
.timeline--height{
    height: 415px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    margin-left: 14px;
    margin-top: 20px;
}

.timeline--header{
    display: flex;
    padding: 10px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%)
}
.timeline{
    font-weight: bold;
    padding: 10px;   
}

.private--overlay{
    position: absolute;
  width: 100%;
  height: 100%;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 249, 249, 0.5);
  z-index: 2;
}

.private--page{
    position: relative;
    filter: blur(3px)
}

.book-header{
    background-color: #007DCD!important;
    color: white;
    padding: 5px 10px;
}

.appointment{
    font-size: 18px!important;
    margin-top: 1.5rem;
}
.peer-connect{
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.peer-connect>a{
    text-decoration: none;
    font-size: 18px;
}
