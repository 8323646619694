/* @media only screen and (min-width: 1300px) {
  .poll-class > article > ul {
    width: 40% !important;
    margin-top: 10px !important;
  }
} */
.poll-class.poll-width > article > ul {
  width: 100% !important;
}
/* @media only screen and (max-width: 1299px) { */
.poll-class > article > ul {
  margin-top: 10px !important;
  width: 100% !important;
}
/* } */
.poll-class {
  width: 100% !important;
}
@media screen and (max-width: 481px) {
  .poll {
    margin: 0px;
  }
}
