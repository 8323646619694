.Details{
    padding: 5px;
    margin-left: 20px;
}
.Details h3{
    font-size: 18px;
    font-weight: 400;
    opacity: 1;
}
.Details p{
    font-size: 12px;
   font-weight: 400;
    opacity: 1;
    letter-spacing: 0.4px;
}
.Ratings{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 11px;
}
.Ratings_content{
    margin-left: 10px;
    padding: 5px;
}
.Ratings_content img{
    margin-left: 5px;
    height: 12px;
}

.Trail_Details{
    display: flex;
    justify-content: space-between;
    padding: 3px;
}
.Trail_Details span{
    font-size: 13px;
    opacity: 1;
}
.Trail_Details p{
    font-size: 13px;
    opacity: 1;
}
.Subscribe{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-left: 25px;
    margin-top: 11px;
}
.Subscribe p{
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-left: -35px;
}
.Subscribe a{
    text-decoration: none;
    margin-top: 4px;
    margin-bottom: -16px;
    margin-left: -33px;
    color: #0054C7;
}