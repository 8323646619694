.largedevices {
  display: block;
}

.smalldevices {
  display: none;
}

.elibrary_rightcontainer_icon1 {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.elibrary_rightcontainer_body {
  background-color: #ffffff;
  /* display: flex; */
  box-shadow: 0px 3px 6px #00000029;
  /* margin-top: 10px; */
}

.components {
  display: flex;
  margin-top: 10px;
}

.components h4 {
  font-size: 18px;
  font-weight: 500;
  padding: 8px;
}

.desktop_show_alphabets {
  display: block;
}

.filtering {
  width: 100%;
}

.filtering ul {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  list-style-type: none;
  flex-wrap: wrap;
}

.bg-blue {
  background-color: #007dcd;
}

.elibrary_remove {
  flex-grow: 1;
  margin-top: 8px;
  padding: 16px;
}

.drugs_classes_css {
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

/* shoud be add */

/* .ebm_cardiology {
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
} */

@media only screen and (max-width: 993px) {
  .block-ul {
    display: block !important;
  }

  .elibrary_remove {
    padding: 16px;
  }
}

/* .filtering ul li{
     margin-left: 50px; 
    
} */

.f-li {
  padding: 0px 15px;
}

.sort_icon {
  display: flex;
  align-items: center;
}

.alphabits ul li {
  margin-right: 30px;
  font-size: 21px;
}

.drugs_list ul {
  list-style-type: none;
  /* margin-top: 10px; */
}

.drugs_list ul li {
  padding: 5px;
  font-size: 21px;
  font-weight: 300;
}

.drugs_list {
  overflow-y: auto;
  height: 680px;
}

.e-leftsidebar {
  position: fixed;
  overflow-y: auto;
  /* height: 89vh; */
  height: inherit;
  /* padding-bottom: 10px; */
}

/* .e-rightsidebar{
    margin-left: 18%!important;
}

@media only screen and (min-width: 910px) and (max-width: 1199px){
    .e-rightsidebar{
        margin-left: 35%!important;
    }
} */

@media only screen and (max-width: 960px) {
  .e-leftsidebar {
    position: static !important;
    height: auto !important;
  }

  .e-rightsidebar {
    margin-left: 0px !important;
    padding-left: 16px !important;
  }

  .grid-hide {
    display: none;
  }
}

.keywords {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 10px;
}

.h-100 {
  height: 100%;
}

.drug-btn {
  height: 26px;
  border-radius: 10px !important;
  text-transform: capitalize;
  font-size: 18px;
  margin: 0px 10px !important;
}

@media screen and (min-width: 960px) {
  .largedevices {
    display: block !important;
  }

  .smalldevices {
    display: none !important;
  }

  .desktop_show_alphabets {
    display: block !important;
  }
}

@media screen and (max-width: 960px) {
  .largedevices {
    display: none;
  }

  .smalldevices {
    display: block;
  }

  .speciality_height {
    height: calc(100vh - 315px);
    overflow: scroll;
  }

  .elibrary_remove {
    margin-block-end: 55px;
    padding-top: 10px;
  }

  .desktop_show_alphabets {
    display: none;
  }

  .drugs_list {
    height: 100% !important;
  }

  .diseases_classheight {
    height: calc(100vh - 218px);
    overflow: hidden;
  }

  .drugs_classes_css {
    margin-top: -300px !important;
  }
}

@media screen and (max-width: 530px) {
  .drugs_list ul {
    margin-top: 25px;
  }

  .drugs_list ul li {
    font-size: 18px;
  }

  /* .elibrary_remove { */
  /* margin-block-end: 75px; */
  /* } */
}

@media screen and (max-width: 480px) {
  .drugs_list ul {
    margin-top: 10px;
  }

  #ml_arrow {
    margin-left: -10px;
  }

  .elibrary_remove {
    margin-block-end: 0px;
    padding-top: 10px;
  }
}