.publication {
  display: flex;
}
.publication__left {
  /* flex: 0.4; */
  margin: 0px 0px 30px 14px;
  width: 100%
}
.publication__left ul {
  list-style: none;
  padding: 20px 0px;
}
.publication__right {
  border-radius: 12px 12px 0px 0px;
  /* flex: 2; */
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  margin: 30px 16px; 
  /* margin-top: 30px;
  margin-left:16px; */
  margin-bottom: 30px;
}
.publication__nav {
  display: flex;
  padding: 20px 20px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px 12px 0px 0px;
}
.publications {
  display: flex;
  padding: 0px 30px;
}
.publication__type {
  display: flex;
  padding: 20px 0px;
  width: 350px;
  line-height: 25px;
}
.pub-type{
  padding: 20px 0px;
  width: 350px;
  line-height: 25px;
}
 .publication__icons {
  display: flex;
  margin-left: 150px;
}
.publication__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
} 
.publication__edit{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
} 
.publication__scroll {
  display: flex;
  /* margin-left: 250px; */
  padding-top:15px;
  flex: 0.1;
}
.publication__img {
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0.54);
}
