.create-left {
  width: 472px;
  height: 130px;
}

.common-logo {
  width: 124px;
  height: 116px;
}

.contained-btn {
  border-radius: 9px !important;
  box-shadow: 0px 7px 6px lightgrey !important;
  background-color: #007dcd !important;
}

.phone-num > .MuiOutlinedInput-root {
  border-radius: 12px;
  height: 48px;
  /* color: grey; */
}

.phone-num {
  margin-top: 17px !important;
  border-radius: 12px;
  /* margin-bottom: 5px; */
  height: 48px;
  box-shadow: 0px 3px 4px #756d6d29;
  color: grey;
}

.phone-num > .MuiOutlinedInput-root > fieldset.PrivateNotchedOutline-root-1 {
  top: -1 !important;
}

.btn-next {
  /* display: flex; */
  border: 1px solid #3e7ea5 !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  margin: auto !important;
  margin-top: 1.5em !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 6px lightgrey inset, 0px 5px 6px lightgrey;
}

#prefix_input {
  width: 40%;
  padding-right: 8px;
}

#firstname_input {
  width: 60%;
}

.resend_otp {
  text-transform: capitalize !important;
  border-bottom: 3px solid #2400ea !important;
  color: #2400ea !important;
  padding: 0px !important;
}

.comp_profile {
  text-align: center;
  font-size: 29px;
}

.add_profile {
  margin-top: 15px;
}

.add_profile h2 {
  text-align: center;
  color: #01cde5;
  font-size: 28px;
}

.profile__upload {
  position: relative;
  border: 1px solid black;
  width: 200px;
  height: 210px;
  border-radius: 20px;
}

.upload__profile {
  position: absolute;
  left: 109px;
  top: 170px;
  margin-bottom: 20px;
}

.profile-attach {
  font-size: 10px;
  padding: 10px 25px;
  color: #ffffff;
  background-color: #3e7ea5;
  border-radius: 18px;
  margin-left: -65px !important;
}

#imag_profile {
  position: absolute;
  height: 144px;
  width: 144px;
  border-radius: 20px;
  left: 28px;
  top: 10px;
}

.skip_btn {
  display: flex;
  justify-content: center;
}

.next__btn {
  display: flex !important;
  background-color: #47aee1 !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  width: 150px !important;
}

#pursuing {
  padding-left: 125px;
}

.during_padding {
  padding: 10px 10px 20px 140px;
}

.enddate_padding {
  padding: 0px 8px;
}

.my-img1 {
  width: 65px;
  height: 65px;
  background-color: white;
  border-radius: 50%;
  margin: 0px !important;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;
}

.sidebar__substat1 {
  display: inline-flex;
  margin-top: 15px;
}

.domain-icon1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Qualification_display {
  display: flex;
  justify-content: center;
}

.step_mobile {
  font-size: 18px;
  text-align: center;
  color: #475fe1;
}

.next_padding {
  margin-top: 30px;
}

#above_upload {
  margin-top: 35px;
}

.profile_display {
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
}

#next_padding {
  margin-top: 40px;
}

.complete_prof {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.quali_text {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

.text_input_fields {
  width: 392px;
  margin: auto;
  text-align: left;
}

#align_education {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_shadow .MuiInputBase-root {
  box-shadow: 2px 3px 10px lightgrey;
  border-radius: 10px;
  background-color: white;
}

.input_shadow fieldset {
  border-color: transparent;
}

.input_padding input {
  padding: 10px 13px !important;
}

.select_padding input {
  padding: 1px 4px !important;
}
/* .select_padding input {
  padding: 1px 3px !important;
} */

@media (max-width: 1025px) {
  .create_margin p {
    font-size: 1.4rem !important;
  }
}

@media screen and (max-width: 821px) {
  .create_margin p {
    font-size: 1.2rem !important;
  }

  .create-left {
    width: 330px;
    height: 130px;
  }
}

@media screen and (max-width: 769px) {
  .mobile_padding {
    padding: 0px 15px 0px 30px;
  }

  .paddind_tablet {
    padding: 0px !important;
  }

  .create-left {
    width: 320px;
    height: 130px;
  }
}

@media screen and (max-width: 426px) {
  .comp_profile {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }

  .add_profile h2 {
    text-align: start;
    font-size: 24px;
    font-weight: 500;
    padding-left: 30px;
  }

  .mobile_padding {
    padding: 0px 15px 0px 30px;
  }

  #pursuing {
    padding-left: 18px;
  }

  .during_padding {
    padding: 10px 10px 20px 10px;
  }

  .enddate_padding {
    padding: 12px 8px;
  }

  .Qualification_display {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px !important;
  }

  .step_mobile {
    text-align: start;
    padding-left: 29px;
  }

  #next_padding {
    margin-top: 60px;
  }

  #above_upload {
    margin-top: 65px;
  }

  .profile_display {
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    margin-top: 40px;
  }

  .quali_text {
    justify-content: flex-start;
    padding: 20px 0px 0px 29px;
  }

  .complete_prof {
    justify-content: flex-start;
  }

  .text_input_fields {
    width: 320px;
    margin: auto;
    text-align: left;
  }

  #align_education {
    justify-content: flex-start;
    padding: 30px 0px 0px 29px;
  }

  /* .create_margin {
    margin-top: 15px !important;
    style={{ padding: "30px 0px 0px 29px" }}
  } */
}
