.search .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  display: none;
}

.search {
  width: 50%;
  padding: 0px !important;
  border-radius: 10px;
  background: #e1f3ff;
}

.search .MuiOutlinedInput-root {
  padding: 0px !important;
  border-radius: 10px;
  background: #e1f3ff;
}
/*
.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px !important;
    border-radius: 10px;
    background: #e1f3ff;
} */
.search_pics{
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px
}