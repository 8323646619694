.sidebarhover {
    background-color: #26A500;
    /* width: 68px; */
    color: white;
    overflow: hidden;
    transition: 0.2s;
    width: 6%;
    position: relative;
}

.rightside-width {
    width: calc(100% - 250px);
}

.right-width {
    width: calc(100% - 77px);
}

/* @media only screen and (min-width:601px)and (max-width: 992px) {
    .right-width {
      width: 86%;
    }
    .rightside-width{
        width: 54%;
    }
   .radio{
       flex-direction: column!important;
   }
  }
  @media only screen and (min-width:993px)and (max-width: 1204px) {
    .right-width {
      width: 86%;
    }
    .rightside-width{
        width: 58%;
    }
  }
  @media only screen and (min-width:1205)and (max-width: 1280px) {
    .right-width {
      width: 91%;
    }
    .rightside-width{
        width: 74%!important;
    }
  } */
.sidebarhover:hover {
    width: 20%;
}

.sidebarhover:hover+.rightside-width {
    width: 80%;
}

.update_details {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.updated_content h6 {
    font-size: 14px;
    font-weight: 400;
}

.updated_content p {
    font-size: 14px;
    font-weight: 500;
    padding: 4px;
}

.radioGroups {
    color: blue;
    padding-left: 36px;
}

.updated_content {
    display: flex;
    justify-content: space-between;
}

.DoseDetails_body {
    background-color: #FFFFFF;

}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
    top: 150px;
    right: 12px;
}

@media screen and (max-width:480px) {
    .padding_menu {
        padding: 0px !important;
    }

    .radioGroups {
        padding-left: 20px;
    }
}