.poll {
  border: 1px solid lightgrey;
  /* margin: 10px; */
  /* padding: 10px; */
  border-radius: 10px;
}
.add-option {
  padding: 2px 20px !important;
  margin-top: 10px !important;
  /* border:1px solid #106DBE!important; */
  border-radius: 15px !important;
  font-size: 12px;
  text-transform: capitalize !important;
}
.poll-class > article > ul > li > div,
.poll-class > article > ul > li > button {
  border-radius: 20px !important;
  /* padding: 14px 5px!important; */
  text-transform: capitalize;
  height: 40px !important;
  line-height: 1 !important;
  color: rgb(62, 126, 165) !important;
  border-color: rgb(62, 126, 165) !important;
}
.poll-class > article > ul > li > div > div > span {
  color: rgb(62, 126, 165) !important;
  border-color: rgb(62, 126, 165) !important;
}
.poll-class > article > ul > li > button {
  padding: 13px 5px !important;
  line-height: 0.5 !important;
}
.poll-class > article > h3 {
  text-transform: capitalize;
  align-self: flex-start !important;
  font-weight: 600;
  color: black !important;
}
.poll-class > article > ul,
.poll-class > article > p,
.poll-class > article > h3 {
  padding: 0px 10px !important;
}
.poll-class > article > ul > li > div > div:first-child {
  border-radius: 20px !important;
}

.poll-class > article > ul > li > div > div {
  display: flex;
  flex-direction: row-reverse;
  width: 90%;
  justify-content: space-between;
}

.undo_btn {
  font-weight: 500 !important;
  width: 120px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

/* .poll-class>article>ul>li>div>div{
    background-color: #707070!important;
} */

/* @media only screen and (min-width: 1300px) {
  .poll-class > article > ul {
    width: 40% !important;
    margin-top: 10px !important;
  }
} */
.poll-class.poll-width > article > ul {
  width: 100% !important;
}
/* @media only screen and (max-width: 1299px) { */
.poll-class > article > ul {
  margin-top: 10px !important;
  width: 100% !important;
}
/* } */
.poll-class {
  width: 100% !important;
}
@media screen and (max-width: 481px) {
  .poll {
    margin: 0px;
  }
}
