.posting__as {
  border: 1px solid;
  padding: 0px;
  border-radius: 5px;
  /* width: 230px; */
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.device__locked {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
/* .posted__by {
  max-width: 120px;
} */
.open_menu {
  color: black;
  width: 220px;
}

.menu_item {
  /* border: 1px solid; */
  /* padding: 0px !important; */
  border-radius: 5px;
  /* width: 200px; */
}

.public_who_can_see {
  border: 1px solid;
  padding: 0 !important;
  border-radius: 5px;
  /* width: 200px; */
}
.posting_drop_down {
  margin-bottom: 10px;
}
.posting__as {
  margin-bottom: 10px;
  /* width: auto; */
}
@media screen and (max-width: 600px) {
  .posting_drop_down {
    margin-bottom: 10px;
  }
  .posting__as {
    margin-bottom: 10px;
    /* width: auto; */
  }
  /* .posted__by {
    max-width: 220px;
  } */
  .open_menu {
    color: black;
    width: calc(100vw - 120px);
  }
  /* .public_view {
    display: flex;
    justify-content: center;
  } */
  /* #public_mobile_view {
    display: none;
  } */
  /* .menu_item {
    display: flex;
    justify-content: center;
  } */
  /* .device__locked {
    width: 100%;
  } */
}
@media screen and (max-width: 376px) {
}
