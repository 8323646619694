.ssr_desc{
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 1.3;
}

.ssr_layout{
    width: 60%;
    margin: auto;
    margin-top: 20px;
}

.ssr_view{
    border: 1px solid #e5e5dc;
    border-radius: 10px;
}

.f-20{
    font-size: 20px!important;
}

.social_share_h{
    font-size: 22px;
    color: #007dcd;
}

.ss_btn{
    border-radius: 25px!important;
    padding: 2px 25px!important;
}

.ss_skip{
    font-size: 18px!important;
    color: black!important;
    background: none!important;
    margin-top: 10px!important;
}

.ss_textarea{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.ss_textarea:focus-visible{
    outline: none
}
