.Ebm_components {
  display: flex;
  padding: 10px;
}

.img_sizes {
  max-width: 30px;
  max-height: 30px;
}

.Ebm_components h4 {
  font-size: 27px;
  font-weight: 500;
  padding: 8px;
}

.Ebm_filtering {
  width: 100%;
  /* padding-top: 10px; */
  justify-content: space-between;
  display: flex;
}

.Ebm_filtering ul {
  display: flex;
  padding-top: 14px;
  margin-left: -56px;
  list-style-type: none;
  flex-wrap: wrap;
}

.Ebm_filtering ul li {
  margin-left: 20px;
  font-size: 21px;
  font-weight: 500;
}

.druglist ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-left: 50px;
  margin-top: 15px;
}

.druglist ul li {
  list-style: none;
  flex: 0 0 33.333333%;
  /* padding: 5px; */
  font-size: 21px;
  font-weight: 300;
}

.Drug__list {
  padding: 24px;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sp-header1 {
  border-radius: 10px !important;
  text-transform: capitalize;
  font-size: 18px;
  padding: 1px 7px !important;
}

.sp-header {
  /* height:26px; */
  border-radius: 10px !important;
  text-transform: capitalize;
  font-size: 18px;
  padding: 1px 7px !important;
}

.sp-padding p {
  padding: 6px 10px;
  font-size: 21px;
}

.display_index {
  display: block;
}

.display__text {
  display: none;
}

.under__cardiology {
  height: 550px;
}

.display_classes_drugs {
  display: flex;
}

.sort_icon_display {
  display: none;
}

.drug_list_padding {
  padding: 24px !important;
}

.all_drugs_css {
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.Desktop_display_cardiology {
  display: block;
  width: 100%;
}

.Mobile_display_cardiology {
  display: none;
}

.display_matter {
  width: 100%;
  height: calc(100vh - 319px);
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
}

#conditions_padding {
  padding-right: 40px;
}

.scrollheight {
  height: calc(100vh - 250px);
}

.Ebm_specialities_Desktop {
  display: block;
}

.Ebm_specialities_Mobile {
  display: none;
}

.durg_height {
  height: 100%;
}

.drugs_specialities_Desktop {
  display: block;
}

.drugs_specialities_Mobile {
  display: none;
}

/* EBM HOME PAGE */

#ebm_homepage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.more_icon {
  position: relative;
  padding-left: 15px;
  color: black;
}

.display_ebm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text_heding {
  margin: 15px !important;
  padding: 0px 50px;
  font-size: 21px !important;
  text-align: center !important;
  font-weight: 550 !important;
  font-family: "Helvetica Neue";
}

.elibrary_homepage_body {
  /* box-shadow: 0px 3px 6px #00000029; */
  border: 0.10000000149011612px solid #e7e7e7;
  background-color: #ffff;
  padding: 0px !important;
}

.test_sub_head {
  font-size: 18px !important;
  margin: 30px 20px 10px 20px !important;
  padding: 0px 20px !important;
  text-align: center !important;
  font-weight: 500 !important;
  font-family: "Helvetica Neue";
  color: #2177c4;
}

.text_sub_headindtext {
  font-size: 20px !important;
  margin: auto !important;
  padding: 0px 25px !important;
  text-align: center !important;
  font-weight: 530 !important;
  font-family: "Helvetica Neue";
}

.practice_toolkit {
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
  margin-top: 40px;
}

.practice_toolkit h2 {
  color: #2177c4;
  text-align: left;
  flex-direction: row;
  padding-left: 18px;
  /* font-family: "Helvetica Neue"; */
  font-size: 22px;
}

.card_head_text {
  font-family: "Helvetica Neue";
  font-size: 16px !important;
  font-weight: 550 !important;
  text-align: center;
  padding-left: 12px;
}

#p_text {
  margin: 0px !important;
  padding: 5px !important;
  /* font-family: "Helvetica Neue"; */
  font-size: 15px;
  font-weight: 300;
}

#span_text {
  margin: 0px !important;
  /* font-family: "Helvetica Neue"; */
  font-size: 15px;
  font-weight: 300;
}

.card_display {
  height: 200px;
  /* max-width: 190px; */
  padding: 8px;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  border: 0.10000000149011612px solid #e7e7e7;
  border-radius: 8px !important;
  margin: 5px;
}

#btn_learn_more {
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: black;
}

#btn_start {
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
  color: #000eb5;
  /* margin-left: 35px; */
}

.card_icon_display {
  height: 80px;
  width: 80px;
  padding: 8px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-repeat: no-repeat;
  border: 0.10000000149011612px solid #e7e7e7;
  border-radius: 8px !important;
}

.app_display {
  width: 60px;
  height: 60px;
  position: relative;
  left: 10px;
}

#set_content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.footer_content {
  background-color: #001f88;
  margin-top: 40px;
  height: 180px;
  padding: 30px 30px 0px 30px;
}

#footer_text {
  display: flex;
  align-items: center;
  padding: 0px 30px;
}

.below_text h4 {
  color: #ffffff;
  text-align: left;
  padding-left: 85px;
  font-size: 16px;
  font-weight: 300;
  /* font-family: Helvetica Neue; */
  margin-top: 10px;
}

.enroll_text {
  padding-left: 85px;
  color: #ffffff;
  /* margin-top: 10px; */
  font-size: 16px;
  font-weight: 400;
}

.footer_last_text {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

#review_text {
  display: flex;
  justify-content: space-evenly;
}

#review_text h3 {
  color: #2177c4;
  font-size: 18px;
}

.sub_text {
  margin-top: 25px;
  text-align: center;
}

.card_actions {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0px !important;
  /* margin-top: 20px; */
}

.card_actions1 {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0px !important;
  /* margin-top: 16px; */
  /* position: fixed;
  top: 415px;*/
}

.main_card {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  padding: 0px 15px;
  margin-top: 15px;
}

#img_position {
  position: relative;
  top: 10px;
}

.search_bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header_icon {
  width: 30px;
  height: 30px;
}

#footer_icon {
  width: 15px;
  height: 15px;
  top: 3px;
  position: relative;
  padding-left: 5px;
}

.interactive_apps_icons {
  display: flex;
  padding: 0px 65px;
}

.more_app_icon {
  width: 40px;
  height: 40px;
  position: relative;
  left: 20px;
  top: 8px;
}

.more_app_text {
  text-align: center;
  font-size: 14px;
  margin-top: 18px;
}

.card_display {
  /* height: 150px; */
  width: 190px;
}

@media screen and (max-width: 1300px) {
  .card_display {
    height: 150px;
    width: 190px;
  }

  .sub_text {
    margin-top: 14px;
  }

  .card_actions {
    margin-top: 14px;
    padding: 0px !important;
  }

  .card_actions1 {
    padding: 0px !important;
  }

  .main_card {
    padding: 0px 10px;
  }

  .card_head_text {
    font-size: 14px !important;
  }

  #p_text {
    padding: 0px !important;
    font-size: 13px;
  }

  #btn_learn_more {
    padding: 3px !important;
    font-size: 10px;
  }

  #btn_start {
    font-size: 10px;
    padding: 0px !important;
    text-align: center;
    /* margin-top: 5px !important; */
  }

  #btn_start img {
    width: 15px !important;
    height: 15px !important;
  }

  #img_position {
    position: relative;
    top: 1px;
    width: 10px !important;
    height: 10px !important;
  }

  #span_text {
    font-size: 12px;
  }

  .practice_toolkit h2 {
    font-size: 18px;
  }

  #review_text h3 {
    font-size: 14px;
  }

  .img_sizes {
    width: 20px;
    height: 20px;
  }

  .text_heding {
    padding: 0px !important;
    font-size: 18px !important;
  }

  .practice_toolkit {
    margin-top: 20px !important;
  }

  #header_icon {
    width: 20px;
    height: 20px;
  }

  #footer_icon {
    width: 10px;
    height: 10px;
    top: 5px;
  }

  .interactive_apps_icons {
    display: flex;
    padding: 0px 28px;
  }

  .card_icon_display {
    height: 60px;
    width: 60px;
  }

  .app_display {
    width: 40px;
    height: 40px;
  }

  .more_app_icon {
    width: 30px;
    height: 30px;
    position: relative;
    left: 15px;
    top: 8px;
  }

  .more_app_text {
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
  }

  .footer_content {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .card_display {
    height: 150px;
    width: 160px;
  }

  .sub_text {
    margin-top: 10px;
  }

  .card_actions {
    margin-top: 5px;
    padding: 0px !important;
  }

  .card_head_text {
    font-size: 12px !important;
  }

  #p_text {
    padding: 0px !important;
    font-size: 11px;
  }

  #span_text {
    font-size: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .text_heding {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 960px) {
  #display_tablet {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .Drug__list {
    padding: 0px 16px 16px 16px;
  }

  .display_matter {
    height: calc(100vh - 305px);
  }

  .condition_display_icon {
    display: none;
  }

  .sp-header1 {
    display: none !important;
  }

  .drugs_list {
    max-height: 100% !important;
    height: auto !important;
  }

  .display_index {
    display: none;
  }

  .display__text {
    display: block;
  }

  .under__cardiology {
    height: 790px;
  }

  .display_classes_drugs {
    display: none;
  }

  .sort_icon_display {
    display: block;
  }

  .drug_list_padding {
    padding: 10px 12px 12px 12px !important;
  }

  .all_drugs_css {
    height: 710px;
  }

  .Desktop_display_cardiology {
    display: none;
  }

  .Mobile_display_cardiology {
    display: block;
    width: 100%;
    /* height: 100px; */
  }

  /* .display_matter {
      margin-top: -300px !important;
    } */
  .sp-padding p {
    font-weight: 400;
  }

  .Display_Tablet_search {
    display: block;
  }

  .Display_mobile_search {
    display: none;
  }

  .search__filter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .Ebm_specialities_Desktop {
    display: none;
  }

  .Ebm_specialities_Mobile {
    display: block;
  }

  .durg_height {
    height: 0px !important;
  }

  .mobile_show {
    display: none;
  }

  .mobile_hide {
    display: flex;
  }

  .drugs_specialities_Desktop {
    display: none;
  }

  .drugs_specialities_Mobile {
    display: block;
  }

  .heading_title {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
  }
}

@media screen and (max-width: 530px) {
  .display_matter {
    height: calc(100vh - 351px);
  }

  .Drug__list {
    padding: 6px 12px;
  }

  .sp-padding p {
    font-size: 18px !important;
  }

  .Display_Tablet_search {
    display: none;
  }

  .Display_mobile_search {
    display: block;
  }

  .mobile_hide {
    display: none;
  }

  .mobile_show {
    display: block;
    justify-content: center;
    margin-bottom: 5px;
    padding: 0 10px 0 20px;
  }

  #display_drawer {
    display: block;
  }

  #drwer_mobile {
    display: block;
  }

  #conditions_padding {
    padding: 0px 15px;
  }

  /* #specialities_ul {
      display: none;
    } */
}

@media screen and (max-width:480px) {
  .padding_menu {
    padding: 0px !important;
  }

  .heading_title {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
  } 
}

@media screen and (max-width:321px) {
  .heading_title {
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
  }
}