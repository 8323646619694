.profile {
  overflow-x: hidden !important;
}

.scroll_y_direction {
  height: calc(100vh - 70px);
  overflow: scroll;
}

.responsive_input_margin {
  margin-top: 5px;
}

.tag_name {
  font-size: 16px;
  font-weight: 500;
}

.del_btn {
  border: none;
  background-color: white;
}

.toggle_clear {
  display: flex;
  justify-content: flex-end;
}

.upload_btn {
  margin-top: 10px;
}

.contact_info_margin {
  margin-top: 20px;
}

.contact_details {
  width: 300px;
  height: 150px;
  padding: 15px;
}

.kyc_margin {
  margin-top: 20px !important;
  padding-left: 10px;
}

.id_type_padding {
  padding-left: 10px;
  margin-top: 20px !important;
}

.id_number_margin {
  margin-top: -20px !important;
  padding-left: 10px;
}

.issued_margin {
  margin-top: 3px !important;
}

.mobile_num {
  font-size: 18px;
  font-weight: 500;
}

.email_support {
  font-size: 18px;
  font-weight: 500;
}

.phone_text {
  font-size: 18px;
  font-weight: 500;
}

.await_text {
  font-size: 14px;
  font-weight: 400;
  color: red;
}

.verify_text_success {
  font-size: 16px;
  font-weight: 400;
  color: green;
}

.need_profile {
  padding-left: 20px;
  bottom: 20px;
}

.need_heading {
  padding-left: 10px;
}

.task_icon {
  /* height: 20px !important;
  width: 20px !important; */
  font-size: 16px !important;
  margin-left: 8px;
  color: #002976;
}

.submt_text {
  padding: 10px 0px 0px 20px;
  font-size: 16px;
  font-weight: normal;
}

.error_icon {
  color: red !important;
  padding-right: 10px !important;
}

.error_icon1 {
  color: red !important;
  padding: 0px 0px 0px 10px !important;
  margin-top: -4px !important;
  font-size: 16px !important;
}

.upload_text {
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

.input_height {
  height: 40px !important;
}

#heading_verify {
  font-size: 16px;
  text-align: center;
  color: #002976;
  font-weight: 550;
  padding: 8px;
}

.kyc_text {
  font-size: 16px;
  font-weight: 550;
  color: #000;
}

.government_text {
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
}

.verify_heading_text {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.re_submit {
  height: 30px !important;
  font-weight: 500 !important;
  width: 100px !important;
  text-transform: capitalize !important;
  border: 1px solid #257fc4 !important;
  color: #4a33ff !important;
  border-radius: 8px !important;
}

.error_text {
  border-radius: 12px !important;
  height: 30px !important;
  color: #36454f !important;
  font-weight: 500 !important;
  width: 160px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
  font-size: 17px !important;
  border: 1px solid #000 !important;
}

.verify_text {
  color: #36454f !important;
  font-weight: 500 !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  display: flex !important;
  font-size: 17px !important;
  border: none !important;
}

.button_share_icon {
  border: none !important;
  padding: 0px !important;
  min-width: 40px !important;
}

.art_head {
  font-size: 18px;
  font-weight: 550;
  text-transform: capitalize;
  padding-top: 8px;
}

.padding_tabs {
  padding: 1rem 2rem !important;
  /* padding: 5px !important; */
}

.profile__cover {
  height: 200px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  margin: 0px !important;
  /* background-image: url("../../Atom/img/human-brain-medical-digital-illustration.png"); */
  /* width: auto; */
  /* height: 250px; */
  /* background-repeat: no-repeat;
  background-size: cover; */
  /* background-position: center;  */
  /* border-radius: 25px 25px 0px 0px; */
  /* 2E3B43 */
}

.need_help_text {
  font-size: 16px;
  font-weight: 550;
}

.privacY_text {
  font-size: 13px;
  font-weight: 300;
}

#username_dec {
  font-size: 21px;
  font-weight: 550;
  color: #000000e6;
}

.profile__header_a {
  position: relative;
  background-color: rgba(255, 255, 255, 75%);
  /* height: 60px; */
  margin-top: -5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#share_text {
  font-size: 14px;
  color: #257fc4;
}

.padding_left {
  padding: 0px 8px 0px 0px;
}

.padding__right {
  padding: 0px 0px 0px 8px;
}

.edit_btn {
  /* border: 1px solid #257fc4 !important; */
  border-radius: 20px !important;
  height: 30px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  width: 120px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  background-color: #47aee1 !important;
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.sh_icon {
  border: none !important;
}

.setting-btn {
  border: 1px solid #257fc4 !important;
  border-radius: 20px !important;
  height: 28px !important;
  color: #237dcd !important;
  font-weight: 500 !important;
  width: 120px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* .highlight-btn {
  border: 1px solid #257fc4 !important;
  border-radius: 8px !important;
  height: 30px !important;
  color: #237dcd !important;
  font-weight: 500 !important;
  width: 150px !important;
  margin-right: 11px !important;
  text-transform: capitalize !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center;
  font-size: 16px !important;
} */

.qr_code {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.input_width_xs {
  border-radius: 12px !important;
}

.profile__header1 {
  position: relative;
  background-color: rgba(255, 255, 255, 75%);
  height: 150px;
  margin-top: -5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description_height {
  height: 80px;
}

.profile__right {
  padding: 10px 0px 10px 30px;
}

.profile__link {
  margin-left: 0px;
}

.profile__link ul {
  display: flex;
  list-style: none;
  cursor: pointer;
  padding-left: 0px;
}

.profile__link li {
  padding: 10px 20px;
  text-decoration: none;
}

.profile-active {
  font-weight: 500;
  padding-bottom: 6px;
  border-bottom: 2px solid #027dcd;
  color: #027dcd;
  /* border-bottom: 4px solid #1500b2;
  color: #0069d3; */
}

.profile__top {
  display: flex;
  justify-content: space-between;
}

.btn__setting {
  padding: 5px 20px 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: left;
  align-items: center;
  background-color: rgba(255, 255, 255, 67%);
  margin-left: 30px;
  margin-top: 20px;
  outline: none;
  border: 1px solid rgba(250, 250, 250, 25%);
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 16%);
}

.profileIcon {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.iconOption {
  display: flex;
  justify-content: center;
  align-items: center;
}

#profile__pic {
  position: absolute;
  height: 144px;
  width: 144px;
  /* top: -95px; */
  top: -60px;
  margin-left: 25px;
  border-radius: 20px;
}

/* 
.edit-icon{
  display: none!important;
  color: black!important;
}

.edit-icon:hover{
  color: black!important;
  display: block!important;
}

.profile-pic:hover + .edit-icon{
    display: block!important;
    color: black!important;
}
.profile-pic:hover{
  opacity: 0.2!important;

}
.edit-icon:hover + .profile-pic{
  opacity: 0.2!important;
} */

div.show-image {
  position: relative;
  float: left;
  /* margin: 5px; */
}

/* div.show-image:hover .profile-pic {
  opacity: 0.7;
} */
div.show-image:hover .edit-icon {
  display: block !important;
  color: white !important;
}

div.show-image .edit-icon {
  position: absolute;
  display: none !important;
}

/* .MuiAvatar-root.MuiAvatar-circular.\"avtar__org\".MuiAvatar-colorDefault {
  height: 144px;
  width: 144px;
  position: absolute;
  top: -100px;
  margin-left: 30px;
} */

#laptopscreen {
  display: block;
}

/* #smallscreens {
  display: none;
} */
#profile_tablet {
  display: none;
}

.profile_details {
  display: none;
}

.cover_edit_icon {
  margin-top: 20px !important;
  margin-right: 30px;
  top: 12px;
  right: 12px !important;
}

#profile_edit_icon {
  font-size: 16px;
  color: #000000e6;
  position: absolute;
  top: -12px;
  left: 30px;
  width: 144px;
  height: 56px;
  border-radius: 0px 0px 20px 20px;
  background-color: black;
  opacity: 0.7;
}

.editcursor {
  font-size: 16px;
  position: absolute;
  top: 15px;
  left: 62px;
}

.desktop_publications {
  display: block;
}

.Tablet_publications {
  display: none;
}

.badges_responsive {
  display: flex;
  place-content: space-evenly;
  align-items: center;
  /* width: 95%; */
  margin-left: 20px;

  /* justify-content: space-evenly;
 
  /* background-color: #ffffff;
    height: 200px; */
}

#flex_align {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#hover_black_none {
  font-size: 16px;
  color: #000;
  position: absolute;
  top: 33px;
  /* top: -2.3px; */
  left: 28.5px;
  width: 144px;
  height: 56px;
  border-radius: 0px 0px 20px 20px;
  background-color: #000;
  opacity: 0.7;
}

.journal_publication_text {
  font-size: 18px;
  font-weight: 500;
  color: #257fc4;
  padding: 8px;
}

.publication_link:hover {
  color: #1500b2;
}

/* #position_tab {
  position: sticky !important;
  z-index: 1100 !important;
  top: 60px !important;
} */

.organisation_setinnerhtml > p {
  font-size: 18px !important;
  font-weight: 550 !important;
}

.organisation_setinnerhtml > p > span {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.organisation_setinnerhtml > img {
  height: 300px !important;
  width: 100% !important;
  object-fit: contain !important;
}

.organisation_setinnerhtml > h1 > img {
  height: 300px !important;
  width: 100% !important;
  object-fit: cover !important;
}

.organisation_setinnerhtml > div > img {
  height: 300px !important;
  width: 100% !important;
  object-fit: contain !important;
}

.organisation_setinnerhtml > p > span > strong {
  font-size: 16px !important;
  font-weight: 550 !important;
}

.organisation_setinnerhtml > h1 {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.organisation_setinnerhtml > ul > li > span {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.patient_img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.profile__info {
  background-color: white;
  height: 50px;
  margin-top: 0px;
  padding-left: 15px;
}

.profile__info ul {
  display: flex;
  list-style: none;
  cursor: pointer;
  padding: 14px 0px 14px 10px;
}

.profile__info li {
  /* color: blue; */
  margin: 0px 15px 4px 15px;
  text-decoration: none;
}

.profile-active3 {
  color: #027dcd;
  font-weight: 500;
  padding-bottom: 7px;
  /* color: #0034e3; */
  /* font-weight: 20px !important; */
  /* margin: 0px 15px !important; */
  border-bottom: 2px solid #027dcd;
  /* border-bottom: 3px solid blue; */
}

@media screen and (min-width: 961px) {
  #profile__pic {
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
  }
}

@media screen and (max-width: 961px) {
  #username_dec {
    font-size: 18px;
    font-weight: 600;
    color: #000000e6;
  }

  .drawer_width {
    width: 400px !important;
  }

  .error_text {
    border-radius: 12px !important;
    height: 30px !important;
    color: #36454f !important;
    font-weight: 500 !important;
    width: 160px !important;
    margin-right: 11px !important;
    text-transform: capitalize !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    font-size: 17px !important;
    border: 1px solid #000 !important;
    /* margin-top: -50px !important; */
  }

  .edit_btn {
    /* border: 1px solid #257fc4 !important; */
    border-radius: 20px !important;
    height: 28px !important;
    color: #ffffff !important;
    font-weight: 500 !important;
    width: 120px !important;
    margin-right: 11px !important;
    text-transform: capitalize !important;
    background-color: #47aee1 !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    font-size: 16px !important;
  }

  .padding_left {
    padding: 0px !important;
  }

  .padding__right {
    padding: 0px 0px 0px 8px;
  }

  .profile__cover {
    height: 150px;
  }

  .tablet_display {
    display: none !important;
  }

  .tablet_mobile {
    display: none;
  }

  #profile__pic {
    height: 100px;
    width: 100px;
    top: -50px;
    margin-left: 20px;
  }

  .cover__pic {
    width: 100%;
    overflow: hidden;
    height: 150px !important;
  }

  #laptopscreen {
    display: none;
  }

  /* #smallscreens {
    display: block;
  } */
  #profile_tablet {
    display: block;
    /* margin-top: 20px; */
  }

  .profile__header1 {
    display: block !important;
    height: 150px;
    width: 100%;
    margin-top: -4px !important;
  }

  .profile__info {
    background-color: white;
    height: 50px;
    margin-top: 0px;
    padding-left: 15px;
  }

  .profile__info ul {
    display: flex;
    list-style: none;
    cursor: pointer;
    padding: 10px 0px 0px 10px;
  }

  .profile__info li {
    /* color: blue; */
    margin: 0px 15px 4px 15px;
    text-decoration: none;
  }

  .profile-active1 {
    color: #027dcd;
    font-weight: 500;
    padding-bottom: 6px;
    /* color: #0034e3; */
    border-bottom: 2px solid #027dcd;
    /* border-bottom: 1px solid blue; */
    /* margin: 0px 15px !important; */
  }

  .badges_responsive {
    display: flex;
    margin: 0px !important;
    width: 100%;
    justify-content: space-evenly;
  }

  .sidebar__icon1 {
    margin: 0px 10px;
    color: gray;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile_details {
    display: block;
    /* justify-content: space-between; */
  }

  .icon_visibility {
    display: flex;
    padding-top: 8px;
  }

  .sidebar__stat1 {
    /* padding-top: 45px; */
    /* border: 1px solid red; */
  }

  .sidepadding {
    padding: 5px 16px;
  }

  .edit_icon {
    position: relative;
  }

  .sidebar__statNumber1 {
    font-weight: bold;
    font-size: 18px;
    color: #3b10cc !important;
    /* padding-top: 65px; */
  }

  .place_icon {
    display: flex;
    justify-content: space-between;
  }

  .button_text {
    display: flex;
    justify-content: flex-end;
  }

  .mobile_header h2 {
    font-size: 14px;
    font-weight: 500;
  }

  #profile_edit_icon {
    width: 100px;
    top: -5px;
    left: 20px;
  }

  .editcursor {
    left: 40px;
  }

  .show_para {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d8e8f1;
    padding: 0px 8px;
  }

  .show_text_profile {
    padding: 15px;
    margin-bottom: 10px;
  }

  .targeted_call {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cover_edit_icon {
    margin-right: 20px;
    margin-top: 30px !important;
    /* top: 50px; */
    right: 0px !important;
  }

  .desktop_publications {
    display: none;
  }

  .Tablet_publications {
    display: block;
  }

  /* .profile__cover {
    width: 100% !important;
  } */
}

@media screen and (max-width: 769px) {
  #profile__pic {
    /* top: -60px; */
    /* top: -50px; */
    top: -40px;
    margin-left: 20px;
  }

  #profile_edit_icon {
    top: 14px;
    height: 45px;
  }
  .editcursor {
    left: 35px;
  }
}

@media screen and (max-width: 601px) {
  .cover_edit_icon {
    margin-top: 25px !important;
    right: 0px !important;
    height: 30px;
  }

  .id_type_padding {
    margin-top: 10px !important;
    padding: 0px 10px;
  }

  .issued_margin {
    margin-top: -15px !important;
    padding: 0px 10px;
  }

  .id_number_margin {
    padding: 0px 10px;
  }

  .input_width_xs {
    width: 100% !important;
  }
}

/* @media screen and (max-width: 500px) {
  .cover__pic {
    height: 150px;
  }
} */

@media screen and (max-width: 481px) {
  .art_head {
    font-size: 14px;
    font-weight: 550;
    text-transform: capitalize;
    padding-top: 8px;
  }

  .profile-active1 {
    /* margin: 0px 10px !important; */
  }

  #profile_edit_icon {
    /* top: -5px; */
    top: 4px;
    left: 8px;
  }

  .editcursor {
    left: 35px;
  }

  #username_dec {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }

  .responsive_input_margin {
    margin-top: 5px;
  }

  .contact_info_margin {
    margin-top: 10px;
  }

  .phone_text {
    font-size: 16px;
    font-weight: 500;
  }

  .mobile_num {
    font-size: 16px;
    font-weight: 500;
  }

  .email_support {
    font-size: 16px;
    font-weight: 500;
  }

  .contact_details {
    width: 250px;
    height: 100px;
    padding: 10px;
  }

  .need_profile {
    position: inherit;
  }

  .need_heading {
    padding: 0px;
  }

  .kyc_margin {
    margin-top: 10px !important;
  }

  /* .need_profile {
    padding: 0px;
  } */

  /* .need_display {
    display: flex;
    justify-content: space-evenly;
  } */

  .upload_btn {
    margin-top: 0px !important;
    padding: 0px 10px !important;
  }

  .drawer_width {
    width: 100% !important;
  }

  .error_text {
    border-radius: 12px !important;
    height: 30px !important;
    color: #36454f !important;
    font-weight: 500 !important;
    width: 130px !important;
    margin-right: 0px !important;
    text-transform: capitalize !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px !important;
    border: 1px solid #000 !important;
    /* margin-top: -50px !important; */
  }

  .verify_text {
    font-size: 14px !important;
    margin-right: 0px !important;
    /* margin-top: -55px !important; */
  }

  .setting-btn {
    border: 1px solid #257fc4 !important;
    border-radius: 12px !important;
    height: 27px !important;
    color: #197fc4 !important;
    font-weight: 500 !important;
    width: 100px !important;
    margin-right: 0px !important;
    text-transform: capitalize !important;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center;
  }

  .edit_btn {
    /* border: 1px solid #257fc4 !important; */
    border-radius: 20px !important;
    height: 25px !important;
    color: #ffffff !important;
    font-weight: 500 !important;
    width: 100px !important;
    margin-right: 0px !important;
    text-transform: capitalize !important;
    background-color: #47aee1 !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px !important;
  }

  .profile__info {
    padding-left: 0px;
    height: 40px;
  }

  #profile__pic {
    /* height: 90px;
    width: 90px; */
    position: absolute;
    top: -50px;
    margin-left: 8px;
    /* border-radius: 50px; */
  }

  .sidebar__icon1 {
    margin: 1px 4px;
  }

  /* .profile__link {
    margin-left: 100px;
  } */

  .profile__header {
    height: 40px;
  }

  /* .sidepadding {
    padding: 5px;
  } */
  /* .profile__header1 {
    height: 160px;
  } */

  /* .profile__cover { */
  /* height: 250px; */
  /* } */
  .cover_edit_icon {
    margin-right: 10px;
    margin-top: 20px !important;
  }

  .profile__info ul {
    display: flex;
    list-style: none;
    cursor: pointer;
    padding: 10px 0px;
  }

  .profile__info li {
    /* color: blue; */
    margin: 0px 12px 4px 12px;
    text-decoration: none;
    font-size: 14px;
  }
}

@media screen and (max-width: 376px) {
  #profile_edit_icon {
    width: 90px;
    top: 0px;
    left: 8px;
  }

  .profile-active1 {
    /* margin: 0px 5px !important; */
  }

  .sidebar__stat1 {
    /* padding-top: 50px; */
  }

  .error_text {
    width: 110px !important;
    font-size: 10px !important;
  }

  .profile__info li {
    /* color: blue; */
    font-size: 14px;
  }

  /* .sidebar__statNumber1 {
    margin-left: -20px;
    padding-top: 45px;
  } */
  #profile__pic {
    height: 90px;
    width: 90px;
    top: -45px;
  }

  /* .drawer_width {
    width: 320px !important;
  } */

  /* .profile__header1 {
    height: 180px;
  } */
  /* .badges_responsive { */
  /* padding-top: 35px; */
  /* } */
}

@media screen and (max-width: 321px) {
  /* .error_text {
    width: 80px !important;
    font-size: 12px !important;
  } */
}
