.elib__card{
  margin:30px 14px;
}
.elib{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
}
.elib__carddetail {
  display: inline-flex;
}
.elib-right {
  border-radius: 10px;
  margin-left:14px;
  flex: 2;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  margin:30px 16px;
}
.elib__substat {
  display: flex;
  padding: 10px 20px;
}
.divider {
  width: 100%;
  border: 1px solid #00000029;
}
.elib-right__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.elib-nav__left{
    display: inline-flex;
}
.elib-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px 12px 0px 0px;
}